import * as cn from "classnames";
import { capitalize, chain, isEmpty, isEqual } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { setLoading } from "../../actions/loading";
import { fetchOrganization } from "../../actions/organization";
import { setCurrentPageProps } from "../../actions/context";
import { LoaderIndicator } from "../../components/ConsolidatedReports";
import { DateText, DeleteMessageIconButton, HorizontalLine, IconUserProfile, PrimaryButtonMedium, TextBlueLabelSmall, TextBlueStrongLabelMedium, TextButtonSmall, TextNormal, TextTableHeader, TrashCanIconButton } from '../../components/lunacy';
import { ERROR } from "../../components/lunacy/debug-panel/DebugPanel";
import { OrganizationUserRoleDropdown, OrganizationUserRoleDropdownWithLabel } from '../../components/lunacy/dropdowns/OrganizationUserRoleDropdown';
import { InputWithLabel } from '../../components/lunacy/inputs/InputWithLabel';
import { Snackbar } from "../../components/lunacy/snackbar/Snackbar";
import { WarningModal } from "../../components/Modal/WarningModal";
import { ValidateEmailStatus } from "../../components/ValidationText/ValidationText";
import { State } from "../../reducers";
import { MemberRole } from "../../reducers/organization";
import { CurrentPageProps } from "../../reducers/session";
import { fetchInternalApi } from "../../services/internal-api";
import { attemptInvoke, wrapCallbackAsAsync } from "../../utils/ReactHelpers";

const Styles = require("./styles.scss");

interface DispatchToProps {
    setCurrentPageProps?: (value: CurrentPageProps) => any;
    setLoading?: (value: boolean) => any;
    reloadInvitations?: () => any;
    reloadMembers?: () => any;
    fetchOrganization?: () => any;
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        setCurrentPageProps: (value: CurrentPageProps) => dispatch(setCurrentPageProps(value)),
        setLoading: (value: boolean) => dispatch(setLoading(value)),
        reloadInvitations: () => wrapCallbackAsAsync(handle => dispatch(fetchOrganization('current', handle))),
        reloadMembers: () => wrapCallbackAsAsync(handle => dispatch(fetchOrganization('current', handle))),
        fetchOrganization: () => wrapCallbackAsAsync(handle => dispatch(fetchOrganization('current', handle))),
    };
}

interface StateToProps {
    organization?: string;
    members: Member[];
    invitations: Invitation[];
}

function mapStateToProps(state: State.All): StateToProps {
    const members: Member[] = chain(state?.organization?.selectedOrganization?.members)
        .defaultTo([])
        .map(({ name: fullName, id, addedBy, addedDate: memberSince, email, lastActive, role }) =>
            ({ id, fullName, addedBy, memberSince, email, lastActive, role } as Member))
        .sortBy(({ fullName }) => fullName)
        .value();

    const invitations: Invitation[] = chain(state?.organization?.selectedOrganization?.invitations)
        .defaultTo([])
        .map(({ id, email, creationDate, role, sentBy, sentByName, sentDate }) =>
            ({ id, email, role, invitedOn: sentDate || creationDate } as Invitation))
        .sortBy(({ email }) => email)
        .reverse()
        .value();

    return {
        organization: state?.organization?.selectedOrganization?.name,
        members,
        invitations
    };
}

interface ExposedProps { }

function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface PageProps extends DispatchToProps, StateToProps, ExposedProps { }

interface PageState {
    organization: string;
    email: string;
    role: MemberRole;
    messageError: string;
    emailWithErrors: boolean;
    showDeleteMemeberModal: boolean;
    selectedMemeberToDelete: { id: string, email: string };
    showDeleteInvitationModal: boolean;
    selectedInvitationToDelete: { id: string, email: string };
    showSnackBar: boolean;
    snackBarMessage: string;
    emailValidationMessage: string;
    isInviteButtonDisabled: boolean;
}

class OrganizationPageComponent extends React.Component<PageProps, PageState> {
    static defaultProps: Partial<PageProps> = {
        members: [],
        invitations: []
    };

    constructor(props: PageProps) {
        super(props);
        this.state = {
            organization: props.organization,
            email: "",
            role: "admin",
            messageError: undefined,
            emailWithErrors: true,
            showDeleteMemeberModal: undefined,
            selectedMemeberToDelete: undefined,
            showDeleteInvitationModal: undefined,
            selectedInvitationToDelete: undefined,
            showSnackBar: undefined,
            snackBarMessage: "",
            emailValidationMessage: "",
            isInviteButtonDisabled: true
        }
    }

    async componentDidMount() {
        const { organization: title = "" } = this.state;
        const subTitle = "Organization Settings";
        this.props.setCurrentPageProps({
            title, subTitle
        })

        try {
            this.props.setLoading(true)
            await this.props.fetchOrganization()
        } catch (err) {
            ERROR("Error fetching sources", err)
        }

        this.props.setLoading(false)
    }

    componentWillReceiveProps(nextProps: Readonly<PageProps>, nextContext: any): void {
        const newState = {} as PageState

        if (!isEqual(nextProps.organization, this.state.organization)) {
            newState.organization = nextProps.organization
        }

        this.setState(newState)
    }

    validateEmail = (email: string) => {
        const { members, invitations } = this.props;

        if (!email) {
            return { isValid: false, message: "Please enter a valid email address." };
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return { isValid: false, message: "Please enter a valid email address." };
        }

        const existingMember = members.find(member => member.email === email);
        if (existingMember) {
            return { isValid: false, message: `${email} is already a member of the organization.` };
        }

        const pendingInvitation = invitations.find(invitation => invitation.email === email);
        if (pendingInvitation) {
            return { isValid: true, message: "This email has a pending invitation. Clicking invite will resend the invitation." };
        }

        return { isValid: true, message: "" };
    }

    handleEmailChange = (email: string) => {
        const validationResult = this.validateEmail(email);
        this.setState({
            email,
            emailValidationMessage: validationResult.message,
            isInviteButtonDisabled: !validationResult.isValid
        });
    }

    handleInvite = async () => {
        const { email, role } = this.state;
        const pendingInvitation = this.props.invitations.find(invitation => invitation.email === email);

        try {
            this.props.setLoading(true);

            if (pendingInvitation) {
                // Resend invitation
                await fetchInternalApi(({ userId, organizationId }) =>
                    `/users/${userId}/organizations/${organizationId}/invitations/${pendingInvitation.id}`, 'PUT', {});
                this.setState({ snackBarMessage: `Invitation resent to ${email}.`, showSnackBar: true });
            } else {
                // Send new invitation
                await fetchInternalApi(({ userId, organizationId }) =>
                    `/users/${userId}/organizations/${organizationId}/invitations`, 'POST', { email, role });
                this.setState({ snackBarMessage: `Invitation sent correctly to ${email}.`, showSnackBar: true });
            }

            await this.props.reloadInvitations();
            this.setState({ email: "", emailValidationMessage: "", isInviteButtonDisabled: true });
        } catch (err) {
            console.error(err);
            this.setState({ snackBarMessage: `Failed to send an invitation to ${email}. Please try again.`, showSnackBar: true });
        } finally {
            this.props.setLoading(false);
        }
    }

    render(): false | JSX.Element {
        return (
            <div className={Styles.container}>
                <div className={Styles.page_header}>
                    <TextBlueStrongLabelMedium>User Management</TextBlueStrongLabelMedium>
                    <TextNormal>Add, modify, or remove users. </TextNormal>
                </div>
                <div className={Styles.main_panel}>
                    <div>
                        <InputWithLabel
                            defaultValue=""
                            label="Email"
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                            autoFocus={true} />
                    </div>
                    <div>
                        <OrganizationUserRoleDropdownWithLabel
                            label="Role"
                            selectedValue={this.state.role}
                            onValueChanged={({ value }) => this.setState({ role: value as MemberRole })}
                        />
                    </div>
                    <div className={Styles.message}>
                        {this.state.emailValidationMessage && (
                            <span className={Styles.validation_message}>{this.state.emailValidationMessage}</span>
                        )}
                    </div>
                    <div className={Styles.button_invite}>
                        <PrimaryButtonMedium
                            disabled={this.state.isInviteButtonDisabled}
                            onClick={this.handleInvite}>
                            Invite
                        </PrimaryButtonMedium>
                    </div>
                    <div className={Styles.horizontal_line}>
                        <HorizontalLine />
                    </div>
                    <div className={Styles.member_list}>
                        <MemberList
                            members={this.props.members}
                            onDeleteMember={async it => {
                                const { id, email } = it;
                                this.setState({ showDeleteMemeberModal: true, selectedMemeberToDelete: { id, email } });
                            }}
                            onUpdateRoleMember={async it => {
                                try {
                                    this.props.setLoading(true);

                                    const { id: memberId, role, email, roleName } = it;
                                    await fetchInternalApi(({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/members/${memberId}`, 'PUT', { role });
                                    await this.props.reloadInvitations();

                                    this.props.setLoading(false);

                                    this.setState({ snackBarMessage: `Role changed successfully to ${roleName} for ${email}`, showSnackBar: true });
                                } catch (err) {
                                    console.error(err);
                                    this.props.setLoading(false);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={Styles.page_header}>
                    <TextBlueStrongLabelMedium>Pending Invitations</TextBlueStrongLabelMedium>
                    <TextNormal>Manage invitations sent to new members. </TextNormal>
                </div>
                <div className={Styles.main_panel}>
                    <div className={Styles.member_list}>
                        <InvitationList
                            invitations={this.props.invitations}
                            onResendInvitation={async it => {
                                try {
                                    this.props.setLoading(true);

                                    const { id: invitationId } = it;
                                    await fetchInternalApi(({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/invitations/${invitationId}`, 'PUT', {});
                                    await this.props.reloadInvitations();
                                    this.props.setLoading(false);

                                    this.setState({ snackBarMessage: `A new invitation to join ${this.state.organization} has been sent to ${it.email}.`, showSnackBar: true });
                                } catch (err) {
                                    this.props.setLoading(false);
                                    console.error(err);
                                }
                            }}
                            onDeleteInvitation={async it => {
                                const { id, email } = it;
                                this.setState({ showDeleteInvitationModal: true, selectedInvitationToDelete: { id, email } });
                            }}
                        />
                    </div>
                </div>
                <ComfirmDeleteMemberModal
                    onOkDialog={async it => {
                        try {
                            this.props.setLoading(true);

                            const { id: memberId } = it;
                            await fetchInternalApi(({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/members/${memberId}`, 'DELETE');
                            await this.props.reloadInvitations();
                            this.setState({ showDeleteMemeberModal: false });
                        } catch (err) {
                            console.error(err);
                        } finally {
                            this.props.setLoading(false);
                        }

                        this.setState({ snackBarMessage: `${it.email} was removed successfully from ${this.state.organization}.`, showSnackBar: true });
                    }}
                    onDismissDialog={() => this.setState({ showDeleteMemeberModal: false })}
                    showModal={this.state.showDeleteMemeberModal}
                    item={this.state.selectedMemeberToDelete}
                />
                <ComfirmDeleteInvitationModal
                    onOkDialog={async it => {
                        this.props.setLoading(true);
                        try {
                            const { id: invitationId } = it;
                            await fetchInternalApi(({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/invitations/${invitationId}`, 'DELETE');
                            await this.props.reloadInvitations();
                            this.setState({ showDeleteInvitationModal: false });
                        } catch (err) {
                            console.error(err);
                        } finally {
                            this.props.setLoading(false);
                        }

                        this.setState({ snackBarMessage: `${it.email} invitation to join ${this.state.organization} has been removed successfully.`, showSnackBar: true });
                    }}
                    onDismissDialog={() => this.setState({ showDeleteInvitationModal: false })}
                    showModal={this.state.showDeleteInvitationModal}
                    item={this.state.selectedInvitationToDelete}
                />
                <Snackbar
                    message={this.state.snackBarMessage}
                    onDissmised={() => this.setState({ showSnackBar: false })}
                    show={this.state.showSnackBar}
                />
            </div>)
    }
}

const StylesMemberList = require("./styles-member-list.scss");

type Member = {
    id: string;
    email: string;
    fullName: string;
    memberSince?: Date;
    lastActive?: Date;
    role: MemberRole;
}

type Invitation = {
    id: string;
    email: string;
    invitedOn?: Date;
    role: MemberRole;
}

type ResendInvitationParam = { id: string, email: string }
type UpdateMemberRoleParam = { id: string, role: string, roleName: string, email: string }
type DeleteMemberParam = { id: string, name: string, role: MemberRole, email: string }
type DeleteInvitationParam = { id: string, email: string }

type MemberListProps = {
    members: Member[];
    onDeleteMember: (item: DeleteMemberParam) => void;
    onUpdateRoleMember: (item: UpdateMemberRoleParam) => void;
}

function MemberList(props: MemberListProps) {
    const {
        members,
        onDeleteMember,
        onUpdateRoleMember
    } = props;

    const emptyRow = [
        <div key={`empty-row`} className={cn(StylesMemberList.empty_row)}><LoaderIndicator /></div>
    ]

    return (
        <div className={StylesMemberList.component}>
            {chain([
                <div key={`header-memebers-member`} className={StylesMemberList.grid_header}><TextTableHeader>Member</TextTableHeader></div>,
                <div key={`header-memebers-member-since`} className={StylesMemberList.grid_header}><TextTableHeader>Member Since</TextTableHeader></div>,
                <div key={`header-memebers-last-active`} className={StylesMemberList.grid_header}><TextTableHeader>Last Active</TextTableHeader></div>,
                <div key={`header-memebers-role`} className={StylesMemberList.grid_header}><TextTableHeader>Role</TextTableHeader></div>,
                <div key={`header-memebers-actions`} className={StylesMemberList.grid_header}>&nbsp;</div>
            ])
                .thru(all => {
                    return isEmpty(members) ? all.concat(emptyRow) : all.concat(
                        chain(members)
                            .map(it => [
                                <div key={`members-row-${it.id}-column-userprofile`}>
                                    <MemberInfo fullName={it.fullName} email={it.email} />
                                </div>,
                                <div key={`members-row-${it.id}-column-member-since`}
                                    className={StylesMemberList.column_center}>
                                    <TextNormal>
                                        <DateText value={it.memberSince} />
                                    </TextNormal>
                                </div>,
                                <div key={`members-row-${it.id}-column-last-active`}
                                    className={StylesMemberList.column_center}>
                                    <TextNormal>
                                        <DateText formatType="Elapsed" value={it.lastActive} />
                                    </TextNormal>
                                </div>,
                                <div key={`members-row-${it.id}-column-member-role`}
                                    className={StylesMemberList.column_center}>
                                    {it.role === "owner"
                                        ? <OrganizationUserRoleDropdown
                                            disabled={true}
                                            selectedValue={"admin"}
                                            onValueChanged={() => { }} />
                                        : <OrganizationUserRoleDropdown
                                            selectedValue={it.role}
                                            onValueChanged={({ label: roleName, value: role }) => attemptInvoke<UpdateMemberRoleParam>(onUpdateRoleMember, { ...it, role, roleName })} />
                                    }
                                </div>,
                                <div key={`members-row-${it.id}-column-actions`}
                                    className={StylesMemberList.column_center}>
                                    {it.role !== "owner" &&
                                        <TrashCanIconButton title="Remove from this organization" onClick={() => attemptInvoke<DeleteMemberParam>(onDeleteMember, it)} />
                                    }
                                </div>
                            ])
                            .reduce((prev, curr) => prev.concat(curr), [])
                            .value()
                    )
                })
                .value()
            }
        </div>
    )
}

type InvitationListProps = {
    invitations: Invitation[];
    onResendInvitation: (item: ResendInvitationParam) => void;
    onDeleteInvitation: (item: DeleteInvitationParam) => void;
}

function InvitationList(props: InvitationListProps) {
    const {
        invitations,
        onResendInvitation,
        onDeleteInvitation,
    } = props;

    const emptyRow = [
        <div key={`empty-row`} className={cn(StylesMemberList.empty_row)}>
            <TextNormal>No pending invitations at the moment. Use the form above to invite new members to your organization.</TextNormal>
        </div>
    ]

    return (
        <div className={StylesMemberList.component}>
            {chain([
                <div key={`header-invitations-member`} className={StylesMemberList.grid_header}><TextTableHeader>Pending Member</TextTableHeader></div>,
                <div key={`header-invitations-invited-on`} className={StylesMemberList.grid_header}><TextTableHeader>Invited On</TextTableHeader></div>,
                <div key={`header-invitations-empty`} className={StylesMemberList.grid_header}>&nbsp;</div>,
                <div key={`header-invitations-role`} className={StylesMemberList.grid_header}><TextTableHeader>Role</TextTableHeader></div>,
                <div key={`header-invitations-action`} className={StylesMemberList.grid_header}>&nbsp;</div>
            ])
                .thru(all => {
                    return isEmpty(invitations) ? all.concat(emptyRow) : all.concat(
                        chain(invitations)
                            .map(it => [
                                <div key={`invitations-row-${it.id}-column-email`}>
                                    <InvitationInfo email={it.email} />
                                </div>,
                                <div key={`invitations-row-${it.id}-column-invited-on`}
                                    className={StylesMemberList.column_center}>
                                    <TextNormal>
                                        <DateText value={it.invitedOn} />
                                    </TextNormal>
                                </div>,
                                <div key={`invitations-row-${it.id}-column-resend`}
                                    className={StylesMemberList.column_center}><TextButtonSmall onClick={() => attemptInvoke<ResendInvitationParam>(onResendInvitation, it)}>Resend invite</TextButtonSmall></div>,
                                <div key={`invitations-row-${it.id}-column-role`}
                                    className={StylesMemberList.column_center}>
                                    <TextNormal>{capitalize(it.role)}</TextNormal>
                                </div>,
                                <div key={`invitations-row-${it.id}-column-actions`} className={StylesMemberList.column_center}>
                                    <DeleteMessageIconButton title="Remove invitation" onClick={() => attemptInvoke<DeleteInvitationParam>(onDeleteInvitation, it)} />
                                </div>
                            ])
                            .reduce((prev, curr) => prev.concat(curr), [])
                            .value()
                    )
                })
                .value()
            }
        </div>
    )
}

const StylesMemberInfo = require("./styles-member-info.scss");

type MemberInfoProps = {
    fullName: string;
    email: string;
}

function MemberInfo(props: MemberInfoProps) {
    const { fullName, email } = props
    return <div className={StylesMemberInfo.component}>
        <div><IconUserProfile color={"color_yale_blue"} /></div>
        <div>
            <TextBlueLabelSmall>{fullName}</TextBlueLabelSmall>
        </div>
        <div>
            <TextNormal>{email}</TextNormal>
        </div>
    </div>
}

type InvitationInfoProps = {
    email: string;
}

function InvitationInfo(props: InvitationInfoProps) {
    const { email } = props
    return <div className={StylesMemberInfo.component}>
        <div><IconUserProfile color={"color_lavender_gray"} /></div>
        <div>
            <TextBlueLabelSmall>Invite Pending</TextBlueLabelSmall>
        </div>
        <div>
            <TextNormal>{email}</TextNormal>
        </div>
    </div>
}

type ComfirmDeleteMemberModalItem = { id: string, email: string }
type ComfirmDeleteMemberModalProps = { showModal: boolean, item: ComfirmDeleteMemberModalItem, onOkDialog: (item: ComfirmDeleteMemberModalItem) => void, onDismissDialog: () => void }

function ComfirmDeleteMemberModal(props: ComfirmDeleteMemberModalProps) {
    const { item, onDismissDialog, onOkDialog, showModal } = props
    const { email, id } = item || {}
    return (
        <WarningModal
            title="Remove user confirmation"
            item={item}
            onDismissDialog={() => attemptInvoke(onDismissDialog)}
            onOkDialog={it => attemptInvoke<ComfirmDeleteMemberModalItem>(onOkDialog, it)}
            showModal={showModal}>
            <p>
                <TextNormal>Are you sure you want to remove</TextNormal><br />
                <TextBlueLabelSmall>&nbsp;{email}&nbsp;</TextBlueLabelSmall>
                <TextNormal>?</TextNormal>
            </p>
        </WarningModal>
    )
}

type ComfirmDeleteInvitationModalItem = { id: string, email: string }
type ComfirmDeleteInvitationModalProps = { showModal: boolean, item: ComfirmDeleteInvitationModalItem, onOkDialog: (item: ComfirmDeleteInvitationModalItem) => void, onDismissDialog: () => void }

function ComfirmDeleteInvitationModal(props: ComfirmDeleteInvitationModalProps) {
    const { item, onDismissDialog, onOkDialog, showModal } = props
    const { email, id } = item || {}
    return (
        <WarningModal
            title="Remove user invitation"
            item={item}
            onDismissDialog={() => attemptInvoke(onDismissDialog)}
            onOkDialog={it => attemptInvoke<ComfirmDeleteInvitationModalItem>(onOkDialog, it)}
            showModal={showModal}>
            <p>
                <TextNormal>Are you sure you want to remove the invitation for:</TextNormal><br />
                <TextBlueLabelSmall>{email}</TextBlueLabelSmall><TextNormal>?</TextNormal>
            </p>
        </WarningModal>
    )
}

export const OrganizationPage = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(OrganizationPageComponent);