import { IntervalPeriods, PlanIdTypes } from "../constants/stripe";
import { FirebaseUser } from "../models/user";
import { User, UserDetails, userStatus } from "../models/user";
import { fetchInternalApi } from "../services/internal-api";
import { BrowserStorage, LocalStorage } from "../store/local-storage";
import browser from "../utils/browser";
import { remoteservice } from "./remote-service";
// import { source } from "./source";
import * as stripe from "./stripe";
import UtilsServer from "./utils-server";

// const ReactGA = require("react-ga");

/**
 * Auth Service
 */
namespace auth {

    const globalWindow: any = typeof (window) !== "undefined" ? window : {};
    const { heap } = globalWindow;

    const planByDefaultForNewUser = PlanIdTypes.SELF_SERVE;
    export const deletedFromDashboardIntercomTag = "deletedFromDashboard";

    export const loginWithGoogle = (auth?: remoteservice.auth.Auth, storage?: LocalStorage, db: remoteservice.database.Database = remoteservice.defaultService().database()): Promise<User> => {
        let provider = new remoteservice.auth.GoogleAuthProvider();
        return loginWithProvider(provider, auth, storage, db);
    };

    export const loginWithIbm = async(instanceId: string, code: string, auth?: remoteservice.auth.Auth, storage?: LocalStorage, window?: any): Promise<User> => {
        // const authResponse = await source.getIbmToken(instanceId, code);
        // return loginWithCustomToken(authResponse.authToken, auth, storage);
        return Promise.resolve(undefined);
    };


    const loginWithProvider = async (provider: remoteservice.auth.AuthProvider, auth: remoteservice.auth.Auth = remoteservice.defaultService().auth(), storage?: LocalStorage, db: remoteservice.database.Database = remoteservice.defaultService().database()): Promise<User> => {
        let result: any;
        let lastEmail: string;
        try {
            if (browser.isMobileOrTablet()) {
                result = await auth.signInWithRedirect(provider);
            } else {
                result = await auth.signInWithPopup(provider);
            }
            const userDetails = await currentUserDetails();

            lastEmail = userDetails.email;
            // TODO: refactor this code! This is no really checking uid, just validating thas has any property
            const isNewUser = !userDetails.uid || !userDetails?.status;

            return authProviderSuccessHandler(result, storage, isNewUser, provider.providerId);
        } catch (error) {
            if (error?.message === "custom/free-email") {
                UtilsServer.generateBespokenLead("pending", "", lastEmail, "pending");
            } else if (error.code === "auth/account-exists-with-different-credential") {
                const email = error.email;
                const credential = error.credential;
                const providers = await auth.fetchSignInMethodsForEmail(email);
                if (providers.some(provider => provider === "google.com")) {
                    await loginWithGoogle();
                    return auth.currentUser.link(credential);
                } else if (providers.some(provider => provider === "password")) {
                    return Promise.reject(new Error("custom/user-have-email-account"));
                }
            }

            return authProviderSuccessHandler(error, storage, false, provider.providerId);
        }
    };

    const ignoreError = (errorCode: string) => {
        const codes: any = ["auth/cancelled-popup-request", "auth/popup-closed-by-user"];
        return codes.includes(errorCode);
    };

    const authProviderSuccessHandler = async (result: any, localStorage: LocalStorage = new BrowserStorage(), isSignUp: boolean, providerId: string): Promise<User> => {
        return new Promise<User>((resolve, reject) => {
            let user: User = undefined;

            if (result.user) {
                const providerMessage = `With ${providerId}`;
                // ReactGA.event({
                //     category: "Authorization",
                //     action: isSignUp ? `Signup ${providerMessage}` : `Login ${providerMessage}`,
                // });
                if (isSignUp) {
                    globalWindow.google_trackConversion && globalWindow.google_trackConversion({
                        google_conversion_id: 860338926,
                        google_conversion_label: "W121CKKEh3YQ7vWemgM",
                        google_remarketing_only: false,
                    });
                }
                user = new FirebaseUser(result.user);
                identify(user, "github");
                localStorage.setItem("user", JSON.stringify(user));
                resolve(user);
            } else {
                if (result.code === "auth/web-storage-unsupported") {
                    reject(new Error("custom/cookies"));
                    return;
                }
                if (result.message === "custom/free-email") {
                    reject(result);
                    return;
                }
                if (!ignoreError(result.code)) {
                    reject(new Error("custom/trying-login"));
                    return;
                }
                reject(new Error("custom/fake-error"));
            }
        });
    };

    export const validateEmail = (email: string) => {
        let emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailRegex.test(email)) {
            throw new Error("custom/not-valid-email");
        }
        return true;
    };

    export const validateDisposableEmail = async (disposableEmailPayload: any) => {
        const isDisposable = disposableEmailPayload?.isDisposable;
        if (isDisposable) {
            throw new Error("custom/disposable-email");
        }
        return true;
    };

    export const validateFreeEmail = async (disposableEmailPayload: any) => {
        const isFreeMail = disposableEmailPayload?.isFreeMail;
        if (isFreeMail) {
            throw new Error("custom/free-email");
        }
        return true;
    };

    export const validatePassword = (password: string, confirmPassword: string) => {
        if (password !== confirmPassword) {
            throw new Error("custom/password-not-match");
        }

        if (password.length < 6) {
            throw new Error("custom/password-length");
        }
        return true;
    };

    export const getCurrentUserEmail = (auth: any = remoteservice.defaultService().auth()) => {
        const user: any = auth.currentUser;
        if (!user) {
            return Promise.reject("There is no user with login");
        }
        return user.email;
    };

    export const changeUserPassword = async (currentPassword: string, newPassword: string, repeatPassword: string,
        auth: any = remoteservice.defaultService().auth(), emailProvider: any = remoteservice.defaultService()) => {

        validatePassword(newPassword, repeatPassword);

        const user: any = auth.currentUser;
        if (!user) {
            return Promise.reject("There is no user with login");
        }
        const credential = emailProvider.emailAuthProviderGetCredencial(user.email, currentPassword);


        await user.reauthenticateWithCredential(credential);
        return user.updatePassword(newPassword);
    };

    export const doTheUserHaveAPassword = (auth: remoteservice.auth.Auth = remoteservice.defaultService().auth()) => {
        const user: any = auth.currentUser;
        if (user && user.providerData && user.providerData.length > 0 && user.providerData[0].providerId && user.providerData[0].providerId === "password") {
            return true;
        }
        return false;
    };

    export const validateData = async (email: string, password: string, confirmPassword: string,
        executeDisposableEmailValidation?: boolean, executeIsFreeEmailValidation?: boolean) => {
        const areEmpty = [email, password, confirmPassword].some(field => !field);
        try {
            validateEmail(email);
            if (areEmpty) {
                throw new Error("custom/empty-fields");
            }
            validatePassword(password, confirmPassword);
            if (executeDisposableEmailValidation || executeIsFreeEmailValidation) {
                let disposableEmailPayload: any;
                try {
                    disposableEmailPayload = await fetchInternalApi(`/login/validateEmail`, "POST", { email },
                    { "Content-Type": "application/json" }, false);
                } catch (e) {}
                if (executeDisposableEmailValidation) {
                    await validateDisposableEmail(disposableEmailPayload);
                }
                if (executeIsFreeEmailValidation) {
                    await validateFreeEmail(disposableEmailPayload);
                }
            }
        } catch (error) {
            throw error;
        }
    };

    export const signUpWithEmail = async (email: string, password: string, confirmPassword: string, auth: remoteservice.auth.Auth = remoteservice.defaultService().auth(), localStorage: LocalStorage = new BrowserStorage()): Promise<User> => {
        // try {
        //     await validateData(email, password, confirmPassword, true, true);
        //     const userRequiresActivation = await source.userRequiresActivation();
        //     const props = await getNewUserProps(email, userRequiresActivation);
        //     const userCredential = await auth.createUserWithEmailAndPassword(email, password);
        //     const user = userCredential.user;
        //     await updateCurrentUser(props);
        //     UtilsServer.generateBespokenLead("pending", "", email, "pending");

        //     try {
        //         await source.untagIntercomUser(email, deletedFromDashboardIntercomTag);
        //     } catch (error) {}
        //     // ReactGA.event({
        //     //     category: "Authorization",
        //     //     action: "Signup With Email"
        //     // });
        //     globalWindow.google_trackConversion && globalWindow.google_trackConversion({
        //         google_conversion_id: 860338926,
        //         google_conversion_label: "M7SmCOmKgXYQ7vWemgM",
        //         google_remarketing_only: false,
        //     });
        //     const modelUser: User = new FirebaseUser(user);
        //     identify(modelUser, "email");
        //     localStorage.setItem("user", JSON.stringify(modelUser));

        //     if (!userRequiresActivation) {
        //         await user.sendEmailVerification();
        //     }

        //     return modelUser;
        // } catch (error) {
        //     if (error.code === "auth/email-already-in-use") {
        //         const credential = remoteservice.defaultService().emailAuthProviderGetCredencial(email, password);
        //         const providers = await auth.fetchSignInMethodsForEmail(email);
        //         if (providers.some(provider => provider === "google.com")) {
        //             await loginWithGoogle();
        //             return auth.currentUser.link(credential);
        //         }
        //     }
        //     return Promise.reject(error);
        // }
        return Promise.resolve(undefined);
    };

    export const login = async (email: string, password: string, auth: remoteservice.auth.Auth = remoteservice.defaultService().auth(), localStorage: LocalStorage = new BrowserStorage()): Promise<User> => {
        try {
            await validateData(email, password, password);
            const user: remoteservice.user.User = await auth.signInWithEmailAndPassword(email, password);

            // @ts-ignore TODO fix types
            let modelUser: User = new FirebaseUser(user?.user);

            identify(modelUser, "email");
            localStorage.setItem("user", JSON.stringify(modelUser));
            return modelUser;
        } catch (error) {
            return Promise.reject(error);
        }
    };

    export const rawLogin = async (email: string, password: string, auth: remoteservice.auth.Auth = remoteservice.defaultService().auth(), localStorage: LocalStorage = new BrowserStorage()): Promise<any> => {
        const user: any = await auth.signInWithEmailAndPassword(email, password);
        return user;
    };

    export const loginWithCustomToken = async (token: string, auth: remoteservice.auth.Auth = remoteservice.defaultService().auth(), localStorage: LocalStorage = new BrowserStorage()): Promise<User> => {

        const user: any = await auth.signInWithCustomToken(token);
        const userDetails = await currentUserDetails();
        // TODO: refactor this code! This is no really checking name, just validating thas has any property
        // const isNewUser = !userDetails.uid;
        // if (isNewUser) {
        //     await validateData(userDetails.email, "123abc", "123abc", true, true);
        //     const userRequiresActivation = await source.userRequiresActivation();
        //     const props = await getNewUserProps(userDetails.email, userRequiresActivation);
        //     await updateCurrentUser(props);
        //     await source.untagIntercomUser(userDetails.email, deletedFromDashboardIntercomTag);
        // }

        // const action = isNewUser ? "Signup" : "Login";
        // // ReactGA.event({
        // //     category: "Authorization",
        // //     action: `${action} Custom Token`,
        // // });
        // const modelUser: User = new FirebaseUser(user);
        // identify(modelUser, "custom token");
        // localStorage.setItem("user", JSON.stringify(modelUser));
        // return modelUser;
        return Promise.resolve(undefined);
    };

    export const hasDeletedFromIntercomTag = (tags: any) => {
        if (tags && tags.tags && tags.tags.length) {
            for (const tag of tags.tags) {
                if (tag.name === deletedFromDashboardIntercomTag) {
                    return true;
                }
            }
        }
        return false;
    };

    export const getNewUserProps = async (email: string, userRequiresActivation: boolean = false, sourceService: any) => {
        let status: string = userStatus.Trial;
        let props: any = {
            planId: planByDefaultForNewUser,
            status,
            userRequiresActivation,
            skipEmailVerification: userRequiresActivation,
            githubUser: true,
        };
        let surveyData: any = {};
        let jobTitle, name, company = "";
        let surveyCompleted = false;
        try {
            const intercomUser = await sourceService.getIntercomUser(email);
            if (hasDeletedFromIntercomTag(intercomUser.tags)) {
                const { e2eTestingImportance, liveApps, logsStatsImportance, monitoringImportance,
                    painPoint, proxyImportance, unitTestingImportance, usabilityTestingImportance, voiceAppsPlatforms } = intercomUser.custom_attributes;
                jobTitle = intercomUser.custom_attributes.jobTitle;
                name = intercomUser.name;
                company = intercomUser.custom_attributes.userCompany;
                surveyCompleted = !!e2eTestingImportance;
                surveyData = e2eTestingImportance ? {
                    e2eTestingImportance, liveApps, logsStatsImportance, monitoringImportance,
                    painPoint, proxyImportance, unitTestingImportance, usabilityTestingImportance, voiceAppsPlatforms
                } : {};
                status = userStatus.TrialEnded;
                props = { planId: planByDefaultForNewUser, status, surveyData, jobTitle, name, company, surveyCompleted };
            }

        } catch (error) {
        }
        return props;
    };

    export const logout = async (auth: remoteservice.auth.Auth = remoteservice.defaultService().auth(), localStorage: LocalStorage = new BrowserStorage()): Promise<any> => {
        await auth.signOut();
        return localStorage.removeItem("user");
    };

    export const user = (localStorage: LocalStorage = new BrowserStorage()): User | undefined => {
        return localStorage.getItem("user") ? new User(JSON.parse(localStorage.getItem("user"))) : undefined;
    };

    export const sendResetPasswordEmail = (email: string, auth: remoteservice.auth.Auth = remoteservice.defaultService().auth()): Promise<any> => {
        return auth.sendPasswordResetEmail(email);
    };

    const identify = (user: User, loginType: string) => {
        if (typeof (heap) !== "undefined") heap.identify(user.userId);
        if (typeof (heap) !== "undefined") heap.addUserProperties({
            "Email": user.email,
            "Name": user.displayName,
            "LoginType": loginType
        });
    };


    export const currentUserDetails = async (): Promise<UserDetails> => {
        // const currentUser = remoteservice.defaultService().auth().currentUser;
        // const emptyUser = new UserDetails();
        // if (!currentUser) {
        //     return emptyUser;
        // }
        // const data = await source.getUser(currentUser.uid);
        // if (data) {
        //     return new UserDetails(data);
        // }
        // return emptyUser;
        return Promise.resolve(undefined);
    };

    export const createUserDetails = (uid: string, props: Object): Promise<any> => {
        // return source.createUserDetail(uid, { ...props });
        return Promise.resolve(undefined);
    };

    export const signInformation = async (data: any, secret?: string): Promise<string> => {
        const jwt = require("jsonwebtoken");
        try {
            return await jwt.sign(data, secret || process.env.JWT_SECRET || "llama");
        } catch (error) {

        }
    };

    export const decodeInformation = async (token: string, secret?: string): Promise<any> => {
        const jwt = require("jsonwebtoken");
        try {
            return await jwt.verify(token, secret || process.env.JWT_SECRET || "llama");
        } catch (error) {

        }
    };
}

export default auth;
