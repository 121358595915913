
import { isEmpty, isString } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import BespokenLogo from "../../../assets/bespoken_logoweb.svg";
import BespokenMountains from "../../../assets/bespoken_mountains.svg";
import { switchOrganization } from "../../actions/organization";
import { Loader } from "../../components";
import { State } from "../../reducers";
import { SessionState } from "../../reducers/session";
import { fetchInternalApi } from "../../services/internal-api";
import { wrapCallbackAsAsync } from "../../utils/ReactHelpers";
import MemberSingupForm from "./member-singup-form";
import * as Intercom from "../../services/intercom";
import { ContextState } from "../../reducers/context";
import { login, setOnboardingViaInvite } from "../../actions/context";

const globalWindow: any = typeof (window) !== "undefined" ? window : {};
const Styles = require("./member-singup.scss");

interface MemberSingupProps {
    login: (email: string, password: string) => Promise<any>;
    goTo: (path: string) => (dispatch: any) => void;
    goToLogin: () => void
    switchOrganization: (organizationId: string) => Promise<any>;
    setOnboardingViaInvite: (onboarding: boolean) => Promise<any>;
    context: ContextState;
}

interface MemberSingupState {
    email: string;
    newUserJoining: boolean;
    showLoader: boolean;
    error: string;
    organizationId: string;
    organizationName: string;
    showContent: boolean;
}

const mapStateToProps = (state: State.All) => ({
    context: state?.context
});

const mapDispatchToProps = (dispatch: any) =>
({
    login: (email: string, password: string) =>
        wrapCallbackAsAsync(handle => dispatch(login(email, password, handle))),
    goTo: function (path: string) {
        dispatch(push(path));
    },
    goToLogin: () => { location.href = "/" },
    switchOrganization: async (organizationId: string) => wrapCallbackAsAsync(handle => dispatch(switchOrganization(organizationId, handle))),
    setOnboardingViaInvite: async (onboarding: boolean) => {
        dispatch(setOnboardingViaInvite(onboarding))
    }
});

export class MemberSingup extends React.Component<MemberSingupProps, MemberSingupState> {
    constructor(props: MemberSingupProps) {
        super(props);
        this.state = {
            email: undefined,
            error: "",
            newUserJoining: false,
            showLoader: true,
            showContent: false,
            organizationName: "",
            organizationId: undefined
        };
    }

    componentDidMount() {
        const urlParsed = new URL(location.href);
        let organizationId = urlParsed.searchParams.get('organizationId');
        let email = urlParsed.searchParams.get('email');
        const token = urlParsed.searchParams.get('token');
        if (organizationId) {
            this.setState({ email: email, organizationId: organizationId })
        } else {
            organizationId = this.state.organizationId
            email = this.state.email
        }

        // This should not be neceesary, but if we are already joining and get sent back here
        // Then just forward to skills page
        if (this.state.newUserJoining) {
            this.props.goTo('/skills')
            return
        }
        fetchInternalApi(
            `/organizations/${organizationId}/invitations/${encodeURIComponent(email)}?token=${token}`)
            .then((body: any) => {
                if (isString(body)) return this.setState({ error: body, showLoader: false });
                const invitation = body;
                this.setState({ organizationName: invitation.organizationName });
                if (invitation?.hasAuthUser) {
                    this.handleJoinOrganizationExistingUser()
                } else {
                    this.setState({ showLoader: false, showContent: true });
                }
            })
            .catch((err: Error) => this.setState({ error: err.message, showLoader: false }))
    }

    handleJoinOrganization = (payload: any) => {
        const urlParsed = new URL(location.href);
        const organizationId = urlParsed.searchParams.get('organizationId');
        const email = urlParsed.searchParams.get('email');
        const token = urlParsed.searchParams.get('token');

        this.setState({ newUserJoining: true, showLoader: true });
        fetchInternalApi(
            `/organizations/${organizationId}/invitations/${encodeURIComponent(email)}?token=${token}`, 'PUT', payload)
            .then((body: any) => {
                if (isString(body)) return this.setState({ error: body, showLoader: false });

                globalWindow && Intercom.updateIntercom(globalWindow, {
                    userWasInvited: true,
                }, email);
                this.props.setOnboardingViaInvite(true)
                this.props.login(email, payload.password).then((user => {

                    // this.setState({ showLoader: false });
                    // // TODO hacky wait to get fetched app settings, organization and user
                    // setTimeout(() => {
                    //     console.log('member-singup: redirect to skills');
                    //
                    //     this.props.goTo(`/skills`);
                    // }, 5000)

                }));
            })
            .catch((err: Error) => this.setState({ error: err.message, showLoader: false }))
    }

    handleJoinOrganizationExistingUser = () => {
        const urlParsed = new URL(location.href);
        const organizationId = urlParsed.searchParams.get('organizationId');
        const email = urlParsed.searchParams.get('email');
        const token = urlParsed.searchParams.get('token');

        this.setState({ showLoader: true });

        fetchInternalApi(
            `/organizations/${organizationId}/invitations/${encodeURIComponent(email)}?token=${token}`, 'PUT', {})
            .then(async (body: any) => {
                if (isString(body)) return this.setState({ error: body, showLoader: false });



                if (isEmpty(this?.props?.context?.authUser)) { return this.props.goToLogin() }


                await this.props?.switchOrganization(organizationId).catch(error => console.log(error))
                this.props.goTo('/skills')
            })
            .then(() => this.setState({ showLoader: false }))
            .catch((err: Error) => this.setState({ error: err.message, showLoader: false }))
    }

    render() {
        return (
            <div className={Styles.container}>
                <div className={Styles.llama_icon}>
                    <BespokenLogo />
                </div>
                {!this.state.error && this.state.showContent &&
                    <div className={Styles.content}>
                        <span className={Styles.header}>Complete your profile</span>
                        <span className={Styles.sub_header}>It takes just 30 seconds!</span>
                        <div className={Styles.form_container}>
                            <MemberSingupForm
                                organizationName={this.state.organizationName}
                                onJoinOrganization={this.handleJoinOrganization}
                            />
                        </div>
                    </div>
                }

                {this.state.error &&
                    <span className={Styles.error}>{this.state.error}</span>
                }
                <BespokenMountains data-id="mountains" />
                {this.state.showLoader && <Loader />}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberSingup);
