import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as classNames from 'classnames';

const Styles = require('./FilteredDropdown.scss');

interface Option {
  value: string;
  label: string;
}

interface FilteredDropdownProps {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  small?: boolean;
}

interface FilteredDropdownState {
  isOpen: boolean;
  filter: string;
}

class FilteredDropdown extends React.Component<FilteredDropdownProps, FilteredDropdownState> {
  private dropdownRef: HTMLDivElement;

  constructor(props: FilteredDropdownProps) {
    super(props);
    this.state = {
      isOpen: false,
      filter: '',
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setDropdownRef = (node: HTMLDivElement) => {
    this.dropdownRef = node;
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.dropdownRef && !this.dropdownRef.contains(event.target as Node)) {
      this.setState({ isOpen: false, filter: '' });
    }
  };

  toggleDropdown = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ filter: event.target.value });
  };

  handleOptionClick = (value: string) => {
    this.props.onChange(value);
    this.setState({ isOpen: false, filter: '' });
  };

  getFilteredOptions = () => {
    const { options } = this.props;
    const { filter } = this.state;
    return options
      .filter((option, index, self) =>
        index === self.findIndex((t) => t.value === option.value && t.label === option.label)
      )
      .filter(option =>
        option.label.toLowerCase().includes(filter.toLowerCase())
      );
  };

  render() {
    const { value, placeholder, className, small, options } = this.props;
    const { isOpen, filter } = this.state;
    const filteredOptions = this.getFilteredOptions();
    const selectedOption = options.find(option => option.value === value);

    return (
      <div
        ref={this.setDropdownRef}
        className={classNames(Styles.dropdown, className, {
          [Styles.active]: isOpen,
          [Styles.small]: small,
        })}
      >
        <div className={Styles.value} onClick={this.toggleDropdown}>
          <input
            type="text"
            readOnly
            value={selectedOption ? selectedOption.label : ''}
            placeholder={placeholder}
          />
        </div>
        {isOpen && (
          <div className={Styles.values}>
            <input
              type="text"
              value={filter}
              onChange={this.handleFilterChange}
              placeholder="Search..."
              className={Styles.filterInput}
            />
            <ul>
              {filteredOptions.map(option => (
                <li
                  key={option.value}
                  onClick={() => this.handleOptionClick(option.value)}
                  className={classNames({
                    [Styles.selected]: option.value === value,
                  })}
                >
                  <span>{option.label}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default FilteredDropdown;