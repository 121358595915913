import * as React from "react";
import * as cn from "classnames";
import { chain, isEmpty, join, snakeCase, toLower, uniqueId } from "lodash";
import BespokenButtonIcon from "../../../assets/bespoken_button_icon.svg";
import BespokenLogo from "../../../assets/bespoken_logoweb.svg";
import BespokenMountains from "../../../assets/bespoken_mountains.svg";
import ClockIcon from "../../../assets/clock.svg";
import * as backgroundLeft from "../../../assets/images/bespoken_survey_first_no_text.jpg";
import { Button, Checkbox, Dropdown, Input, LeftPanel, RightPanel } from "../../components";
import { attemptInvoke, cnToggle, joinWithElements } from "../../utils/ReactHelpers";
import { User } from "../../reducers/user";
import { fetchInternalApi } from "../../services/internal-api";

const style = require("./firstStepStyles.scss");

const HEARABOUT_US_IN_GRID_ORDER = [
    "Social Media", "Blog post",
    "Google", "Friend/Colleague referall",
    "Tech Event/Conference", "Ad",
    "Other"
]

const JOB_TITLES = [
    { label: "Product Owner/Manager", value: "Product Owner/Manager" },
    { label: "Software Developer", value: "Software Developer" },
    { label: "QA Analyst/Manager", value: "QA Analyst/Manager" },
    { label: "Dev Ops", value: "Dev Ops" },
    { label: "Director/Management", value: "Director/Management" },
    { label: "Other", value: "Other" }
]

interface FirstStepProps {
    onStepComplete: (data: any) => void;
    user: User;
}

interface FirstStepStates {
    name: string;
    userCompany: string;
    email: string;
    emailIsValid: boolean;
    jobTitle: string;
    enableOtherJobTitle: boolean;
    hearAboutUs: string[];
    enableOtherHearAbout: boolean;
    formIsCompleted: boolean;
    validatingEmail: boolean;
}

export default class FirstStep extends React.Component<FirstStepProps, FirstStepStates> {
    constructor(props: FirstStepProps) {
        super(props);
        const isIbmUser = !isEmpty(this.props?.user?.onboardingData?.ibm);

        const { name } = props.user || {};
        this.state = {
            name,
            userCompany: "",
            email: "",
            emailIsValid: false,
            jobTitle: "Product Owner/Manager",
            enableOtherJobTitle: false,
            hearAboutUs: isIbmUser ? ["IBM Catalog"] : [],
            enableOtherHearAbout: false,
            formIsCompleted: false,
            validatingEmail: false,
        };
    }

    get isComponentCompleted() {
        const { name, userCompany, hearAboutUs, jobTitle, emailIsValid, email } = this.state
        const isIbmUser = !isEmpty(this.props?.user?.onboardingData?.ibm)

        if (isIbmUser && !emailIsValid) { return false }

        if (!isIbmUser && hearAboutUs.length === 0) { return false }

        return !chain({ name, userCompany, jobTitle })
            .thru(obj => isIbmUser ? { ...obj, email } : obj)
            .values()
            .some(isEmpty)
            .value()
    }

    completeOnboarding = () => {
        const { name, userCompany, hearAboutUs, jobTitle, email } = this.state;
        const isIbmUser = !isEmpty(this.props?.user?.onboardingData?.ibm);

        const data = {
            name,
            userCompany,
            hearAboutUs: join(hearAboutUs, " & "),
            jobTitle,
            email: isIbmUser ? email : undefined,
            isNewsLetterChecked: true, // Always assume opted in
        };

        this.props.onStepComplete(data);
    };

    render() {
        const isIbmUser = !isEmpty(this.props?.user?.onboardingData?.ibm)
        const {
            email,
            name,
            userCompany,
            hearAboutUs,
            enableOtherJobTitle,
            enableOtherHearAbout,
            emailIsValid,
            validatingEmail
        } = this.state;
        return (
            <div className={"firstStep"}>
                <LeftPanel className={style.leftPanel} fullHeight={true} image={backgroundLeft}>
                    <div data-id="details">
                        <ClockIcon data-id="icon" />
                        <div data-id="message1">Welcome to your Dashboard</div>
                        <div data-id="message2">save time and</div>
                        <div data-id="message3">free your team</div>
                    </div>
                    <div data-id="companyName">Bespoken</div>
                </LeftPanel>
                <RightPanel className={style.rightPanel} fullHeight>
                    <section>
                        <div className={style.form_container}>
                            <div className={style.llama_icon}>
                                <BespokenLogo />
                            </div>
                            <div className={style.page_title}>
                                your bespoken profile <br />
                                get more from testing automation!
                            </div>

                            <div className={style.takes_just_30_title}>It takes just 30 seconds!</div>
                            <SurveyTextInput
                                defaultValue={name}
                                label="Full Name"
                                onChange={name => this.setState({ name })}
                            />
                            <SurveyTextInput
                                defaultValue={userCompany}
                                label="Company *"
                                onChange={userCompany => this.setState({ userCompany })}
                            />

                            <SurveyJobTitle
                                label="Job Title"
                                onChange={jobTitle => {
                                    if (jobTitle === 'Other') {
                                        return this.setState({ jobTitle: '', enableOtherJobTitle: true })
                                    }

                                    return this.setState({ jobTitle, enableOtherJobTitle: false })
                                }}
                            />
                            {!isIbmUser && <SurveyOtherInput
                                label="Other?"
                                editable={enableOtherJobTitle}
                                onChange={jobTitle => this.setState({ jobTitle })}
                            />}

                            {isIbmUser &&
                                [
                                    <SurveyTextInput
                                        key={'input-text-email'}
                                        hasError={!isEmpty(email) && !validatingEmail && !emailIsValid}
                                        defaultValue=""
                                        label="Email:"
                                        onChange={email => this.setState({ email, emailIsValid: false, validatingEmail: true })}
                                    />,
                                    <SurveyEmailValidation
                                        key={'email-validation-message'}
                                        onChange={emailIsValid => this.setState({ emailIsValid, validatingEmail: false })}
                                        emailToValidate={email}
                                    />
                                ]
                            }

                            {!isIbmUser &&
                                <div className={style.hear_about_question_title}>How did you hear about us?:</div>
                            }
                            {!isIbmUser &&
                                joinWithElements(
                                    chain(HEARABOUT_US_IN_GRID_ORDER).map(label => ({ label, value: snakeCase(label) })).value(),
                                    it => (<SurveyCheckbox
                                        key={`checkbox-${snakeCase(it.label)}`}
                                        label={it.label}
                                        value={it.value}
                                        checked={it.label === "Other" ? enableOtherHearAbout : hearAboutUs.includes(it.label)}
                                        onChange={item => {
                                            if (item.label === "Other") {
                                                return this.setState({
                                                    enableOtherHearAbout: item.checked,
                                                    hearAboutUs: item.checked
                                                        ? [...hearAboutUs, "Other"]
                                                        : hearAboutUs.filter(h => h !== "Other" && !h.startsWith('Other: '))
                                                });
                                            }

                                            return this.setState({
                                                hearAboutUs: chain(hearAboutUs)
                                                    .concat([item.label])
                                                    .filter(it => it !== item.label || item.checked)
                                                    .uniq()
                                                    .value()
                                            });
                                        }} />),
                                    () => undefined
                                )
                                    .concat(<SurveyOtherInput
                                        key={'other-hear-about-us-input-text'}
                                        editable={enableOtherHearAbout}
                                        onChange={otherHearAboutUs => {
                                            // Remove both "Other" and any "Other: " entries
                                            const filteredHearAboutUs = hearAboutUs.filter(h => h !== "Other" && !h.startsWith('Other: '));
                                            // Add either the specific value or just "Other"
                                            const newValue = otherHearAboutUs.trim();
                                            return this.setState({
                                                hearAboutUs: chain(filteredHearAboutUs)
                                                    .concat([newValue ? `Other: ${newValue}` : "Other"])
                                                    .uniq()
                                                    .value()
                                            });
                                        }}
                                    />)
                            }
                            <div className={style.buttons}>
                                <Button
                                    icon={<BespokenButtonIcon />}
                                    iconToRight
                                    label="Continue"
                                    accent
                                    onClick={this.completeOnboarding}
                                    disabled={!this.isComponentCompleted}
                                />
                            </div>
                        </div>
                        <div className={style.alt_text}>
                            <span>* Your company name will be used to create an organization within Bespoken.</span>
                        </div>
                    </section>
                    <BespokenMountains data-id="mountains" />
                </RightPanel>
            </div>
        );
    }
}

type SurveyTextInputProps = { autoFocus?: boolean; onChange: (value: string) => void; defaultValue: string; label: string, hasError?: boolean }
type SurveyTextInputState = { value: string }
const SurveyTextInput = class extends React.Component<SurveyTextInputProps, SurveyTextInputState> {
    constructor(props: SurveyTextInputProps) {
        super(props);

        this.state = {
            value: props.defaultValue || ""
        };
    }

    render(): false | JSX.Element {
        const { autoFocus, hasError, onChange, label } = this.props
        return (
            <div className={style.survey_text_input}>
                <label className={style.title}>{label}</label>
                <Input className={cn(style.control, cnToggle(hasError, style.error))} value={this.state.value} onChange={value => this.setState({ value }, () => attemptInvoke(onChange, this.state.value))} maxLength={50}  {...{ autoFocus }} />
            </div>
        )
    }
}


type SurveyCheckboxProps = {
    onChange: (it: { checked: boolean; label: string, value: string }) => void;
    label: string;
    value: string;
    checked?: boolean;
}
type SurveyCheckboxState = { value: string; label: string; checked: boolean }

const SurveyCheckbox = class extends React.Component<SurveyCheckboxProps, SurveyCheckboxState> {
    constructor(props: SurveyCheckboxProps) {
        super(props);

        this.state = {
            label: props.label,
            value: props.value,
            checked: props.checked || false, // Initialize with props.checked
        };
    }

    componentDidUpdate(prevProps: SurveyCheckboxProps) {
        // Update state if checked prop changes
        if (this.props.checked !== prevProps.checked) {
            this.setState({ checked: this.props.checked || false });
        }
    }

    render(): false | JSX.Element {
        const { onChange } = this.props
        const { value, label, checked } = this.state
        return (
            <Checkbox label={label}
                checked={checked}
                onChange={checked => this.setState({ checked }, () => attemptInvoke(onChange, { checked: this.state.checked, value, label }))} />
        )
    }
}


type SurveyOtherInputProps = { autoFocus?: boolean; label?: string; editable: boolean; onChange: (value: string) => void; }
type SurveyOtherInputState = { value: string; editable: boolean; autoFocus: boolean; }
const SurveyOtherInput = class extends React.Component<SurveyOtherInputProps, SurveyOtherInputState> {
    constructor(props: SurveyOtherInputProps) {
        super(props);

        this.state = {
            value: "",
            editable: false,
            autoFocus: false,
        };
    }

    componentWillReceiveProps(nextProps: Readonly<SurveyOtherInputProps>, nextContext: any): void {
        if (nextProps.editable !== this.state.editable) {
            const { editable } = nextProps
            this.setState({ editable, value: "", autoFocus: true })
        }
    }

    render(): false | JSX.Element {
        const { onChange, label } = this.props
        const { editable, autoFocus, value } = this.state
        return (
            <div className={style.survey_other_input}>
                {(!isEmpty(label) && <label className={style.title}>{label}</label>)}
                <Input className={style.control} disabled={!editable} value={value} onChange={value => this.setState({ value }, () => attemptInvoke(onChange, this.state.value))} maxLength={30}  {...{ autoFocus }} />
            </div>
        )
    }
}


type SurveyJobTitleProps = { label: string; onChange: (val: any) => void; }
type SurveyJobTitleState = { items: any[]; selectedValue: string; }
const SurveyJobTitle = class extends React.Component<SurveyJobTitleProps, SurveyJobTitleState> {

    constructor(props: SurveyJobTitleProps) {
        super(props)

        const items = chain(JOB_TITLES)
            .sort()
            .value()
        const selectedValue = JOB_TITLES[0].value

        this.state = {
            items,
            selectedValue
        }
    }

    render() {
        const { onChange, label } = this.props
        const { items, selectedValue } = this.state
        // Using random id to force close popup when value changed
        const uniqId = uniqueId()
        return (
            <div className={style.survey_jobtitle}>
                <label className={style.title}>{label}</label>
                <Dropdown
                    className={style.control}
                    key={`jobtitle-dropdown-${uniqId}`}
                    source={items}
                    value={selectedValue}
                    onChange={selectedValue => this.setState({ selectedValue }, () => attemptInvoke(onChange, this.state.selectedValue))} />
            </div>

        )
    }
}


type SurveyEmailValidationProps = { emailToValidate: string; onChange: (val: any) => void; }
type SurveyEmailValidationState = { email: string, valid: boolean, empty: boolean, message: string }
const SurveyEmailValidation = class extends React.Component<SurveyEmailValidationProps, SurveyEmailValidationState> {

    timeoutId: any = undefined;

    constructor(props: SurveyEmailValidationProps) {
        super(props)

        this.state = {
            email: props.emailToValidate,
            valid: undefined,
            empty: true,
            message: ""
        }
    }

    componentWillReceiveProps(nextProps: Readonly<SurveyEmailValidationProps>, nextContext: any): void {
        clearTimeout(this.timeoutId)

        if (nextProps.emailToValidate !== this.state.email) {
            const { emailToValidate: email } = nextProps
            this.setState({ email }, () => {
                this.timeoutId = setTimeout(() => this.validateEmail(), 1000)
            })
        }
    }

    async validateEmail() {
        const { email } = this.state
        const { onChange } = this.props
        const completeCallback = () => attemptInvoke(onChange, this.state.valid)

        if (isEmpty(email)) {
            return this.setState({ valid: false, message: "" }, completeCallback)
        }

        const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(toLower(email))) {
            return this.setState({ valid: false, message: "Please enter a valid email." }, completeCallback)
        }

        let message, exists, hasError;
        try {
            const userExistsResult = await fetchInternalApi(`/login/userExists`, "POST", { email },
                { 'Content-Type': 'application/json' }, false);
            exists = userExistsResult === 'true';
            message = 'This email is already registered. Please use a different one.';
        } catch (error) {
            hasError = true;
            message = 'There was a server error';
        }

        if (exists) { return this.setState({ valid: false, message }, completeCallback) }
        if (hasError) { return this.setState({ valid: false, message }, completeCallback) }

        return this.setState({ valid: true, message: '' }, completeCallback)
    }

    render() {
        const { message } = this.state

        return (
            <div className={style.survey_emailerror}>
                <label className={style.title}>&nbsp;</label>
                <span className={style.control}>{message}</span>
            </div>
        )
    }
}

