import * as React from 'react';
import * as lottie from 'lottie-web';

class LottieAnimation extends React.Component<{ animationData: any, width?: number, height?: number }> {
    private container: HTMLDivElement | null = null;
    private anim: any = null;

    componentDidMount() {
        const lottieInstance = lottie as any; // Type assertion here
        try {
            this.anim = lottieInstance.loadAnimation({
                container: this.container,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: this.props.animationData,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                }
            });
        } catch (error) {
            console.error('Error loading animation:', error);
        }
    }

    componentWillUnmount() {
        if (this.anim) {
            this.anim.destroy();
        }
    }

    play = () => {
        if (this.anim) {
            this.anim.play();
        }
    }

    pause = () => {
        if (this.anim) {
            this.anim.pause();
        }
    }

    stop = () => {
        if (this.anim) {
            this.anim.stop();
        }
    }

    render() {
        const { width = 24, height = 24 } = this.props;
        return <div ref={(el) => this.container = el} style={{ width: `${width}px`, height: `${height}px` }} />;
    }
}


export default LottieAnimation;