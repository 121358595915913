import {PlanIdTypes} from "../constants/stripe";
import {Source} from "../models/source-legacy";
import {User, UserDetails} from "../models/user";
import sleep from "../utils/sleep";
import auth from "./auth";
import { fetchInternalApi } from "./internal-api";
import listeners from "./listeners";
import listener from "./listeners";
// import source from "./source";
import {Payment} from "./subscription-plan";

const suscriptionPlan = new Payment();

const app_id = process.env.INTERCOM_APP_ID

export const sendUserPlanUpdateToIntercom = async (globalWindow: any, sources: Source[] = [], user: User,
                                                   userDetail?: UserDetails) => {
    if (!userDetail) {
        userDetail = await auth.currentUserDetails();
    }

    const userPlanId = userDetail && userDetail.planId;
    const isTrialUser = userDetail.isTrialPlan;
    const numMonitoredSources = suscriptionPlan.getMonitoredSkillNumber(sources);

    const updateData = {userPlan: userPlanId, numMonitoredSources, numSources: sources.length};
    await updateIntercom(globalWindow, updateData);
};

export const updateIntercom = async (globalWindow: any, updateData?: any, email?: string) => {
    if (!email) {
        return;
    }
    if (updateData) {
        const user_hash = await fetchInternalApi(
            `/helpers/intercomUserHash/${encodeURIComponent(email)}`,
            "GET",
            undefined,
            {"Content-Type": "application/json"},
            false);

        const userProps = {app_id, ...updateData, user_hash, email};
        globalWindow.Intercom("update", userProps);
    }
};

export const startIntercomTour = async (globalWindow: any, tourId?: any) => {

    // welcome tour in dev  492109
    // welcome tour in prod 492290
    globalWindow.Intercom("startTour", tourId);
};

export const shutdownIntercom = async (globalWindow: any, email: string) => {
    if (!email) {
        return;
    }
    const user_hash = await fetchInternalApi(
        `/helpers/intercomUserHash/${encodeURIComponent(email)}`,
        "GET",
        undefined,
        {"Content-Type": "application/json"},
        false);
    globalWindow.Intercom("shutdown", {user_hash, email});
};

export const bootIntercom = async (globalWindow: any, user: any) => {
    // const user_hash = await source.getUserHash(user.email);
    // const userStatus = listener.getStatus(user);

    // globalWindow.Intercom("boot", {
    //     app_id,
    //     name: user.displayName || user.name,
    //     email: user.email,
    //     userRequiresActivation: user.userRequiresActivation,
    //     // userStatus,
    //     user_hash,
    //     onboardingAppointmentSet: false,
    // });
    // await sleep(600);
};

export const updateInitDataToIntercom = async (globalWindow: any, sources: Source[], user: User,
                                               userDetail?: UserDetails, others?: any) => {
    if (!userDetail) {
        userDetail = await auth.currentUserDetails();
    }

    const userPlanId = userDetail.planId;
    const isTrialUser = userDetail.isTrialPlan;
    const numMonitoredSources = suscriptionPlan.getMonitoredSkillNumber(sources);
    const numVirtualDevices = await suscriptionPlan.getNumberVirtualDevicesUsed(userDetail);
    const surveyData = userDetail.surveyData;

    updateIntercom(globalWindow, {
        name: user.displayName || userDetail.name,
        jobTitle: userDetail.jobTitle,
        email: user.email,
        hide_default_launcher: false,
        // ibmUser,
        ...surveyData,
        ...others
    });
};

export const sendIntercomDataThroughEvent = async (globalWindow: any, subscriptionUpdate: boolean, planId: string, oldPlanId: string,
                                                   oldPLatform: string, platform: string, isTrial: boolean, isYearly: boolean) => {
    if (isTrial) {
        // case when the user subscribes by being trial
        const metadata = await suscriptionPlan.getsubscribeMetadata(planId, platform ? platform : "Alexa AND Google", isYearly);
        globalWindow && sendSubscribeIntercomEvent(globalWindow, metadata);
    }
    if (subscriptionUpdate && planId === PlanIdTypes.SINGLE_PLATFORM) {
        if (isTrial) return;
        // case when user downgrade plan
        let metadata = await suscriptionPlan.getDowngradePlanMetadata(oldPlanId, planId, platform, isYearly);
        if (planId === oldPlanId) {
            globalWindow && sendPlatformChangeIntercomEvent(globalWindow, metadata);
        } else {
            globalWindow && sendDowngradesIntercomEvent(globalWindow, metadata);
        }
    } else if (subscriptionUpdate && (planId === PlanIdTypes.VIRTUAL_ASSISTANTS) &&
        (oldPlanId !== PlanIdTypes.VIRTUAL_ASSISTANTS)) {
        // case when user upgrade plan
        if (isTrial) return;
        const metadata = await suscriptionPlan.getUpgradePlanMetadata(oldPlanId, planId, oldPLatform, isYearly);
        globalWindow && sendUpgradesIntercomEvent(globalWindow, metadata);
    }
};

export const sendSubscribeIntercomEvent = (globalWindow: any, metadata?: any) => {
    sendIntercomEvent(globalWindow, "user-subscribes", metadata);
};

export const sendUnsubscribeIntercomEvent = (globalWindow: any, metadata?: any) => {
    sendIntercomEvent(globalWindow, "user-unsubscribes", metadata);
};

export const sendUpgradesIntercomEvent = (globalWindow: any, metadata?: any) => {
    sendIntercomEvent(globalWindow, "user-upgrades-plan", metadata);
};
export const sendPlatformChangeIntercomEvent = (globalWindow: any, metadata?: any) => {
    sendIntercomEvent(globalWindow, "user-change-platform", metadata);
};
export const sendDowngradesIntercomEvent = (globalWindow: any, metadata?: any) => {
    sendIntercomEvent(globalWindow, "user-downgrades-plan", metadata);
};
export const sendAccountDeletedAtIntercomEvent = (globalWindow: any) => {
    sendIntercomEvent(globalWindow, "account-deleted-at");
};

export const sendIntercomEvent = (globalWindow: any, eventName: string, metadata?: any) => {
    metadata = metadata ? metadata : {};
    globalWindow.Intercom("trackEvent", eventName, metadata);
};
