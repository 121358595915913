import * as cn from "classnames";
import { chain, defaultTo, first, isEmpty, startsWith, trim } from "lodash";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { connect } from "react-redux";
import { State } from "../../../reducers";
import { copyToClipboard, ifEmpty } from "../../../utils/ReactHelpers";
import { DEBUG, ERROR, WARN } from "../debug-panel/DebugPanel";
import CheckmarkIcon from "./template-org-and-user.svg";

const Styles = require("./styles.scss");

const DEFAULT_NO_COLOR = "#939598"

interface DispatchToProps { }
function mapDispatchToProps(dispatch: any) { return {} }

interface StateToProps {
    userId?: string;
    organizationId?: string;
    email?: string;
    userName: string;
    organizationName: string;
    userAvatarUrl?: string;
    organizationAvatarUrl?: string;
    organizarionColor?: string;
    userColor?: string;
}
function mapStateToProps(state: State.All): StateToProps {
    return {
        userId: state?.user?.currentUser?.id,
        email: state?.context?.authUser?.email,
        organizationId: state?.organization?.selectedOrganization?.id,
        userName: state?.user?.currentUser?.name,
        organizationName: state?.organization?.selectedOrganization?.name,
        userAvatarUrl: state?.user?.currentUser?.profile?.avatarImageUrl,
        organizationAvatarUrl: state?.organization?.selectedOrganization?.profile?.avatarImageUrl,
        organizarionColor: ifEmpty(state?.organization?.selectedOrganization?.profile?.color, DEFAULT_NO_COLOR),
        userColor: ifEmpty(state?.user?.currentUser?.profile?.color, DEFAULT_NO_COLOR),
    }
}

interface ExposedProps {
}
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface ComponentProps extends DispatchToProps, StateToProps, ExposedProps { }

interface ComponentState {
    userInitials: string;
    organizationInitial: string;
    userAvatarUrl: string;
    organizationAvatarUrl: string;
    organizarionColor: string;
    userColor: string;
    hasOrganizationIcon?: boolean;
    hasUserIcon?: boolean;
}

class Component extends React.Component<ComponentProps, ComponentState> {

    static defaultProps: ComponentProps = {
        userName: undefined,
        organizationName: undefined,
        userAvatarUrl: undefined,
        organizationAvatarUrl: undefined,
        organizarionColor: undefined,
        userColor: undefined
    }

    constructor(props: ComponentProps) {
        super(props);
        this.state = {
            userInitials: this.buildInitials(props?.userName, 2),
            userAvatarUrl: props?.userAvatarUrl,
            organizationInitial: this.buildInitials(props?.organizationName, 2),
            organizationAvatarUrl: props?.organizationAvatarUrl,
            organizarionColor: ifEmpty(props?.organizarionColor, DEFAULT_NO_COLOR),
            userColor: ifEmpty(props?.userColor, DEFAULT_NO_COLOR),
            hasOrganizationIcon: false,
            hasUserIcon: false,
        }
    }

    async componentDidMount(): Promise<void> {
        const hasOrganizationIcon = this.state.organizationAvatarUrl ? await fetch(this.state.organizationAvatarUrl).then(res => startsWith(res?.headers?.get('content-type'), 'image')).catch(err => false) : false
        const hasUserIcon = this.state.userAvatarUrl ? await fetch(this.state.userAvatarUrl).then(res => startsWith(res?.headers?.get('content-type'), 'image')).catch(err => false) : false

        this.setState({ hasOrganizationIcon, hasUserIcon })
    }

    async componentWillReceiveProps(nextProps: Readonly<ComponentProps>, nextContext: any): Promise<void> {
        if (this.props.organizationId === nextProps.organizationId && this.props.userId === nextProps.userId) {
            return
        }

        const userInitials = this.buildInitials(nextProps?.userName, 2);
        const userAvatarUrl = nextProps?.userAvatarUrl;
        const userColor = ifEmpty(nextProps?.userColor, DEFAULT_NO_COLOR);
        const hasUserIcon = this.state.userAvatarUrl ? await fetch(nextProps?.userAvatarUrl).then(res => startsWith(res?.headers?.get('content-type'), 'image')).catch(err => false) : false

        const organizationInitial = this.buildInitials(nextProps?.organizationName, 2);
        const organizationAvatarUrl = nextProps?.organizationAvatarUrl;
        const organizarionColor = ifEmpty(nextProps?.organizarionColor, DEFAULT_NO_COLOR);
        const hasOrganizationIcon = this.state.organizationAvatarUrl ? await fetch(nextProps?.organizationAvatarUrl).then(res => startsWith(res?.headers?.get('content-type'), 'image')).catch(err => false) : false

        this.setState({
            userInitials,
            userAvatarUrl,
            organizationInitial,
            organizationAvatarUrl,
            organizarionColor,
            userColor,
            hasOrganizationIcon,
            hasUserIcon
        })
    }

    buildInitials(value: string, numberOfLeters: number = 2) {
        return chain(value).snakeCase().toUpper().words().map(v => first(trim(v))).filter(v => !isEmpty(v)).take(numberOfLeters).map(v => isEmpty(v) ? '-' : v).join('').value()
    }

    render(): false | JSX.Element {
        return (
            <div
                className={cn(Styles.component)}
                title={`${this.props.userName} at ${this.props.organizationName}`}
                onClick={evt => {
                    if (evt.altKey) {
                        copyToClipboard(`userId: ${this.props.userId}\norganizationId: ${this.props.organizationId}\nemail: ${this.props.email}`)
                    }
                }}
                onDoubleClick={evt => {
                    if (evt.altKey) {
                        copyToClipboard(`${this.props.organizationId}`)
                    }
                }}
                ref={ref => {
                    const domNode = ReactDOM.findDOMNode(ref) as Element;
                    if (!domNode) return; // Add this check

                    const svg: SVGElement | null = domNode.querySelector('svg');
                    if (!svg) return; // Add this check

                    const leftCircle = svg.querySelector('[data-id="left-circle"]');
                    const rightCircleOutline = svg.querySelector('[data-id="right-outline"]');
                    const rightCircle = svg.querySelector('[data-id="right-circle"]');
                    const orgLinkedImage = svg.querySelector('[data-id="right-circle"]');
                    const userLinkedImage = svg.querySelector('[data-id="left-circle"]');
                    const leftCircleClippath = svg.querySelector('[data-id="clip-image-org"]');
                    const rightCircleClippath = svg.querySelector('[data-id="clip-image-user"]');
                    const labelUser = svg.querySelector('[data-id="user"]');
                    const labelOrg = svg.querySelector('[data-id="org"]');

                    leftCircle && leftCircle.setAttribute('style', `fill:${this.state.organizarionColor};`)

                    rightCircle && rightCircle.setAttribute('style', `fill:${this.state.userColor};`)
                    rightCircleOutline && rightCircleOutline.setAttribute('style', `fill:${this.state.userColor};`)

                    if (this.state.hasOrganizationIcon) {
                        labelOrg && (labelOrg.innerHTML = '')
                        orgLinkedImage && orgLinkedImage.setAttribute("xlink:href", "https://picsum.photos/200/300?random=" + Math.random())
                    } else {
                        labelOrg && (labelOrg.innerHTML = this.state.organizationInitial)
                        leftCircleClippath && leftCircleClippath.setAttribute("style", "visibility:hidden")
                    }

                    if (this.state.hasUserIcon) {
                        labelUser && (labelUser.innerHTML = '')
                        userLinkedImage && userLinkedImage.setAttribute("xlink:href", "https://picsum.photos/200/300?random=" + Math.random())
                    } else {
                        labelUser && (labelUser.innerHTML = this.state.userInitials)
                        rightCircleClippath && rightCircleClippath.setAttribute("style", "visibility:hidden")
                    }
                }}>
                <CheckmarkIcon />
            </div>
        )
    }
}

export const ProfileAvatar = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Component);
