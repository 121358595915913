import * as internal from "../../services/internal-api"
import * as newAuth from "../../services/new-auth";
import * as React from "react";
import * as redux from "react-redux";
import * as routerRedux from "react-router-redux";

const style = require("./loginStyles.scss");

interface ParamProps {
    token: string;
}

interface TokenLoginProps {
    params: ParamProps
}

interface DispatchToProps {
    goTo?: (path: string) => void;
}

interface TokenLoginState {

}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        goTo: (path: string) => dispatch(routerRedux.replace(path))
    };
}

function mapStateToProps(state: any) {
    return {}
}

function mergeProps(ownProps: any, stateProps: any, dispatchProps: DispatchToProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}
interface PageProps extends DispatchToProps, TokenLoginProps { }


export class TokenLogin extends React.Component<PageProps, TokenLoginState> {
    constructor(props: TokenLoginProps) {
        super(props);
        this.state = { };
    }

    async componentDidMount () {
        const props: any = this.props as any
        const response = await internal.fetchInternalApi(`/users/token/${props.params.token}`)

        const auth: newAuth.Auth = new newAuth.FirebaseAuth();
        try {
            const signinResponse = await auth.signInWithCustomToken(response.authToken);
            this.props.goTo('/skills')
        } catch (e) {
            console.error('Error signing in: ', e.toString())
        }

    }


    render() {
        const allProps = this.props as any;
        const location = allProps.location;

        return (
          <div>
            Hello World!
          </div>
        );
    }
}

export const TokenLoginPage = redux.connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(TokenLogin);
