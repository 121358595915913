import * as React from "react";
import * as cn from "classnames";
import { format } from 'date-fns';
import { TestRunWithResultsResponse, TestResultInteractionsResponse } from '../../services/bespoken-reporting-api';
import { LoaderIndicator, KPICard } from '../../components/ConsolidatedReports';
import { VideoPlayerComponent } from "../Video/VideoPlayer";
import { DebugIconButton } from "../lunacy/icon-buttons/IconButton";
import { DebugPanel } from "../DebugPanel/DebugPanel";
import { InlineIVRPlayer } from './InlineIVRPlayer';
import { Button, IconButton } from "react-toolbox/lib/button";
import { Chip } from "react-toolbox/lib/chip";
import BespokenIconExpanded from "../../../assets/bespoken_icon_expanded.svg";
import BespokenIconCollapsed from "../../../assets/bespoken_icon_collapsed.svg";
import BespokenIconPassed from "../../../assets/bespoken_icon_passed.svg";
import BespokenIconFailed from "../../../assets/bespoken_icon_failed.svg";
import BespokenIconSkipped from "../../../assets/bespoken_icon_skipped.svg";
import KpiExecuted from "../../../assets/reporting-kpi-executed.svg";
import KpiFailure from "../../../assets/reporting-kpi-failure.svg";
import KpiSuccess from "../../../assets/reporting-kpi-success.svg";
import BespokenIconCalender from "../../../assets/unicons/calender.svg";
import BespokenIconServer from "../../../assets/unicons/server.svg";
import BespokenIconClock from "../../../assets/unicons/clock.svg";
import { BespokenModal } from "../Modal/BespokenModal";

const Styles = require("./TestRunDetails.scss");
const DebugPanelStyles = require("../DebugPanel/DebugPanel.scss")

const TEST_STATUS_ICON: { [key: string]: JSX.Element } = {
  PASSED: (<BespokenIconPassed />),
  FAILED: (<BespokenIconFailed />),
  SKIPPED: (<BespokenIconSkipped />),
};

const SUITE_STATUS_COLOR: { [key: string]: string } = {
  PASSED: Styles.status_chip_passed,
  FAILED: Styles.status_chip_failed,
  SKIPPED: Styles.status_chip_skipped
};

interface TestRunDetailsProps {
  runId: string;
  customerId: string;
  reportingApi: any;
}

interface TestRunDetailsState {
  testRunData: TestRunWithResultsResponse | null;
  expandedTests: { [key: string]: boolean };
  loadedInteractions: { [key: string]: TestResultInteractionsResponse['interactions'] };
  showDebugModal: boolean;
  debugContent: any;
  allExpanded: boolean;
  filter: 'ALL' | 'PASSED' | 'FAILED' | 'SKIPPED';
}

interface RawConfig {
  platform?: string;
  phoneNumber?: string;
  voiceId?: string;
  locale?: string;
  virtualDeviceToken?: string;
  virtualDeviceConfig?: {
    url?: string;
    fuzzyMatching?: number;
  };
}

export class TestRunDetails extends React.Component<TestRunDetailsProps, TestRunDetailsState> {
  constructor(props: TestRunDetailsProps) {
    super(props);
    this.state = {
      testRunData: null,
      expandedTests: {},
      loadedInteractions: {},
      showDebugModal: false,
      debugContent: null,
      allExpanded: false,
      filter: 'ALL',
    };
  }

  async componentDidMount() {
    if (this.props.reportingApi) {
      await this.loadTestRunData();
    }
  }

  componentDidUpdate(prevProps: TestRunDetailsProps) {
    if (!prevProps.reportingApi && this.props.reportingApi) {
      this.loadTestRunData();
    }
  }

  loadTestRunData = async () => {
    const { runId, customerId } = this.props;
    const testRunData = await this.props.reportingApi.getTestRunWithResults({ customerId, testRunId: runId });
    this.setState({ testRunData: testRunData.resultSet });
  }

  loadTestInteractions = async (testResultId: string) => {
    const { runId, customerId } = this.props;
    const interactions = await this.props.reportingApi.getTestResultInteractions({ customerId, testRunId: runId, testResultId });
    this.setState(prevState => ({
      loadedInteractions: {
        ...prevState.loadedInteractions,
        [testResultId]: interactions.resultSet
      }
    }));
  }

  toggleTestExpansion = (testResultId: string) => {
    this.setState(prevState => ({
      expandedTests: {
        ...prevState.expandedTests,
        [testResultId]: !prevState.expandedTests[testResultId]
      }
    }));

    if (!this.state.loadedInteractions[testResultId]) {
      this.loadTestInteractions(testResultId);
    }
  }

  toggleAll = () => {
    const { testRunData, allExpanded } = this.state;
    if (!testRunData) return;

    const newExpandedTests: { [key: string]: boolean } = {};
    testRunData.test_suites.forEach(suite => {
      suite.tests.forEach(test => {
        newExpandedTests[test.test_result_id] = !allExpanded;
        if (!allExpanded && !this.state.loadedInteractions[test.test_result_id]) {
          this.loadTestInteractions(test.test_result_id);
        }
      });
    });

    this.setState({
      expandedTests: newExpandedTests,
      allExpanded: !allExpanded
    });
  }

  handleFilterChange = (filter: 'ALL' | 'PASSED' | 'FAILED' | 'SKIPPED') => {
    this.setState({ filter });
  }

  getTestCounts = () => {
    const { testRunData } = this.state;
    if (!testRunData) return { PASSED: 0, FAILED: 0, SKIPPED: 0, ALL: 0 };

    const counts = { PASSED: 0, FAILED: 0, SKIPPED: 0, ALL: 0 };
    testRunData.test_suites.forEach(suite => {
      suite.tests.forEach(test => {
        counts[test.test_result as keyof typeof counts]++;
        counts.ALL++;
      });
    });

    return counts;
  }

  renderFilterButtons = () => {
    const { filter } = this.state;
    const counts = this.getTestCounts();

    return (
      <div className={Styles.filter_buttons}>
        <Button
          className={cn(Styles.filter_button, filter === 'ALL' && Styles.active)}
          onClick={() => this.handleFilterChange('ALL')}
          disabled={counts.ALL === 0}
        >
          All ({counts.ALL})
        </Button>
        <Button
          className={cn(Styles.filter_button, filter === 'PASSED' && Styles.active)}
          onClick={() => this.handleFilterChange('PASSED')}
          disabled={counts.PASSED === 0}
        >
          Passed ({counts.PASSED})
        </Button>
        <Button
          className={cn(Styles.filter_button, filter === 'FAILED' && Styles.active)}
          onClick={() => this.handleFilterChange('FAILED')}
          disabled={counts.FAILED === 0}
        >
          Failed ({counts.FAILED})
        </Button>
        <Button
          className={cn(Styles.filter_button, filter === 'SKIPPED' && Styles.active)}
          onClick={() => this.handleFilterChange('SKIPPED')}
          disabled={counts.SKIPPED === 0}
        >
          Skipped ({counts.SKIPPED})
        </Button>
      </div>
    );
  }

  formatDuration(durationInSeconds: number): string {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    const parts = [];
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    if (seconds > 0 || parts.length === 0) parts.push(`${seconds}s`);

    return parts.join(' ');
  }

  renderTestRunInfo = () => {
    const { testRunData } = this.state;
    if (!testRunData) return null;

    const startTime = new Date(testRunData.test_run_start_timestamp);
    const endTime = new Date(testRunData.test_run_end_timestamp);
    const duration = Math.floor((endTime.getTime() - startTime.getTime()) / 1000); // in seconds

    const testCounts = testRunData.test_suites.reduce((counts, suite) => {
      suite.tests.forEach(test => {
        if (test.test_result === 'PASSED') counts.passed++;
        else if (test.test_result === 'FAILED') counts.failed++;
        else if (test.test_result === 'SKIPPED') counts.skipped++;
      });
      return counts;
    }, { passed: 0, failed: 0, skipped: 0 });

    const totalExecutedTests = testCounts.passed + testCounts.failed;
    const successRate = totalExecutedTests > 0 ? Math.round((testCounts.passed / totalExecutedTests) * 100) : 0;

    const defaultSvgProps = {
      viewBox: "0 0 34 34",
      width: "24",
      height: "24"
    }

    const svgFooterProps = {
      viewBox: "0 0 24 24",
      width: "20",
      height: "20"
    }

    const executedTestsLabel = [
      <div key="executed">Executed test{totalExecutedTests !== 1 ? 's' : ''}</div>,
      <div key="skipped" className={Styles.second_line}>
        ({testCounts.skipped} test{testCounts.skipped !== 1 ? 's' : ''} skipped)
      </div>
    ];

    const successRateLabel = [
      <div key="rate">Success rate</div>,
      <div key="total" className={Styles.second_line}>
        ({testCounts.passed}/{totalExecutedTests} tests passed)
      </div>
    ]

    const rawConfig = (testRunData.test_suites[0]?.raw_config || {}) as RawConfig;
    const testPlatform = rawConfig.platform;
    const contactInfo = ['phone', 'whatsapp', 'sms'].includes(testPlatform)
      ? rawConfig.phoneNumber
      : testPlatform === 'webchat'
        ? rawConfig.virtualDeviceConfig?.url
        : null;

    const showVoice = ['alexa', 'google', 'phone'].includes(testPlatform);

    return (
      <div className={Styles.test_run}>
        <div className={cn(Styles.test_run_info, Styles.test_run_title)}>
          <div className={Styles.test_run_name}>{testRunData.project_name}</div>
          <div className={Styles.suite_status}>
            <Chip className={SUITE_STATUS_COLOR[testRunData.test_run_result]}>
              <span>{testRunData.test_run_result}</span>
            </Chip>
          </div>
          <div className={Styles.test_run_footer}>
            <div><BespokenIconServer  {...svgFooterProps} /></div>
            <div className={Styles.footer_divider}>{testPlatform}</div>
            <div><BespokenIconCalender {...svgFooterProps} /></div>
            <div className={Styles.footer_divider}>{format(startTime, 'MM/dd/yy hh:mm a')}</div>
            <div><BespokenIconClock {...svgFooterProps} /></div>
            <div>{this.formatDuration(duration)}</div>
          </div>
        </div>
        <div className={Styles.kpi_card}>
          <KPICard
            data={totalExecutedTests}
            label={executedTestsLabel}
            icon={<KpiExecuted {...defaultSvgProps} />}
          />
        </div>
        <div className={Styles.kpi_card}>
          <KPICard
            data={successRate}
            label={successRateLabel}
            suffix="%"
            icon={testRunData.test_run_result === 'PASSED' ? <KpiSuccess {...defaultSvgProps} /> : <KpiFailure {...defaultSvgProps} />}
          />
        </div>
        <div className={Styles.section_title}>Environment</div>
        {contactInfo && (
          <div className={Styles.environment_card_large}>
            <div className={Styles.environment_label}>{testPlatform === 'webchat' ? 'URL' : 'Phone Number'}</div>
            <div className={Styles.environment_value}>{contactInfo}</div>
          </div>
        )}
        {showVoice && (
          <div className={Styles.environment_card_small}>
            <div className={Styles.environment_label}>Locale</div>
            <div className={Styles.environment_value}>{rawConfig.locale || 'N/A'}</div>
          </div>
        )}
        {showVoice && (
          <div className={Styles.environment_card_small}>
            <div className={Styles.environment_label}>Voice</div>
            <div className={Styles.environment_value}>{rawConfig.voiceId || 'N/A'}</div>
          </div>
        )}
        <div className={Styles.environment_card_large}>
          <div className={Styles.environment_label}>Virtual Device</div>
          <div className={Styles.environment_value}>{testRunData.virtual_device_name || rawConfig.virtualDeviceToken || 'N/A'}</div>
        </div>
        <div className={Styles.environment_card_small}>
          <div className={Styles.environment_label}>Assertion Fuzzy Threshold</div>
          <div className={Styles.environment_value}>{rawConfig.virtualDeviceConfig?.fuzzyMatching?.toString() || 'N/A'}</div>
        </div>

        <div className={Styles.section_title}>Tests</div>
      </div>
    );
  }

  renderTestSuite = (testSuite: TestRunWithResultsResponse['test_suites'][0]) => {
    const { filter } = this.state;
    const filteredTests = testSuite.tests.filter(test =>
      filter === 'ALL' || test.test_result === filter
    );

    if (filteredTests.length === 0) return null;

    return (
      <div key={testSuite.test_suite_id} className={cn(Styles.test_suite_panel, Styles.suite_container)}>
        {filteredTests.map(this.renderTest)}
      </div>
    );
  }

  renderTest = (test: TestRunWithResultsResponse['test_suites'][0]['tests'][0]) => {
    const isExpanded = this.state.expandedTests[test.test_result_id];
    const interactions = this.state.loadedInteractions[test.test_result_id];
    const isVisible = this.state.filter === 'ALL' || test.test_result === this.state.filter;

    if (!isVisible) {
      return null;
    }

    return (
      <div key={test.test_result_id}>
        <div className={Styles.test_expand_button}>
          <a className={Styles.button_component} onClick={() => this.toggleTestExpansion(test.test_result_id)}>
            {isExpanded ? <BespokenIconExpanded /> : <BespokenIconCollapsed />}
          </a>
        </div>
        <div className={Styles.test_title}>
          <span className={cn(Styles.title, test.test_result === 'FAILED' ? Styles.text_failed : test.test_result === 'SKIPPED' ? Styles.text_skipped : undefined)}>
            {test.test_name}
          </span>
        </div>
        {test.test_platform === 'phone' && test.test_result !== 'SKIPPED' && (
          <div className={Styles.test_inline_player}>
            <InlineIVRPlayer conversationId={test.test_conversation_id} key={test.test_result_id} />
          </div>
        )}
        {isExpanded && test.test_platform === 'webchat' && test.test_result !== 'SKIPPED' && (
          <div className={Styles.test_inline_video}>
            <VideoPlayerComponent
              autoPlay={false}
              height={300}
              loaded={true}
              url={`https://store.bespoken.io/store/video/${test.test_conversation_id}.mp4`}
            />
          </div>
        )}
        {isExpanded && (
          <div className={Styles.interaction_details}>
            {interactions ? this.renderInteractions(interactions) : <LoaderIndicator />}
          </div>
        )}
      </div>
    );
  }

  renderInteractions = (interactions: TestResultInteractionsResponse['interactions']) => {
    return interactions.map((interaction, index) => (
      <div key={index} className={Styles.interaction_container}>
        <div className={cn(Styles.interaction_status_icon)}>{TEST_STATUS_ICON[interaction.result]}</div>
        <div className={Styles.interaction_title}>
          <span className={cn(interaction.result === 'FAILED' ? Styles.text_failed : undefined)}>
            {interaction.message}
          </span>
        </div>
        {this.renderAssertionDetails(interaction)}
      </div>
    ));
  }

  renderAssertionDetails = (interaction: TestResultInteractionsResponse['interactions'][0]) => {
    if (interaction.result === 'SKIPPED') return null;

    const assertion = JSON.parse(interaction.assertion);
    const errors = JSON.parse(interaction.errors);

    return (
      <div className={Styles.assertion_slot}>
        <span className={Styles.assertion_title}>Expected:</span>
        <span className={Styles.assertion_content}>
          {assertion.map((a: any, index: number) => (
            <span key={index}>{`${a._path} ${a._operator} ${a._localizedValue}`}</span>
          ))}
        </span>
        <div>
          {interaction.step_raw_response && (
            <DebugIconButton
              color={"color_spanish_gray"}
              title={"View Debug Output"}
              onClick={() => this.showDebugModal(interaction.step_raw_response)}
            />
          )}
        </div>
        <span className={Styles.assertion_title}>Actual:</span>
        <span className={Styles.assertion_content}>{interaction.transcript}</span>
      </div>
    );
  }

  showDebugModal = (content: any) => {
    this.setState({ showDebugModal: true, debugContent: content });
  }

  hideDebugModal = () => {
    this.setState({ showDebugModal: false, debugContent: null });
  }

  render() {
    const { testRunData, showDebugModal, debugContent, allExpanded } = this.state;

    if (!testRunData) {
      return <LoaderIndicator />;
    }

    return (
      <div className={Styles.test_details_layout}>
        {this.renderTestRunInfo()}
        <div className={Styles.top_bar}>
          {this.renderFilterButtons()}
          <Button
            className={Styles.button_component}
            onClick={this.toggleAll}
          >
            {allExpanded ? "COLLAPSE ALL" : "EXPAND ALL"}
          </Button>
        </div>

        {testRunData.test_suites.map(this.renderTestSuite)}

        {showDebugModal && (
          <BespokenModal className={cn(DebugPanelStyles.dialog)}
            showModal={this.state.showDebugModal}
            title={"Debug Output"}
            redTitleBorder
            dialogToggle={() => this.hideDebugModal()}>

            <DebugPanel content={this.state.debugContent} />
          </BespokenModal>
        )}
      </div>
    );
  }
}