import { chain, clone, defaultTo, get, isEmpty, isEqual, pick, trim } from "lodash";
import * as React from "react";
import { connect } from "react-redux";

import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import Button, { IconButton } from "react-toolbox/lib/button";
import FontIcon from "react-toolbox/lib/font_icon";
import Input from "react-toolbox/lib/input";
import BespokenAddIcon from "../../../../assets/bespoken_add_icon.svg";
import BespokenMenuDots from "../../../../assets/bespoken_menu_dots.svg";
import BespokenRunAllIcon from "../../../../assets/bespoken_run_all_icon.svg";
import { TestResultStatus } from "../../../constants";
import Source from "../../../models/source";
import { AudioPlayer } from "../../AudioPlayer";
import { IconLabelButton } from "../../IconLabelButton/IconLabelButton";
import { BespokenMenu, BespokenMenuItem } from "../../Menu";
import { State } from "../../../reducers";
import YamlHelper from "../../../helpers/yaml-helper";
import { EMPTY_AUDIO } from "../../ConsolidatedReports/EmptyAudio";
import { InplaceEditor, TextBlueLabelSmall, TextNormal } from "../../lunacy";
import { DEBUG } from "../../lunacy/debug-panel/DebugPanel";
import { updateTestDescription, updateTestId } from "../../../actions/source";
import { TooltipDiv } from "./test-editor";
import { VideoPlayerComponent } from "../../Video/VideoPlayer"
import StreamPlayer from "../../AudioPlayer/StreamPlayer";

const SourceSelectorItemStyle = require("../../SourceSelectorV2/SourceSelectorItemStyle.scss");
const bespokenButton = require("../../../themes/bespoken_button.scss");

const validationStyle = require("../../validation/ValidationParentComponentStyle.scss");

interface TestListProps {
    source: Source;
    testResults?: any;
    selectedTestIndex: number;
    runningTestIndexes: number[];

    addTest: (name: string) => void;
    deleteTest: (index: number) => void;
    cloneTest: (index: number) => void;
    updateTestName: (testIndex: number, name: string) => void;
    updateSkipFlag: (testIndex: number, value: boolean) => void;
    updateOnlyFlag: (testIndex: number, value: boolean) => void;
    handleChangeSelectedTestIndex: (index: number) => void;
    handleRunAll: () => void;

    reorderTest: (oldIndex: number, newIndex: number) => void;

    updateTestId: (index: number, testId: string) => void;
    updateTestDescription: (index: number, testId: string) => void;
}

interface ConnectedProps {
    currentTestResults?: any;
    currentConfig?: any;
    defaultPlatformId: string;
}

interface AudioResults {
    hasAudioAvailable?: boolean;
    audioUrl?: string;
}

interface VideoResults {
    hasVideoAvailable?: boolean;
    videoUrl?: string;
}

interface TestListState {
    testNameEditorValue: string;
    addNewTest: boolean;
    grabbing: boolean;
    editIndex: number;

    currentAudioResult: AudioResults;
    listOfAudioResults: AudioResults[];

    showRecordedCall?: boolean;

    loadingAudioFile?: boolean;
    loadingVideoFile?: boolean;

    showRecordedVideo?: boolean;
    currentVideoResult: VideoResults;
    listOfVideoResults: VideoResults[];
}

const DragHandle = SortableHandle(() => <span />);
const SortableParentContainer = SortableContainer(({ children }: any) => {
    return <div className={validationStyle.sortable_list}>{children}</div>;
});
const SortableItem = SortableElement(({ value, itemToRender, loadingResults, grabbing }: any) =>
    <div title={value} className={`${validationStyle.drag_sortable} ${grabbing ? validationStyle.grabbing : ""}`}>
        {
            !loadingResults &&
            <DragHandle />
        }
        {itemToRender}
    </div>);

type ComponentProps = TestListProps & ConnectedProps

export class TestList extends React.Component<ComponentProps, TestListState> {


    constructor(props: ComponentProps) {
        super(props);

        this.state = {
            editIndex: -1,
            testNameEditorValue: '',
            addNewTest: false,
            grabbing: false,

            currentAudioResult: undefined,
            listOfAudioResults: [],

            showRecordedCall: false,

            currentVideoResult: undefined,
            listOfVideoResults: [],
            showRecordedVideo: false
        };

        StreamPlayer.instance().onPlaying(() => {
            this.setState(prevState => ({
                ...prevState,
                currentAudioResult: { audioUrl: undefined, hasAudioAvailable: true }
            }));
        })
    }

    componentDidMount(): void {
        const platform = this.props.currentConfig?.platform || this.props?.defaultPlatformId;

        this.setState({
            showRecordedCall: this.props?.currentConfig?.virtualDeviceConfig?.recordCall,
            showRecordedVideo: platform === 'webchat',
        })
    }

    async componentWillReceiveProps(nextProps: Readonly<ComponentProps>, nextContext: any): Promise<void> {
        const newState: TestListState = this.state

        if (!isEqual(this.props.currentConfig, nextProps?.currentConfig)) {
            const isIVR = (nextProps?.currentConfig?.platform === "phone")
            newState.showRecordedCall = isIVR && !!nextProps?.currentConfig?.virtualDeviceConfig?.recordCall
            newState.showRecordedVideo = nextProps?.currentConfig?.platform === "webchat"
        }

        if (!isEqual(this.props.currentTestResults, nextProps.currentTestResults)) {
            if (newState.showRecordedCall) {
                const testResults = nextProps.currentTestResults || [];
                newState.loadingAudioFile = false;

                const isAnyTestRunning = nextProps.runningTestIndexes.length > 0;
                if (!isAnyTestRunning || this.checkIfExecutionCompleted(testResults)) {
                    newState.listOfAudioResults = await new Promise(resolve => setTimeout(resolve, 2000))
                        .then(() => this.getAudioResults(testResults));
                    newState.loadingAudioFile = false;
                    newState.currentAudioResult = newState.listOfAudioResults[nextProps.selectedTestIndex];
                }
            }

            if (newState.showRecordedVideo) {
                const testResults = nextProps.currentTestResults || [];
                newState.loadingVideoFile = true;

                const isAnyTestRunning = nextProps.runningTestIndexes.length > 0;
                if (!isAnyTestRunning || this.checkIfExecutionCompleted(testResults)) {
                    newState.listOfVideoResults = await new Promise(resolve => setTimeout(resolve, 2000))
                        .then(() => this.getVideoResults(testResults));
                    newState.currentVideoResult = newState.listOfVideoResults[nextProps.selectedTestIndex];
                    newState.loadingVideoFile = false;
                }
            }
        }

        if (!newState.showRecordedCall) {
            newState.listOfAudioResults = []
            newState.currentAudioResult = undefined
        }

        this.setState({ ...newState })
    }

    async getAudioResults(testResults: { conversationId: string }[]): Promise<AudioResults[]> {
        if (isEmpty(testResults)) {
            return []
        }

        return Promise.all(
            testResults
                .map((it: any) => {
                    const { conversationId } = it
                    if (isEmpty(conversationId)) {
                        return { hasAudioAvailable: false }
                    }
                    return this.getRecordCallUrl(it.conversationId)
                        .then(audioUrl => ({ hasAudioAvailable: true, audioUrl }))
                })
        )
    }


    async getVideoResults(testResults: { conversationId: string }[]): Promise<VideoResults[]> {
        if (isEmpty(testResults)) {
            return []
        }

        return Promise.all(
            testResults
                .map((it: any) => {
                    const { conversationId } = it
                    if (isEmpty(conversationId)) {
                        return { hasVideoAvailable: false }
                    }
                    return { hasVideoAvailable: true, videoUrl: this.getRecordedVideoUrl(conversationId) }
                })
        )
    }

    async getRecordCallUrl(conversationId: any): Promise<string> {
        const VIRTUAL_DEVICE_TWILIO_URL = process.env.VIRTUAL_DEVICE_TWILIO_URL

        return fetch(`${VIRTUAL_DEVICE_TWILIO_URL}/twilio_recording?callSid=${conversationId}`)
            .then(r => r.json())
            .then(({ recordingUrl }) => recordingUrl)
            .catch(err => err.message)
    }

    getRecordedVideoUrl(conversationId: any): string {
        return `https://store.bespoken.io/store/video/bspk-test-robot/${conversationId}.mp4`;
    }

    checkIfExecutionCompleted(testResults: any[]): boolean {
        return (testResults || [])
            .map((i: any) => get(i, 'interactions'))
            .reduce((prev: any, curr: any) => prev.concat(curr), [])
            .map((i: any) => get(i, 'items'))
            .reduce((prev: any, curr: any) => prev.concat(curr), [])
            .map((i: any) => get(i, 'status'))
            .filter((i: any) => i === TestResultStatus.PENDING).length === 0
    }

    onSortStart = () => {
        this.setState(prevState => ({
            ...prevState,
            grabbing: true,
        }));
    }

    onSortEnd = ({ oldIndex, newIndex }: any) => {
        const { listOfAudioResults } = this.state
        const { audioUrl, hasAudioAvailable } = (listOfAudioResults || [])[newIndex] || {}
        const testMetadata = this.props?.source?.meta?.testMetadata || []
        if (oldIndex !== newIndex) {
            this.props.reorderTest(oldIndex, newIndex);
            const temp = clone(testMetadata[oldIndex])
            const newIndexTestMetadata = clone(testMetadata[newIndex])

            this.props.updateTestId(oldIndex, newIndexTestMetadata?.testId)
            this.props.updateTestDescription(oldIndex, newIndexTestMetadata?.testDescription)
            this.props.updateTestId(newIndex, temp?.testId)
            this.props.updateTestDescription(newIndex, temp?.testDescription)
        }

        this.setState(prevState => ({
            ...prevState,
            grabbing: false,

            currentAudioResult: { audioUrl, hasAudioAvailable }
        }));
        this.props.handleChangeSelectedTestIndex(newIndex);
    }

    handleTestCreateNameBlur = async () => {
        this.setState(prevState => ({
            ...prevState,
            addNewTest: false,
            testNameEditorValue: "",
        }));
    }

    handleTestCreateNameKeyDown = async (event: any) => {
        // keycodes - escape: 27, enter: 13, tab: 9
        if (event.keyCode === 27) {
            this.setState(prevState => ({
                ...prevState,
                addNewTest: false,
                testNameEditorValue: "",
            }));
        } else if (event.keyCode === 13 || event.keyCode === 9) {
            const { yamlObject } = this.props.source;
            const { editIndex, testNameEditorValue } = this.state;
            const trimmedName = defaultTo((trim(testNameEditorValue) || undefined), `Test ${this.props.source.yamlObject.tests.length + 1}`);

            await this.props.addTest(trimmedName);
            this.setState(prevState => ({
                ...prevState,
                addNewTest: false,
                testNameEditorValue: "",
            }));
            this.props.handleChangeSelectedTestIndex(yamlObject.tests?.length);
        }
    }

    handleNameInputChange = async (value: any) => {
        this.setState(prevState => ({
            ...prevState,
            testNameEditorValue: value,
        }));
    }

    handleNameInputBlur = async (index: number) => {
        this.setState(prevState => ({
            ...prevState,
            editIndex: -1,
            testNameEditorValue: "",
        }));
    }

    handleNameInputKeyDown = async (index: number, event: any) => {
        // keycodes - escape: 27, enter: 13, tab: 9
        if (event.keyCode === 27) {
            this.setState(prevState => ({
                ...prevState,
                editIndex: -1,
                testNameEditorValue: "",
                addNewTest: false,
            }));
            return;
        }
        if (event.keyCode === 13 || event.keyCode === 9) {
            const { editIndex, testNameEditorValue } = this.state;
            const trimmedName = testNameEditorValue && testNameEditorValue.trim();

            if (!isEmpty(trimmedName)) {
                await this.props.updateTestName(editIndex, trimmedName)
            }

            this.setState(prevState => ({
                ...prevState,
                editIndex: -1,
                addNewTest: false,
                testNameEditorValue: "",
            }));
        }
    }

    handleAddNewTestClick = () => {
        this.setState(prevState => ({
            ...prevState,
            addNewTest: true,
            testNameEditorValue: `Test ${this.props.source.yamlObject.tests.length + 1}`
        }), () => {
            const input: any = document.getElementsByName("testCreateName")[0];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        });
    }

    handleRenameTestClick = (index: number) => {
        const { yamlObject } = this.props?.source || {};
        const testNameEditorValue = yamlObject.tests[index].name;
        this.setState(prevState => ({
            ...prevState,
            editIndex: index,
            testNameEditorValue,
        }), () => {
            const input: any = document.getElementsByName(`testEditName${index}`)[0];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        });
    }

    handleDeleteTestClick = async (index: number) => {
        this.props.deleteTest(index);
        this.props.handleChangeSelectedTestIndex(0);
    }

    handleCloneTestClick = async (index: number) => {
        this.props.cloneTest(index);
    }

    handleTestOnlyFlagClick = async (index: number) => {
        const { yamlObject } = this.props?.source || {};
        const value = !yamlObject.tests[index].only;
        this.props.updateOnlyFlag(index, value);
        if (yamlObject.tests[index].skip) {
            this.props.updateSkipFlag(index, false);
        }
    }

    handleTestSkipFlagClick = async (index: number) => {
        const { yamlObject } = this.props?.source || {};
        const value = !yamlObject.tests[index].skip;
        this.props.updateSkipFlag(index, value);
        if (yamlObject.tests[index].only) {
            this.props.updateOnlyFlag(index, false);
        }
    }

    renderTestItem = (test: any, index: number, hasOnly: boolean) => {
        const testMetadata = (this.props?.source?.meta?.testMetadata || [])[index]
        const { source, selectedTestIndex, testResults } = this.props;
        const { yamlObject } = source;


        const interactionResults = testResults
            ?.find((testResult: any, testResultIndex: number) => testResultIndex === index)
            ?.interactions;

        const isTestRunning = this.props.runningTestIndexes.includes(index);
        const isCurrentlyRunningTest = this.props.runningTestIndexes[0] === index;
        const isAnyTestRunning = this.props.runningTestIndexes.length > 0;

        const testCompleted = interactionResults?.every((interaction: any) => {
            return interaction?.items?.every((item: any) =>
                item.status === TestResultStatus.COMPLETED)
        });

        const testSuccess = interactionResults?.every((interaction: any) => {
            return interaction?.items?.every((item: any) =>
                item.passed)
        })

        const isPending = isTestRunning && !isCurrentlyRunningTest;

        const canSelect = !isAnyTestRunning || (testCompleted || isCurrentlyRunningTest);

        let statusClass = testCompleted ? (testSuccess ? validationStyle.success : validationStyle.error) :
            isCurrentlyRunningTest ? validationStyle.running :
            isPending ? validationStyle.loading :
            false;

        const isSelectedIndex = selectedTestIndex === index;
        const showEditable = this.state.editIndex === index;

        const ignoreSingleTest = isTestRunning || test.skip
        const ignoreTest = (test.skip || hasOnly) && ignoreSingleTest && !test.only
        const skippedStyle = ignoreTest ? validationStyle.skipped : ''

        return showEditable ?
            <div key={`div${index}`}
                className={`${validationStyle.sortable_item} ${isSelectedIndex ? validationStyle.selected : validationStyle.item} ${statusClass}`}>
                <Input
                    maxLength={50}
                    name={`testEditName${index}`}
                    key={`testEdit${index}`}
                    onChange={this.handleNameInputChange}
                    onBlur={this.handleNameInputBlur.bind(this, index)}
                    onKeyDown={this.handleNameInputKeyDown.bind(this, index)}
                    floating={false}
                    className={validationStyle.test_name_input}
                    value={this.state.testNameEditorValue} />
            </div> :
            <div
                key={index}
                className={`${validationStyle.sortable_item_container} ${canSelect ? '' : validationStyle.disabled}`}
                onClick={() => canSelect && this.props.handleChangeSelectedTestIndex(index)}
            >
                <div
                    className={`${validationStyle.sortable_item}
                    ${isSelectedIndex ? validationStyle.selected : validationStyle.item}
                    ${statusClass}
                    ${skippedStyle}`}>
                    <div className={validationStyle.test_name_tooltip}>
                        <span>{test.name}</span>
                    </div>
                    <div className={validationStyle.test_flag}>
                        {test.only && <FontIcon value="flag" className={validationStyle.test_flag_icon} />}
                        {test.skip && <FontIcon value="fast_forward" className={validationStyle.test_flag_icon} />}
                    </div>
                    {(isTestRunning || testResults && testResults.length) && !ignoreTest &&
                        <div className={`${validationStyle.status_icon} ${statusClass}`} />
                    }
                    {!isTestRunning ?
                        (
                            <BespokenMenu
                                className={validationStyle.test_menu}
                                selectable={true}
                                icon={<BespokenMenuDots />}
                                position="topRight"
                                menuRipple={true}>
                                <BespokenMenuItem
                                    data-id="test-rename"
                                    caption={"Rename"}
                                    onClick={this.handleRenameTestClick.bind(this, index)} />
                                <BespokenMenuItem
                                    data-id="test-clone"
                                    caption="Clone"
                                    onClick={this.handleCloneTestClick.bind(this, index)} />
                                <hr className={validationStyle.test_menu_divider} />
                                <BespokenMenuItem
                                    data-id="test-only"
                                    caption={"Only"}
                                    className={test.only ? validationStyle.menu_item_selected : ''}
                                    onClick={this.handleTestOnlyFlagClick.bind(this, index)} />
                                <BespokenMenuItem
                                    data-id="test-skip"
                                    caption={"Skip"}
                                    className={test.skip ? validationStyle.menu_item_selected : ''}
                                    onClick={this.handleTestSkipFlagClick.bind(this, index)} />
                                <hr className={validationStyle.test_menu_divider} />
                                <BespokenMenuItem
                                    disabled={yamlObject && yamlObject.tests && yamlObject.tests.length <= 1}
                                    data-id="test-delete"
                                    caption="Delete"
                                    onClick={this.handleDeleteTestClick.bind(this, index)} />
                            </BespokenMenu>
                        ) : ""
                    }
                </div>
                {isSelectedIndex &&
                    <div
                        key={`other-attributes-${index}`}
                        className={validationStyle.other_fields}
                    >
                        <div>
                            <TextBlueLabelSmall>Test ID</TextBlueLabelSmall>
                            <TooltipInfoIcon
                                message="Use this field if you want to customize your tests for report generation (optional)"
                            />
                            <InplaceEditor
                                lines={1}
                                saveOnBlur={true}
                                maxLength={50}
                                truncateLength={25}
                                placeHolder={"Enter a Test ID (optional)"}
                                value={testMetadata?.testId}
                                onChanged={testId => this.props?.updateTestId(index, testId)}
                            />
                        </div>
                        <div>
                            <TextBlueLabelSmall>Description</TextBlueLabelSmall>
                            <TooltipInfoIcon
                                message="Use this field to provide details about what this test does or should do (optional)"
                            />
                            <InplaceEditor
                                saveOnBlur={true}
                                maxLength={200}
                                truncateLength={50}
                                placeHolder={"Enter a description (optional)"}
                                value={testMetadata?.testDescription}
                                onChanged={testDescription => this.props?.updateTestDescription(index, testDescription)}
                            />
                        </div>
                    </div>
                }
            </div>
    }

    render() {
        const {
            yamlObject
        } = this.props?.source || {};
        const {
            currentAudioResult,
            showRecordedCall,
            loadingAudioFile,
            loadingVideoFile,

            currentVideoResult,
            showRecordedVideo,
        } = this.state

        const { hasAudioAvailable, audioUrl } = currentAudioResult || {}
        const { hasVideoAvailable, videoUrl } = currentVideoResult || {}

        const hasOnly = false;
        const isAnyTestRunning = this.props.runningTestIndexes.length > 0;

        return (
            <div className={validationStyle.tests_container}>
                <div className={`${validationStyle.title_container}`}>
                    <div className={validationStyle.title}>
                        <h4>Tests</h4>
                    </div>
                    <IconLabelButton
                        icon={<BespokenRunAllIcon />}
                        label={"Run all"}
                        disable={isAnyTestRunning}
                        onClick={this.props.handleRunAll}
                        size="small"
                    />
                </div>
                <div className={validationStyle.tests_list} data-intercom-target="TestList">
                    <SortableParentContainer
                        onSortStart={this.onSortStart}
                        onSortEnd={this.onSortEnd}
                        useDragHandle={true}
                        disabled={isAnyTestRunning}>
                        {
                            chain(yamlObject?.tests)
                                .map((test: any, index: number) => (
                                    <SortableItem
                                        key={`item-${test.name}${index}`}
                                        loadingResults={this.props.runningTestIndexes.includes(index)}
                                        index={index}
                                        itemToRender={this.renderTestItem(test, index, hasOnly)}
                                        grabbing={this.state.grabbing}
                                        value={test.name} />
                                ))
                                .value()
                        }
                    </SortableParentContainer>
                    {
                        this.state.addNewTest ?
                            (
                                <div className={validationStyle.edit_test_text}>
                                    <Input
                                        maxLength={50}
                                        name={"testCreateName"}
                                        onKeyDown={this.handleTestCreateNameKeyDown}
                                        onBlur={this.handleTestCreateNameBlur}
                                        floating={false}
                                        className={validationStyle.test_name_input}
                                        label={"Enter a name for your test..."}
                                        onChange={this.handleNameInputChange}
                                        value={this.state.testNameEditorValue} />
                                </div>
                            ) :
                            (
                                <div className={validationStyle.add_test_button}
                                    onClick={isAnyTestRunning ? undefined : this.handleAddNewTestClick.bind(this)}>
                                    <IconButton icon={<BespokenAddIcon />} />
                                    Add new test
                                </div>
                            )
                    }

                    {showRecordedCall &&
                        <AudioPlayer
                            loading={loadingAudioFile}
                            disable={!hasAudioAvailable}
                            url={audioUrl}
                        />
                    }

                    {showRecordedVideo && videoUrl && !loadingVideoFile &&
                        <div className={validationStyle.video_container} style={{ width: '100%' }}>
                            <VideoPlayerComponent autoPlay={true} url={videoUrl} />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state: State.All, ownProps: any) {

    const defaultPlatformId = chain(state?.organization?.selectedOrganization?.subscription?.platforms)
        .uniq()
        .sort()
        .first()
        .value()

    return {
        currentConfig: state.sourceGit?.currentSource?.config || {},
        currentTestResults: state.sourceGit.testResults,
        defaultPlatformId
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        updateTestId: (index: number, testId: string) => { dispatch(updateTestId(index, testId)) },
        updateTestDescription: (index: number, testDescription: string) => { dispatch(updateTestDescription(index, testDescription)) }
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TestList);


type TooltipInfoIconProps = { message: string }
const TooltipInfoIcon = (props: TooltipInfoIconProps) => {
    return <TooltipDiv
        tooltipPosition={"right"}
        theme={require("../../../themes/tooltip.scss")}
        tooltip={props?.message}>
        <IconButton
            className={validationStyle.info_icon}
            disabled={true}
            icon={"info"}
        />
    </TooltipDiv>
}