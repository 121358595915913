import { chain, isEmpty, isEqual, isEqualWith, isUndefined, merge, negate, omit, pick, set, split, startsWith, toNumber } from "lodash";
import {
    SetCurrentSource,
    SetSources,
    SetSourceSearchText,
    SourceDeleteSource,
    SourceInterimResultsUpdate,
    SourceResultsInit,
    SourceResultsPending,
    SourceResultsUpdate,
    SourceResultsUpdateAllPendingStatus,
    SourceResultsUpdateInteractionAllStatus,
    SourceSetIsYamlSyntax,
    SourceSetLoading,
    SourceUpdateMeta,
    SourceUpdateName,
    SourceUpdateYaml,
    SourceUpdateYamlObject,
    SourceYamlObjectAddAssertion,
    SourceYamlObjectAddInteraction,
    SourceYamlObjectAddTest,
    SourceYamlObjectCloneTest,
    SourceYamlObjectDeleteAssertion,
    SourceYamlObjectDeleteTest,
    SourceYamlObjectRemoveInteraction,
    SourceYamlObjectResetConfig,
    SourceYamlObjectUpdateAssertionAction,
    SourceYamlObjectUpdateAssertionOperator,
    SourceYamlObjectUpdateAssertionValue,
    SourceYamlObjectUpdateConfig,
    SourceYamlObjectUpdateConfigNested,
    SourceYamlObjectUpdateInteractionInput,
    SourceYamlObjectUpdateOnlyFlag,
    SourceYamlObjectUpdateSkipFlag,
    SourceYamlObjectUpdateTestName,
    SourceYamlUpdateMonitoringSchedule,
    SourceYamlUpdateTestDescription,
    SourceYamlUpdateTestId,
    SourceYamlUpdateTestSuiteDescription,
} from "../actions/source";
import {
    SET_SOURCE_SEARCH_TEXT,
    SET_SOURCES,
    SOURCE_DELETE_SOURCE,
    SOURCE_INTERIM_RESULTS_UPDATE,
    SOURCE_RESULTS_INIT,
    SOURCE_RESULTS_PENDING,
    SOURCE_RESULTS_UPDATE,
    SOURCE_RESULTS_UPDATE_ALL_PENDING_STATUS,
    SOURCE_RESULTS_UPDATE_INTERACTION_ALL_STATUS,
    SOURCE_SET_CURRENT,
    SOURCE_SET_IS_VALID_SYNTAX,
    SOURCE_SET_LOADING,
    SOURCE_UPDATE_META,
    SOURCE_UPDATE_MONITORING_SCHEDULE_TYPE,
    SOURCE_UPDATE_NAME,
    SOURCE_UPDATE_TEST_DESCRIPTION,
    SOURCE_UPDATE_TEST_ID,
    SOURCE_UPDATE_TEST_SUITE_DESCRIPTION,
    SOURCE_UPDATE_YAML,
    SOURCE_UPDATE_YAML_OBJECT,
    SOURCE_YAMLOBJECT_ADD_ASSERTION,
    SOURCE_YAMLOBJECT_ADD_INTERACTION,
    SOURCE_YAMLOBJECT_ADD_TEST,
    SOURCE_YAMLOBJECT_CLONE_TEST,
    SOURCE_YAMLOBJECT_DELETE_ASSERTION,
    SOURCE_YAMLOBJECT_DELETE_TEST,
    SOURCE_YAMLOBJECT_REMOVE_INTERACTION,
    SOURCE_YAMLOBJECT_RESET_CONFIG,
    SOURCE_YAMLOBJECT_UPDATE_ASSERTION_ACTION,
    SOURCE_YAMLOBJECT_UPDATE_ASSERTION_OPERATOR,
    SOURCE_YAMLOBJECT_UPDATE_ASSERTION_VALUE,
    SOURCE_YAMLOBJECT_UPDATE_CONFIG,
    SOURCE_YAMLOBJECT_UPDATE_CONFIG_NESTED,
    SOURCE_YAMLOBJECT_UPDATE_INTERACTION_INPUT,
    SOURCE_YAMLOBJECT_UPDATE_ONLY_FLAG,
    SOURCE_YAMLOBJECT_UPDATE_SKIP_FLAG,
    SOURCE_YAMLOBJECT_UPDATE_TEST_NAME,
    TestResultStatus,
} from "../constants";
import Source from "../models/source";

export type SourceState = {
    readonly currentSource?: Source;
    readonly sources: Source[];
    readonly error?: Error;
    readonly finishLoading?: boolean;
    readonly interimResults?: string[];
    readonly searchText?: string;
    readonly selectedTestIndex?: number
    readonly testResults?: any;
    readonly hasUnsavedChanges?: any;
    readonly syntaxError?: string;

};

const INITIAL_STATE: SourceState = {
    sources: [],
    hasUnsavedChanges: false
};

type SourceAction = SourceSetLoading | SetSources | SetSourceSearchText | SetCurrentSource | SourceYamlObjectUpdateTestName | SourceYamlObjectUpdateInteractionInput |
    SourceYamlObjectAddAssertion | SourceYamlObjectUpdateAssertionAction | SourceYamlObjectUpdateAssertionOperator | SourceYamlObjectUpdateAssertionValue |
    SourceYamlObjectDeleteAssertion | SourceYamlObjectAddInteraction | SourceResultsInit | SourceResultsUpdateInteractionAllStatus |
    SourceResultsUpdate | SourceResultsUpdateAllPendingStatus | SourceYamlObjectAddTest | SourceYamlObjectUpdateConfig | SourceYamlObjectUpdateConfigNested |
    SourceYamlObjectDeleteTest | SourceResultsPending | SourceDeleteSource | SourceUpdateMeta | SourceInterimResultsUpdate |
    SourceUpdateYaml | SourceUpdateYamlObject | SourceYamlObjectRemoveInteraction | SourceSetIsYamlSyntax |
    SourceYamlObjectUpdateSkipFlag | SourceYamlObjectUpdateOnlyFlag | SourceYamlObjectCloneTest | SourceYamlUpdateMonitoringSchedule |
    SourceYamlObjectResetConfig | SourceYamlUpdateTestId |
    SourceYamlUpdateTestDescription | SourceYamlUpdateTestSuiteDescription | SourceUpdateName | { type: "" };

export function source(state: SourceState = INITIAL_STATE, action: SourceAction): SourceState {
    // console.log("source reducer", action);
    switch (action.type) {

        case SOURCE_SET_LOADING: {
            return {
                ...state,
                finishLoading: action.finishLoading,
            };
        }

        case SET_SOURCES: {
            return { ...state, ...{ sources: action.sources, finishLoading: action.finishLoading } };
        }

        case SET_SOURCE_SEARCH_TEXT: {
            return { ...state, ...{ searchText: action.searchText } };
        }

        case SOURCE_DELETE_SOURCE: {
            const index = state.sources.findIndex(source => source.name === action.id);
            return {
                ...state,
                sources: [
                    // @ts-ignore
                    ...state.sources.slice(0, index),
                    ...state.sources.slice(index + 1),
                ]
            };
        }

        case SOURCE_SET_IS_VALID_SYNTAX: {
            return {
                ...state,
                syntaxError: action.value,
            };
        }

        case SOURCE_SET_CURRENT: {
            return {
                ...state,
                currentSource: action.currentSource,
                hasUnsavedChanges: false,
            };
        }

        case SOURCE_YAMLOBJECT_UPDATE_CONFIG: {
            const { config } = state?.currentSource;

            // skip if no changes
            if (isEqual(pick(config, action.property), { [action.property]: action.value })) {
                return state;
            }

            const newValue = set({}, action.property, action.value);
            const newConfig = chain(config).merge(newValue).omit(isUndefined(action.value) ? action.property : undefined).value();

            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    config: newConfig
                }
            };
        }

        case SOURCE_YAMLOBJECT_RESET_CONFIG: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: undefined
            };
        }

        case SOURCE_YAMLOBJECT_UPDATE_CONFIG_NESTED: {
            if (state?.currentSource?.config
                && state?.currentSource?.config?.virtualDeviceConfig
                && state?.currentSource?.config?.virtualDeviceConfig[action.property] === action.value) {
                return state;
            }

            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    // @ts-ignore
                    config: {
                        ...state.currentSource.config,
                        virtualDeviceConfig: {
                            ...state.currentSource.config.virtualDeviceConfig,
                            [action.property]: action.value,
                        }
                    }
                }
            };
        }

        case SOURCE_UPDATE_META: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    // @ts-ignore
                    meta: {
                        ...state.currentSource.meta,
                        [action.property]: action.value,
                    }
                }
            };
        }

        case SOURCE_UPDATE_YAML: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    // @ts-ignore
                    yaml: action.value,
                }
            };
        }

        case SOURCE_UPDATE_NAME: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    name: action.value,
                }
            };
        }


        case SOURCE_INTERIM_RESULTS_UPDATE: {
            return {
                ...state,
                interimResults: action.interimResults
            };
        }

        case SOURCE_UPDATE_YAML_OBJECT: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    // @ts-ignore
                    yamlObject: action.value,
                }
            };
        }

        case SOURCE_YAMLOBJECT_UPDATE_TEST_NAME: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    yamlObject: {
                        ...state.currentSource.yamlObject,
                        // @ts-ignore
                        tests: state.currentSource.yamlObject.tests.map((test, testIndex) => {
                            if (testIndex !== action.testIndex) return test;
                            return {
                                ...test,
                                name: action.name,
                            };
                        }),
                    }
                }
            };
        }

        case SOURCE_YAMLOBJECT_ADD_TEST: {
            const platform = state.currentSource?.config?.platform || "alexa";
            const name = state.currentSource?.meta?.name || "";

            let input = "hello";
            if (["twilio", "phone"].indexOf(platform) > -1) {
                input = "$DIAL";
            } else if (platform === "google") {
                input = "talk to " + name;
            } else if (platform === "alexa") {
                input = "open " + name;
            }

            let items = [
                { action: "prompt", value: "hi! how can I help?", operator: ":" }
            ];
            if (["twilio", "phone"].indexOf(platform) > -1) {
                items = [
                    { action: "prompt", value: "", operator: ":" }
                ];
            } else if (["alexa", "google"].indexOf(platform) > -1) {
                items = [
                    { action: "prompt", value: "welcome to " + name, operator: ":" }
                ];
            }

            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    yamlObject: {
                        ...state.currentSource.yamlObject,
                        tests: [
                            // @ts-ignore
                            ...state.currentSource.yamlObject.tests,
                            { name: action.name, interactions: [{ input, items }] },
                        ],
                    }
                }
            };
        }

        case SOURCE_YAMLOBJECT_CLONE_TEST: {
            const toClone = {
                ...state.currentSource.yamlObject.tests[action.index]
            };
            toClone.name += " copy";
            toClone.skip = false;
            toClone.only = false;

            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    yamlObject: {
                        ...state.currentSource.yamlObject,
                        tests: [
                            // @ts-ignore
                            ...state.currentSource.yamlObject.tests,
                            toClone,
                        ],
                    }
                }
            };
        }

        case SOURCE_YAMLOBJECT_DELETE_TEST: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    yamlObject: {
                        ...state.currentSource.yamlObject,
                        tests: [
                            // @ts-ignore
                            ...state.currentSource.yamlObject.tests.slice(0, action.index),
                            ...state.currentSource.yamlObject.tests.slice(action.index + 1),
                        ],
                    }
                }
            };
        }

        case SOURCE_YAMLOBJECT_UPDATE_SKIP_FLAG: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    yamlObject: {
                        ...state.currentSource.yamlObject,
                        // @ts-ignore
                        tests: state.currentSource.yamlObject.tests.map((test, testIndex) => {
                            if (testIndex !== action.testIndex) return test;
                            return {
                                ...test,
                                skip: action.value,
                            };
                        }),
                    }
                }
            };
        }

        case SOURCE_YAMLOBJECT_UPDATE_ONLY_FLAG: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    yamlObject: {
                        ...state.currentSource.yamlObject,
                        // @ts-ignore
                        tests: state.currentSource.yamlObject.tests.map((test, testIndex) => {
                            if (testIndex !== action.testIndex) return test;
                            return {
                                ...test,
                                only: action.value,
                            };
                        }),
                    }
                }
            };
        }

        case SOURCE_YAMLOBJECT_ADD_INTERACTION: {
            const items = [
                { action: "prompt", value: "", operator: ":" }
            ];
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    yamlObject: {
                        ...state.currentSource.yamlObject,
                        // @ts-ignore
                        tests: state.currentSource.yamlObject.tests.map((test, testIndex) => {
                            if (testIndex !== action.testIndex) return test;
                            return {
                                ...test,
                                // @ts-ignore
                                interactions:
                                    action.interactionIndex !== undefined
                                        ? [
                                            ...test.interactions.slice(0, action.interactionIndex + 1),
                                            {
                                                input: "",
                                                items,
                                            },
                                            ...test.interactions.slice(action.interactionIndex + 1),
                                        ]
                                        : [
                                            ...test.interactions,
                                            {
                                                input: "",
                                                items,
                                            }
                                        ]

                            };
                        }),
                    }
                }
            };
        }

        case SOURCE_YAMLOBJECT_REMOVE_INTERACTION: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    yamlObject: {
                        ...state.currentSource.yamlObject,
                        // @ts-ignore
                        tests: state.currentSource.yamlObject.tests.map((test, testIndex) => {
                            if (testIndex !== action.testIndex) return test;
                            return {
                                ...test,
                                // @ts-ignore
                                interactions: [
                                    ...test.interactions.slice(0, action.interactionIndex),
                                    ...test.interactions.slice(action.interactionIndex + 1),
                                ]
                            };
                        }),
                    }
                }
            };
        }

        case SOURCE_YAMLOBJECT_UPDATE_INTERACTION_INPUT: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    yamlObject: {
                        ...state.currentSource.yamlObject,
                        // @ts-ignore
                        tests: state.currentSource.yamlObject.tests.map((test, testIndex) => {
                            if (testIndex !== action.testIndex) return test;
                            return {
                                ...test,
                                // @ts-ignore
                                interactions: test.interactions.map((interaction, index) => {
                                    if (index !== action.interactionIndex) return interaction;
                                    return {
                                        ...interaction,
                                        input: action.input,
                                    };
                                })
                            };
                        }),
                    }
                }
            };
        }

        case SOURCE_YAMLOBJECT_ADD_ASSERTION: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    yamlObject: {
                        ...state.currentSource.yamlObject,
                        // @ts-ignore
                        tests: state.currentSource.yamlObject.tests.map((test, testIndex) => {
                            if (testIndex !== action.testIndex) return test;
                            return {
                                ...test,
                                // @ts-ignore
                                interactions: test.interactions.map((interaction, index) => {
                                    if (index !== action.interactionIndex) return interaction;
                                    return {
                                        ...interaction, items: [
                                            ...interaction.items,
                                            { action: "prompt", value: "", operator: ":" }
                                        ]
                                    };

                                })
                            };
                        }),
                    }
                }
            };
        }

        case SOURCE_YAMLOBJECT_DELETE_ASSERTION: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    yamlObject: {
                        ...state.currentSource.yamlObject,
                        // @ts-ignore
                        tests: state.currentSource.yamlObject.tests.map((test, testIndex) => {
                            if (testIndex !== action.testIndex) return test;
                            return {
                                ...test,
                                // @ts-ignore
                                interactions: test.interactions.map((interaction, index) => {
                                    if (index !== action.interactionIndex) return interaction;
                                    return {
                                        ...interaction,
                                        items: [
                                            ...interaction.items.slice(0, action.itemIndex),
                                            ...interaction.items.slice(action.itemIndex + 1)
                                        ]
                                    };
                                })
                            };
                        }),
                    }
                }
            };
        }

        case SOURCE_YAMLOBJECT_UPDATE_ASSERTION_ACTION: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    yamlObject: {
                        ...state.currentSource.yamlObject,
                        // @ts-ignore
                        tests: state.currentSource.yamlObject.tests.map((test, testIndex) => {
                            if (testIndex !== action.testIndex) return test;
                            return {
                                ...test,
                                // @ts-ignore
                                interactions: test.interactions.map((interaction, index) => {
                                    if (index !== action.interactionIndex) return interaction;
                                    return {
                                        ...interaction,
                                        // @ts-ignore
                                        items: interaction.items.map((item, itemIndex) => {
                                            if (itemIndex !== action.itemIndex) return item;
                                            return { ...item, action: action.value };
                                        }),
                                    };
                                }),
                            };
                        }),
                    }
                }
            };
        }

        case SOURCE_YAMLOBJECT_UPDATE_ASSERTION_OPERATOR: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    yamlObject: {
                        ...state.currentSource.yamlObject,
                        // @ts-ignore
                        tests: state.currentSource.yamlObject.tests.map((test, testIndex) => {
                            if (testIndex !== action.testIndex) return test;
                            return {
                                ...test,
                                // @ts-ignore
                                interactions: test.interactions.map((interaction, index) => {
                                    if (index !== action.interactionIndex) return interaction;
                                    return {
                                        ...interaction,
                                        // @ts-ignore
                                        items: interaction.items.map((item, itemIndex) => {
                                            if (itemIndex !== action.itemIndex) return item;
                                            return { ...item, operator: action.value };
                                        }),
                                    };
                                }),
                            };
                        }),
                    }
                }
            };
        }

        case SOURCE_YAMLOBJECT_UPDATE_ASSERTION_VALUE: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    yamlObject: {
                        ...state.currentSource.yamlObject,
                        // @ts-ignore
                        tests: state.currentSource.yamlObject.tests.map((test, testIndex) => {
                            if (testIndex !== action.testIndex) return test;
                            return {
                                ...test,
                                // @ts-ignore
                                interactions: test.interactions.map((interaction, index) => {
                                    if (index !== action.interactionIndex) return interaction;
                                    return {
                                        ...interaction,
                                        // @ts-ignore
                                        items: interaction.items.map((item, itemIndex) => {
                                            if (itemIndex !== action.itemIndex) return item;
                                            return { ...item, value: action.value };
                                        }),
                                    };
                                }),
                            };
                        }),
                    }
                }
            };
        }

        case SOURCE_RESULTS_INIT:
            return {
                ...state,
                testResults: action.yamlObject.tests.map((test: any) => ({
                    name: test.name,
                    interactions: test.interactions.map((interaction: any) => ({
                        input: interaction.input,
                        items: interaction.items
                            .filter((item: any) => !item?.action?.startsWith("set "))
                            .map((item: any) => ({
                                path: item.action,
                                status: TestResultStatus.INITIAL,
                            }))
                    })),
                })),
            };
        case SOURCE_RESULTS_PENDING:
            return {
                ...state,
                testResults: action.yamlObject.tests.map((test: any) => ({
                    name: test.name,
                    interactions: test.interactions.map((interaction: any) => ({
                        input: interaction.input,
                        items: interaction.items
                            .filter((item: any) => !item?.action?.startsWith("set "))
                            .map((item: any) => ({
                                path: item.action,
                                status: TestResultStatus.PENDING,
                            }))
                    })),
                })),
            };


        case SOURCE_RESULTS_UPDATE_INTERACTION_ALL_STATUS:
            return {
                ...state,
                testResults: state.testResults.map((result: any, index: number) => {
                    if (index !== action.testIndex) return result;
                    return {
                        ...result,
                        interactions: result.interactions.map((interaction: any, interactionIndex: number) => {
                            if (interactionIndex !== action.interactionIndex) return interaction;
                            return {
                                ...interaction,
                                items: interaction?.items.map((item: any) => ({
                                    ...item,
                                    status: action.status,
                                }))
                            };
                        })
                    };
                })
            };
        case SOURCE_RESULTS_UPDATE:
            return {
                ...state,
                testResults: state.testResults.map((result: any, index: number) => {
                    if (index !== action.testIndex) return result;
                    return {
                        ...result,
                        conversationId: action.conversationId,
                        interactions: result.interactions.map((interaction: any, interactionIndex: number) => {
                            if (interactionIndex !== action.interactionIndex) return interaction;
                            return {
                                ...interaction,
                                items: interaction?.items.map((item: any, itemIndex: number) => {
                                    // if (itemIndex !== action.itemIndex) return item;
                                    if (item.path !== action.path) return item;
                                    return action.value;
                                })
                            };
                        })
                    };
                })
            };
        case SOURCE_RESULTS_UPDATE_ALL_PENDING_STATUS:
            return {
                ...state,
                testResults: state.testResults.map((result: any, index: number) => {
                    if (index !== action.testIndex) return result;
                    return {
                        ...result,
                        interactions: result.interactions.map((interaction: any) => {
                            return {
                                ...interaction,
                                items: interaction?.items.map((item: any) => {
                                    if (item.status !== "PENDING") return item;
                                    return ({
                                        ...item,
                                        status: action.status,
                                    });
                                }),
                            };
                        })
                    };
                })
            };

        case SOURCE_UPDATE_MONITORING_SCHEDULE_TYPE: {
            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    monitoringConfig: {
                        cron: action.selectedScheduleType,
                        emailsToNotify: action.emailsToNotify,
                        numbersToNotify: action.numbersToNotify
                    }
                }
            };
        }

        case SOURCE_UPDATE_TEST_ID: {
            const testMetadata = chain(state?.currentSource?.meta?.testMetadata)
                .defaultTo({})
                .mapKeys((v, k) => toNumber(k))
                .thru(all => {
                    set(all, `${toNumber(action.testIndex)}.testId`, action.testId);
                    return all;
                })
                .value();

            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    meta: { ...state.currentSource.meta, testMetadata }
                }
            };
        }

        case SOURCE_UPDATE_TEST_DESCRIPTION: {
            const testMetadata = chain(state?.currentSource?.meta?.testMetadata)
                .defaultTo({})
                .mapKeys((v, k) => toNumber(k))
                .thru(all => {
                    set(all, `${toNumber(action.testIndex)}.testDescription`, action.testDescription);
                    return all;
                })
                .value();

            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    meta: { ...state.currentSource.meta, testMetadata }
                }
            };
        }

        case SOURCE_UPDATE_TEST_SUITE_DESCRIPTION: {
            const { testSuiteDescription } = action;

            return {
                ...state,
                hasUnsavedChanges: true,
                currentSource: {
                    ...state.currentSource,
                    meta: { ...state.currentSource.meta, testSuiteDescription }
                }
            };
        }

        default:
            return state;
    }
}

