export default class WebsocketClient {
  init(url: string, handlers: any) {
    let client = new WebSocket(url);
    let heartbeatInterval: ReturnType<typeof setInterval> | undefined;

    const startHeartbeat = () => {
      if (heartbeatInterval) {
        clearInterval(heartbeatInterval);
      }

      heartbeatInterval = setInterval(() => {
        if (client.readyState === WebSocket.OPEN) {
          client.send(JSON.stringify({ type: 'ping' }));

        }
      }, 30000); // Ping every 30 seconds
    };

    client.addEventListener("open", () => {

      handlers?.onOpen();
      startHeartbeat();
    });

    client.addEventListener("close", () => {

      clearInterval(heartbeatInterval);
      client = undefined;
    });

    client.addEventListener("message", (event) => {
      if (event?.data && handlers.onMessage) {
        handlers.onMessage(event.data);
      }
    });

    client.addEventListener("error", (error) => {
      console.error('[websockets] Error:', error);
    });

    const connection = {
      client,
      send: (message = {}) => {
        if (!client) {
          throw new Error("WS connection is closed");
        }
        return client.send(JSON.stringify(message));
      },
      close: () => {

        client.close();
      }
    };

    return connection;
  }
}