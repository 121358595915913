import * as cn from "classnames";
import { capitalize, chain, includes, isEmpty, isFunction, lowerCase, snakeCase, upperCase } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { Button, IconButton } from "react-toolbox/lib/button";
import Snackbar from "react-toolbox/lib/snackbar";
import { Table, TableCell, TableHead, TableRow } from "react-toolbox/lib/table";
import Tooltip from "react-toolbox/lib/tooltip";
import BespokenCopyIcon from "../../../assets/bespoken_copy_icon.svg";
import BespokenDeleteIcon from "../../../assets/bespoken_delete_icon.svg";
import BespokenRefreshIcon from "../../../assets/bespoken_refresh_icon.svg";
import { setLoading } from "../../actions/loading";
import { setCurrentPageProps } from "../../actions/context";
import GenericInlineEdit from "../../components/GenericInlineEdit";
import { Menu, MenuItem } from "../../components/Menu";
import { BespokenModal } from "../../components/Modal/BespokenModal";
import { ReactSpan } from "../../components/ReactDiv";
import { sourceType } from "../../constants";
import { State } from "../../reducers";

import { format } from "date-fns";
import { createVirtualDevice, createVirtualDeviceWithOAuth, deleteVirtualDevice, fetchOrganization, fetchVirtualDevices, refreshVirtualDeviceWithOAuthLink, updateVirtualDevice } from "../../actions/organization";
import { WarningModal } from "../../components/Modal/WarningModal";
import { PLATFORM_ICONS, PlatformIcon } from "../../components/PlatformIcons/PlatformIcons";
import { BespokenPlatformsSelector } from "../../components/bespoken-linked-components";
import { ERROR } from "../../components/lunacy/debug-panel/DebugPanel";
import { SelectedOrganization, VirtualDeviceRequest, VirtualDevices } from "../../reducers/organization";
import { CurrentPageProps } from "../../reducers/session";
import { attemptInvoke, wrapCallbackAsAsync } from "../../utils/ReactHelpers";
import sleep from "../../utils/sleep";

const Styles = require("./styles.scss");
const bespokenButton = require("../../themes/bespoken_button.scss");
const bespokenTooltip = require("../../themes/bespoken_tooltip.scss");
const tableTheme = require("../../themes/table_theme.scss");

const TooltipSpan = Tooltip(ReactSpan);

const CREATE_VIRTUAL_DEVICE_TEXTS = {
    "alexa": { platform: 'alexa', description: "In order to create a new <strong>Alexa Virtual Device</strong>, you'll be asked to provide your Amazon account credentials." },
    "email": { platform: 'email', description: "This will create a new <strong>EMAIL Virtual Device</strong>." },
    "google": { platform: 'google', description: "In order to create a new <strong>Google Virtual Device</strong>, you'll be asked to provide your Google account credentials." },
    "phone": { platform: 'phone', description: "This will create a new <strong>Phone Virtual Device</strong>." },
    "sms": { platform: 'sms', description: "This will create a new <strong>SMS Virtual Device</strong>." },
    "whatsapp": { platform: 'whatsapp', description: "This will create a new <strong>Whatsapp Virtual Device</strong>." },
    "webchat": { platform: 'webchat', description: "This will create a new <strong>Webchat Virtual Device</strong>." },
    "watson": { platform: 'watson', description: "This will create a new <strong>Watson Virtual Device</strong>." },
    "voiceflow":  { platform: 'watson', description: "This will create a new <strong>Voiceflow Virtual Device</strong>." },
    "lex":  { platform: 'watson', description: "This will create a new <strong>AWS Lex Virtual Device</strong>." },
}

interface DispatchToProps {
    setLoading: (value: boolean) => any;
    fetchOrganization: () => Promise<any>;
    setCurrentPageProps?: (value: CurrentPageProps) => any;

    createVirtualDevice: (request: VirtualDeviceRequest) => Promise<any>;
    createVirtualDeviceOAuth: (request: VirtualDeviceRequest) => Promise<{ url: string }>;
    refreshVirtualDevice: (virtualDeviceRequest: VirtualDeviceRequest) => Promise<any>;
    deleteVirtualDevice: (token: string) => Promise<any>;
    udpateVirtualDevice: (virtualDevice: VirtualDevices) => Promise<any>;
    fetchVirtualDevices: () => Promise<any>;
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        setLoading: (value: boolean) => dispatch(setLoading(value)),
        fetchOrganization: async () => wrapCallbackAsAsync(handle => dispatch(fetchOrganization('current', handle))),
        setCurrentPageProps: (value: CurrentPageProps) => dispatch(setCurrentPageProps(value)),

        createVirtualDevice: async (request: VirtualDeviceRequest) => wrapCallbackAsAsync(handle => dispatch(createVirtualDevice(request, handle))),
        createVirtualDeviceOAuth: async (request) => wrapCallbackAsAsync(handle => dispatch(createVirtualDeviceWithOAuth(request, handle))) as Promise<{ url: string }>,
        refreshVirtualDevice: async (virtualDeviceRequest) => wrapCallbackAsAsync(handle => dispatch(refreshVirtualDeviceWithOAuthLink(virtualDeviceRequest, handle))),
        deleteVirtualDevice: async (token) => wrapCallbackAsAsync(handle => dispatch(deleteVirtualDevice(token, handle))),
        udpateVirtualDevice: async (virtualDevice) => wrapCallbackAsAsync(handle => dispatch(updateVirtualDevice(virtualDevice, handle))),
        fetchVirtualDevices: async () => wrapCallbackAsAsync(handle => dispatch(fetchVirtualDevices(handle))),
    };
}

interface StateToProps {
    selectedOrganization: SelectedOrganization

}

function mapStateToProps(state: State.All): StateToProps {
    return {
        selectedOrganization: state?.organization?.selectedOrganization
    };
}

interface VirtualDeviceManagerState {
    filter: string;

    displaySnackbar: boolean;
    snackbarLabel: string;

    createVirtualDeviceTexts: { [platform: string]: { platform: string, description: string } };
    createVirtualDeviceArgs: VirtualDeviceRequest;
    showCreateVirtualDeviceModal: boolean;

    virtualDeviceToDelete: { token: string, name: string };
    showVirtualDeviceToDeleteModal: boolean;
}

interface VirtualDeviceManagerProps extends DispatchToProps, StateToProps { }

export class VirtualDeviceManager extends React.Component<VirtualDeviceManagerProps, VirtualDeviceManagerState> {
    constructor(props: VirtualDeviceManagerProps) {
        super(props);

        this.state = {
            filter: '',

            displaySnackbar: false,
            snackbarLabel: '',

            createVirtualDeviceTexts: CREATE_VIRTUAL_DEVICE_TEXTS,
            createVirtualDeviceArgs: undefined,
            showCreateVirtualDeviceModal: false,

            virtualDeviceToDelete: undefined,
            showVirtualDeviceToDeleteModal: false,
        };
    }

    async componentDidMount(): Promise<void> {
        this.props.setCurrentPageProps({ title: "Virtual Device", subTitle: "Manager" });
        try {
            this.props.setLoading(true)
            await this.props?.fetchVirtualDevices()
        } catch (err) {
            ERROR("Error fetching sources", err)
        }

        this.props.setLoading(false)
    }

    copyTokenToClipboard = (token: string, name: string) => {
        const onSuccess = (name: string) => this.setState({
            displaySnackbar: true,
            snackbarLabel: `The token for ${name} has been copied to the clipboard`,
        });
        const onError = (err: any) => console.error("Oops, unable to copy", err);

        if (isFunction(navigator.clipboard.writeText)) {
            navigator.clipboard.writeText(token)
                .then(() => onSuccess(name), err => onError(err))

            return
        }

        try {
            const textArea = document.createElement("textarea");
            textArea.style.display = 'none'
            textArea.value = token;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            document.execCommand("copy");
            onSuccess(name)
        } catch (err) { onError(err) }
    }

    render() {
        return (
            <div className={cn(Styles.container)}>
                <BespokenPlatformsSelector onChangeCategory={({ value: filter }) => this.setState({ filter })} />

                <VirtualDeviceTable
                    filter={this.state.filter}
                    onNameChanged={async (token, name) => {
                        try {
                            this.props.setLoading(true)

                            await this.props?.udpateVirtualDevice({ token, name } as unknown as VirtualDevices)
                            await this.props?.fetchVirtualDevices()

                        } catch (err) {
                            console.error(err);
                        } finally {
                            this.props.setLoading(false)
                        }
                    }}
                    onActionClicked={async (action, virtualDevice) => {
                        try {
                            this.props.setLoading(true)

                            const { token, name, platform } = virtualDevice

                            if (action === 'copy-token') {
                                return this.copyTokenToClipboard(token, name)
                            }

                            if (action === 'delete-virtual-device') {
                                return this.setState({ showVirtualDeviceToDeleteModal: true, virtualDeviceToDelete: virtualDevice })
                            }

                            if (action === 'refresh-token') {
                                if (!includes(["google", "alexa"], platform)) { return }

                                const returnUrl = `${window.location.origin}${window.location.pathname}`
                                const consentScreenLink = await this.props.refreshVirtualDevice({ token, returnUrl })
                                location.href = consentScreenLink?.url

                                return
                            }
                        } catch (err) {
                            console.error(err)
                        } finally {
                            this.props.setLoading(false)
                        }
                    }}
                />

                <CreateVirtualDevice
                    onCreateAction={createVirtualDeviceArgs => this.setState({ showCreateVirtualDeviceModal: true, createVirtualDeviceArgs })}
                />

                <CreateVirtualDeviceModal
                    texts={this.state?.createVirtualDeviceTexts[this.state?.createVirtualDeviceArgs?.platform]}
                    onCreateClick={async (request: VirtualDeviceRequest) => {
                        try {
                            this.setState({ showCreateVirtualDeviceModal: false })

                            this.props.setLoading(true)

                            if (includes(["google", "alexa"], request?.platform)) {
                                const returnUrl = `${window.location.origin}${window.location.pathname}`
                                const consentScreenLink = await this.props.createVirtualDeviceOAuth({ ...request, returnUrl })
                                location.href = consentScreenLink?.url
                                return
                            }

                            await this.props.createVirtualDevice(request)
                            await this.props.fetchVirtualDevices()
                        } catch (err) {
                            console.error(err)
                        } finally {
                            this.props.setLoading(false)
                        }
                    }}
                    onDismissDialog={() => this.setState({ showCreateVirtualDeviceModal: false })}
                    request={this.state?.createVirtualDeviceArgs}
                    showModal={this.state?.showCreateVirtualDeviceModal} />

                <ComfirmDeleteModal
                    showModal={this.state.showVirtualDeviceToDeleteModal}
                    onOkDialog={async (virtualDevice) => {
                        try {
                            this.setState({ showVirtualDeviceToDeleteModal: false })

                            this.props.setLoading(true)

                            const { token } = virtualDevice
                            await this?.props?.deleteVirtualDevice(token)
                            await this.props?.fetchVirtualDevices()
                        } catch (err) {
                            console.error(err)
                        } finally {
                            this.props.setLoading(false)
                        }
                    }}
                    onDismissDialog={() => this.setState({ showVirtualDeviceToDeleteModal: false })}
                    item={this.state.virtualDeviceToDelete}
                />

                <Snackbar className="sm-snackbar" action="Dismiss" type="cancel"
                    active={this.state.displaySnackbar}
                    label={this.state.snackbarLabel}
                    onClick={() => this.setState({ ...this.state, displaySnackbar: false })}
                    onTimeout={() => this.setState({ ...this.state, displaySnackbar: false })}
                    timeout={10000}
                />
            </div>
        );
    }

    async refreshData(): Promise<void> {
        await this.props.fetchOrganization()
        await sleep(500)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VirtualDeviceManager);


type CreateVirtualDeviceProps = { platforms: { caption: string, action: string }[], icons: { [key: string]: any } }
type CreateVirtualDeviceExposedProps = { onCreateAction: (val: VirtualDeviceRequest) => void }
const CreateVirtualDevice = connect(
    function mapStateToProps(state: State.All) {
        const formatPlatformName = (val: string) => includes(['sms'], val) ? upperCase(val) : capitalize(val)

        const platforms = chain(state?.organization?.selectedOrganization?.subscription?.platforms)
            .sort()
            .map(it => ({ caption: `${formatPlatformName(it)} Virtual Device`, action: snakeCase(lowerCase(it)) }))
            .value()

        return {
            platforms,
            icons: PLATFORM_ICONS
        };
    },
    undefined,
    function mergeProps(ownProps: any, stateProps: any, dispatchProps: CreateVirtualDeviceExposedProps) {
        return { ...ownProps, ...stateProps, ...dispatchProps }
    }
)((props: CreateVirtualDeviceProps & CreateVirtualDeviceExposedProps, state: any) => {
    const ref: any = { current: undefined }
    const { platforms, onCreateAction, icons } = props

    return <div className={Styles.create_virtual_device_menu_container}>
        <Menu
            className={Styles.platforms_menu}
            selectable={false}
            icon={"add"}
            position="bottomLeft"
            menuRipple={true}
            ref={val => ref.current = val}
        >

            {
                chain(platforms)
                    .map((it, index) => <MenuItem
                        key={`menu-${it.action}-${index}`}
                        value={it.action}
                        caption={it.caption}
                        icon={<PlatformIcon platform={it.action} />}
                        onClick={() => {
                            onCreateAction({ platform: it.action })

                            if (isFunction(ref.current.setState)) {
                                ref.current.setState({ active: false })
                            }
                        }}
                    />)
                    .reduce((prev, curr) => prev.concat(curr), [])
                    .value()
            }
        </Menu>
        <span className={Styles.add_new} data-intercom-target="AddDevice">Add Virtual Device</span>
    </div>
})


type VirtualDeviceTableActions = 'copy-token' | 'refresh-token' | 'delete-virtual-device'
type VirtualDeviceTableProps = { filter: string, onActionClicked: (action: VirtualDeviceTableActions, virtualDevice: any) => void, onNameChanged: (token: string, newName: string) => void }
const VirtualDeviceTable = connect(
    function mapStateToProps(state: State.All) {

        const canRefreshToken = (platform: string) => ![sourceType.alexa, sourceType.google].some(t => t === (platform as sourceType))

        const items = chain(state?.organization?.selectedOrganization?.virtualDevices)
            .filter((({ status }) => status !== 'disabled'))
            .sortBy((({ name }) => name))
            .map(it => ({ ...it, disableRefreshToken: canRefreshToken(it.platform) }))
            .value()

        return {
            items,
            icons: PLATFORM_ICONS
        };
    },
    undefined,
    function mergeProps(ownProps: any, stateProps: any, dispatchProps: VirtualDeviceTableProps) {
        return { ...ownProps, ...stateProps, ...dispatchProps }
    }
)((props: any) => {
    const { items, onActionClicked, icons, onNameChanged, filter } = props
    // To avoid warning: Unknown props `column`, `tagName` on <td> tag. Remove these props from the element.
    const EmptyRow = () => <td colSpan={6}><div className={Styles.no_rows}>No virtual devices available. Create your first virtual device by clicking the button below.</div></td>

    return <Table theme={tableTheme} selectable={false} multiSelectable={false}>
        <TableHead>
            <TableCell>Type</TableCell>
            <TableCell>Token</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>&nbsp;</TableCell>
        </TableHead>
        <TableHead />
        {
            chain(items)
                .thru(items => {
                    // show all platforms
                    if (isEmpty(filter)) { return items }
                    // filter the selected platform
                    return items.filter((it: any) => lowerCase(it.platform) === lowerCase(filter))
                })
                .map((item) => {
                    const { token, platform, name, status, created, disableRefreshToken } = item

                    return <TableRow key={`row-token-${token}`}>
                        <TableCell><PlatformIcon platform={platform}/></TableCell>
                        <TableCell data-intercom-target="TokenColumn">{token || ""}</TableCell>
                        <TableCell data-intercom-target="NameColumn">
                            <GenericInlineEdit
                                className={Styles.inline_input}
                                editClassName={Styles.inline_input_edit}
                                name={name || ""}
                                onChange={(value: string) => onNameChanged(token, value)}
                            />
                        </TableCell>
                        <TableCell>{status || ""}</TableCell>
                        <TableCell>{format(created, "MM/dd/yyyy hh:mmaaa")}</TableCell>
                        <TableCell data-intercom-target="Actions column">
                            <div>
                                <TooltipSpan
                                    tooltipPosition={"bottom"}
                                    theme={bespokenTooltip}
                                    tooltip={"Copy Device Token"}
                                >
                                    <IconButton
                                        data-id="virtual-device-copy-token-action"
                                        className={`${Styles.icon_button} ${Styles.copy}`}
                                        icon={<BespokenCopyIcon />}
                                        onClick={() => onActionClicked('copy-token', item)}
                                    />
                                </TooltipSpan>
                                {disableRefreshToken ? (
                                    <IconButton
                                        data-id="virtual-device-refresh-permissions-action"
                                        className={Styles.icon_button}
                                        icon={<BespokenRefreshIcon />}
                                        disabled
                                    />
                                ) : (
                                    <TooltipSpan
                                        tooltipPosition={"bottom"}
                                        theme={bespokenTooltip}
                                        tooltip={"Refresh Permissions"}
                                    >
                                        <IconButton
                                            data-id="virtual-device-refresh-permissions-action"
                                            className={Styles.icon_button}
                                            icon={<BespokenRefreshIcon />}
                                            onClick={() => onActionClicked('refresh-token', item)}
                                        />
                                    </TooltipSpan>
                                )}
                                <TooltipSpan
                                    tooltipPosition={"bottom"}
                                    theme={bespokenTooltip}
                                    tooltip={"Delete"}
                                >
                                    <IconButton
                                        data-id="virtual-device-delete-action"
                                        className={Styles.icon_button}
                                        icon={<BespokenDeleteIcon />}
                                        onClick={() => onActionClicked('delete-virtual-device', item)}
                                    />
                                </TooltipSpan>
                            </div>
                        </TableCell>
                    </TableRow>
                })
                .thru(items => {
                    if (isEmpty(items)) {
                        return [<TableRow key={'row-token-empty'}><EmptyRow /></TableRow>]
                    }
                    return items
                })
                .value()
        }
    </Table>
})

type CreateVirtualDeviceModalProps = { showModal: boolean, texts: any, request: VirtualDeviceRequest, onCreateClick: (request: VirtualDeviceRequest) => void, onDismissDialog: () => void }
const CreateVirtualDeviceModal = (props: CreateVirtualDeviceModalProps) => {
    const { onCreateClick, request, showModal, texts, onDismissDialog } = props
    const { description } = texts || {}
    return <BespokenModal
        title='Create new Virtual Device'
        showModal={showModal}
        dialogToggle={() => isFunction(onDismissDialog) && onDismissDialog()}
    >
        <p dangerouslySetInnerHTML={{ __html: description }} />
        <div className={Styles.dialog_buttons_container}>
            <Button
                data-id="virtual-device-add-modal-cancel-action"
                theme={bespokenButton}
                onClick={() => isFunction(onDismissDialog) && onDismissDialog()}
            >
                Cancel
            </Button>
            <Button
                data-id="virtual-device-add-modal-ok-action"
                theme={bespokenButton}
                accent={true}
                onClick={() => isFunction(onCreateClick) && onCreateClick(request)}
            >
                OK
            </Button>
        </div>
    </BespokenModal>
}


type ComfirmDeleteModalItem = { token: string, name: string }
type ComfirmDeleteModalProps = { showModal: boolean, item: ComfirmDeleteModalItem, onOkDialog: (item: ComfirmDeleteModalItem) => void, onDismissDialog: () => void }
const ComfirmDeleteModal = (props: ComfirmDeleteModalProps) => {
    const { item, onDismissDialog, onOkDialog, showModal } = props
    const { name } = item || {}
    return (
        <WarningModal item={item} onDismissDialog={() => attemptInvoke(onDismissDialog)} onOkDialog={it => attemptInvoke(onOkDialog, it)} showModal={showModal}>
            <p>Are you sure you want to remove this virtual device ({name})?</p>
        </WarningModal>
    )
}
