import * as cn from "classnames";
import { isEmpty, isEqual } from "lodash";
import * as React from "react";
import Switch from "react-toolbox/lib/switch";
import Tooltip from "react-toolbox/lib/tooltip";
import ReactDiv from "../ReactDiv";

const TooltipDiv = Tooltip(ReactDiv);

const Styles = require("./SwitchLabelStyle.scss");
const ThemeTooltip = require("../../themes/tooltip.scss");
const ThemeSwitchSmall = require("./SwitchLabelThemeSmall.scss");
const ThemeSwitchMedium = require("./SwitchLabelThemeMedium.scss");
const ThemeSwitchLarge = require("./SwitchLabelThemeLarge.scss");

const ICON_DIRECTIONS = {
  "left": "row",
  "right": "row-reverse"
}

const COMPONENT_SIZE = {
  "small": ThemeSwitchSmall,
  "medium": ThemeSwitchMedium,
  "large": ThemeSwitchLarge
}

type IconPosition = "left" | "right"
type ComponentSize = "small" | "medium" | "large"
interface SwitchLabelProps {
  label: string;
  iconPosition?: IconPosition;
  checked?: boolean
  gap?: string;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  size?: ComponentSize;
  tooltipText?: string;
  checkedTooltipText?: string;
}

interface SwitchLabelState {
  checked: boolean
}
export class SwitchLabel extends React.Component<SwitchLabelProps, SwitchLabelState> {

  static defaultProps: SwitchLabelProps = {
    iconPosition: "left",
    size: "small",
    label: "Toggle Button",
    checked: false,
    tooltipText: undefined,
    checkedTooltipText: undefined
  }

  state: SwitchLabelState = {
    checked: false
  }

  constructor(props: SwitchLabelProps) {
    super(props);
    const { checked } = props;
    this.state = { checked };
  }

  componentWillReceiveProps(nextProps: Readonly<SwitchLabelProps>, nextContext: any): void {
    if (!isEqual(this.props, nextProps)) {
      const { checked } = nextProps
      this.setState({ checked })
    }
  }

  render(): false | JSX.Element {
    const {
      label,
      iconPosition,
      gap,
      onChange,
      disabled,
      size,
      tooltipText,
      checkedTooltipText
    } = this.props
    const flexDirection = ICON_DIRECTIONS[iconPosition] as "row" | "row-reverse"
    const { checked } = this.state;

    const nestedComponent = <div className={Styles.container} style={{ gap, flexDirection }}>
      <Switch
        theme={COMPONENT_SIZE[size]}
        disabled={disabled}
        checked={checked}
        onChange={(checked: boolean) => this.setState({ checked }, () => onChange && onChange(this.state.checked))} />
      <a
        onClick={() => { if (!disabled) { this.setState({ checked: !checked }, () => onChange && onChange(this.state.checked)) } }}
        className={cn(Styles[`button_${size}`], disabled ? Styles.button_disabled : undefined)}
      >
        {label}
      </a>
    </div>

    return (isEmpty(tooltipText) ? (nestedComponent) : (
      <TooltipDiv
        tooltipPosition={"left"}
        theme={ThemeTooltip}
        tooltipDelay={500}
        tooltip={(this.state.checked === true && !isEmpty(checkedTooltipText)) ? checkedTooltipText : tooltipText}>
        {nestedComponent}
      </TooltipDiv >
    ))
  }
}