import { startsWith, toLower } from "lodash";
import { BespokenSessionArgs } from "../index-utils";


export const TEST_API_URL = process.env.TEST_API_URL;
const isHttps = startsWith(toLower(TEST_API_URL), "https://");

export default class TestApi {
    static async excelImport(file: File) {
        const url = `${TEST_API_URL}/test-import/excel`;
        const params = new URLSearchParams({
            'api-key': `api-${BespokenSessionArgs.UserId}`,
            'org-id': BespokenSessionArgs.OrganizationId,
        });

        const urlWithParams = `${url}?${params.toString()}`;

        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch(urlWithParams, {
            method: "POST",
            headers: {},
            body: formData});

        const responseBody = await response.json();

        return responseBody.message;
    }
}