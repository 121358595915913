import * as React from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import Snackbar from "react-toolbox/lib/snackbar";
import { setLoading } from "../../actions/loading";
import { Loader } from "../../components";
import { State } from "../../reducers";
import auth from "../../services/auth";
import * as Intercom from "../../services/intercom";
import FirstStep from "./FirstStep";
import { defaultTo, isEmpty } from "lodash";
import UtilsServer from "../../services/utils-server";
import { User } from "../../reducers/user";
import { createOrganization, fetchOrganization } from "../../actions/organization";
import { AuthUser } from "../../reducers/context";
import { wrapCallbackAsAsync } from "../../utils/ReactHelpers";
import { fetchUser } from "../../actions/user";
import { loginWithIbm } from "../../actions/context";

const style = require("./surveyStyles.scss");

interface SurveyVerifyProps {
    authUser: AuthUser;
    user: User;
    goTo: (path: string) => (dispatch: any) => void;
    setLoading: (value: boolean) => (dispatch: any) => void;
    loading: boolean;
    createOrganization: (user: User) => Promise<any>;
    fetchUser: () => Promise<any>;
    fetchOrganization: (organizationId: string) => Promise<any>;
    loginWithIbm: (instanceId: string, code: string) => Promise<any>;
}

interface SurveyVerifyStates {
    showSnackbar: boolean;
    snackbarMessage: string;
    showLoader: boolean;
}

const mapStateToProps = (state: State.All) => ({
    authUser: state.context.authUser,
    user: defaultTo(state.user.currentUser, {}),
    loading: state.loading.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
    goTo: (path: string) => {
        dispatch(replace(path));
    },
    setLoading: (value: boolean) => {
        return dispatch(setLoading(value));
    },
    loginWithIbm: (instanceId: string, code: string) =>
        wrapCallbackAsAsync(handle => dispatch(loginWithIbm(instanceId, code, handle))),
    createOrganization: (user: User) =>
        wrapCallbackAsAsync(handle => dispatch(createOrganization(user, handle))),
    fetchUser: () => wrapCallbackAsAsync(handle => dispatch(fetchUser(handle))),
    fetchOrganization: (organizationId: string) =>
        wrapCallbackAsAsync(handle => dispatch(fetchOrganization(organizationId, handle))),
});

const globalWindow: any = typeof (window) !== "undefined" ? window : {};

export class Survey extends React.Component<SurveyVerifyProps, SurveyVerifyStates> {
    constructor(props: SurveyVerifyProps) {
        super(props);
        this.state = {
            showSnackbar: false,
            snackbarMessage: "",
            showLoader: false
        };
    }

    handleOnboardingComplete = async (data: any) => {
        const { name, userCompany, jobTitle, email, hearAboutUs } = data;
        const isIbmUser = !isEmpty(this.props?.user?.onboardingData?.ibm);

        globalWindow && Intercom.updateIntercom(globalWindow, {
            name,
            jobTitle,
            email,
            ibmUser: isIbmUser,
            userWasInvited: false,
        }, email);

        const currentUserEmail = email || auth.getCurrentUserEmail();
        UtilsServer.generateBespokenLead(name, '', currentUserEmail, userCompany);

        try {
            this.props.setLoading(true);
            const updatedProps: User = {
                id: this.props.authUser.id,
                name,
                onboardingData: {
                    organizationName: userCompany
                },
                jobTitle,
                surveyData: {
                    hearAboutUs,
                    isNewsLetterChecked: true
                },
                surveyCompleted: true,
                ...(isIbmUser ? { email } : {})
            };

            const result = await this.props.createOrganization(updatedProps);

            if (isIbmUser) {
                const instanceId = this.props?.user?.onboardingData?.ibm?.instanceId;
                await this.props.loginWithIbm(instanceId, '');
                this.props.goTo("/");
                window.location.reload();
                return;
            }

            const organizationIdOrDefault = result?.id || 'default';
            await this.props.fetchUser();
            await this.props.fetchOrganization(organizationIdOrDefault);

            if (this.props.user?.userRequiresActivation) {
                this.props.goTo("/needsActivation");
            } else {
                this.props.goTo("/skills");
            }
        } catch (error) {

            this.changeSnackMessage("An error occurred. Please try again.");
        } finally {
            this.props.setLoading(false);
        }
    };

    handleSnackbarClick = async () => {
        await this.setState({ showSnackbar: false });
    }

    changeSnackMessage = (snackbarMessage: string) => {
        this.setState({
            showSnackbar: true,
            snackbarMessage
        });
    }

    render() {
        return (
            <div className={"survey"}>
                <FirstStep
                    user={this.props.user}
                    onStepComplete={this.handleOnboardingComplete}
                />
                <Snackbar
                    className="sm-snackbar"
                    action="Dismiss"
                    type="cancel"
                    active={this.state.showSnackbar}
                    label={this.state.snackbarMessage}
                    onClick={this.handleSnackbarClick}
                    timeout={10000}
                    onTimeout={this.handleSnackbarClick}
                />
                {this.props.loading && <Loader />}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Survey);