import * as cn from "classnames";
import { format } from "date-fns";
import { get } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import { setLoading } from "../../actions/loading";
import { setCurrentPageProps } from "../../actions/context";
import { LoaderIndicator, TestRunDetails } from "../../components/ConsolidatedReports";
import Source from "../../models/source-legacy";
import { UserDetails } from "../../models/user";
import { State } from "../../reducers";
import { CurrentPageProps } from "../../reducers/context";
import BespokenReportingApi, { DoughnutChartData, TestIdParameters, TestRunBreakdown, TestRunIdParameters, TestRunSummaryRow } from "../../services/bespoken-reporting-api";
import { User } from "../../reducers/user";

const Styles = require("./TestRunDrillDownPageStyle.scss");

interface TestRunDrillDownPagePath {
    runId: string;
}

interface TestRunDrillDownPageProps {
    params: TestRunDrillDownPagePath;
    setLoading: (value: boolean) => any;
    setCurrentPageProps: (value: CurrentPageProps) => any;
    user: User;
    userDetails: UserDetails;
    sources: Source[];
    currentSource: Source;
    getSources: () => Promise<Source[]>;
    sourceSearchText: string;
    test?: string;
    goBack: () => void;
}

interface ReportData {
    testRunSummary?: TestRunSummaryRow[];
    testSuiteByResult?: DoughnutChartData;
    testByResult?: DoughnutChartData;
    testRunBreakdown?: TestRunBreakdown[]
}

interface TestRunDrillDownPageState extends ReportData {
    testRunId: TestRunIdParameters;
    loading: boolean;
}

export class TestRunDrillDownPage extends React.Component<TestRunDrillDownPageProps, TestRunDrillDownPageState> {

    reportingApi: any;
    testDetailsTable: any;

    constructor(props: TestRunDrillDownPageProps) {
        super(props);

        this.state = {
            testRunId: {
                customerId: undefined,
                testRunId: undefined,
                testResultId: undefined,
            },
            loading: true
        };
    }

    componentDidMount(): void {
        const testRunId = this.props.params.runId;
        const customerId = this.props.user.id;

        this.retrieveData({ testRunId, customerId } as TestRunIdParameters)
            // TODO: hadle project name with null id and date
            .then(s => {
                this.props.setCurrentPageProps({
                    title: "Test Run",
                    subTitle: "Execution Details"
                });
                return s;
            })
            .then((reportsData: ReportData) => this.setState({ ...reportsData, loading: false }))
    }

    async retrieveData(id: TestRunIdParameters | TestIdParameters): Promise<ReportData> {
        return Promise.all([
            this.reportingApi.getTestRunSummary(id)
                .catch(() => ({})),
            this.reportingApi.getTestSuitesGroupedByResultForTestRun(id)
                .catch(() => ({})),
            this.reportingApi.getTestsGroupedByResultForTestRun(id)
                .catch(() => ({})),
            this.reportingApi.getTestRunBreakdown(id)
                .catch(() => ({}))

        ]).then(({
            0: testRunSummary,
            1: testSuiteByResult,
            2: testByResult,
            3: testRunBreakdown
        }) => ({
            testRunSummary,
            testSuiteByResult,
            testByResult,
            testRunBreakdown
        }))
            .catch(err => { console.log("Error:", err); return this.state })
    }

    render() {
        const loadingStateClass = this.state.loading ? Styles.loading_feature : "";

        return (
            <div className={Styles.layout}>
                <BespokenReportingApi ref={(instance: any) => {
                    if (!instance) { return }
                    this.reportingApi = instance.getWrappedInstance();

                }} />
                <div className={loadingStateClass}>
                    <LoaderIndicator className={Styles.hide} />
                    {
                        this.reportingApi && (
                            <TestRunDetails
                                runId={this.props.params.runId}
                                customerId={this.props.user.id}
                                reportingApi={this.reportingApi}
                            />
                        )
                    }
                </div>
            </div>
        );
    }
}


function mapStateToProps(state: State.All) {
    return {
        user: state.user?.currentUser,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        setLoading: function (value: boolean) {
            return dispatch(setLoading(value));
        },
        goBack: function () {
            dispatch(replace('/history'))
        },
        setCurrentPageProps: (value: CurrentPageProps) => dispatch(setCurrentPageProps(value))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TestRunDrillDownPage);


