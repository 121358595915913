import * as React from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import Snackbar from "react-toolbox/lib/snackbar";

import BespokenLogo from "../../../assets/bespoken_logoweb.svg";
import BespokenMountains from "../../../assets/bespoken_mountains.svg";
import * as backgroundLeft from "../../../assets/images/bespoken_needs_validation.jpg";
import ShakingIcon from "../../../assets/shaking.svg";
import { logout } from "../../actions/context";
import { LeftPanel, RightPanel } from "../../components";
import { State } from "../../reducers";
import { defaultTo } from "lodash";
import { SelectedOrganization } from "../../reducers/organization";
import { User } from "../../reducers/user";
import { AuthUser } from "../../reducers/context";

const style = require("./needsActivation.scss");

const globalWindow: any = typeof (window) !== "undefined" ? window : {};

interface NeedsActivationProps {
    logout: () => (dispatch: any) => void;
    user: User;
    goTo: (path: string) => (dispatch: any) => void;
    currentOrganization: SelectedOrganization;
    authUser: AuthUser;
}

interface NeedsActivationStates {
    showSnackbar: boolean;
    snackbarMessage: string;
}

const mapStateToProps = (state: State.All) => ({
    authUser: state.context?.authUser,
    user: state.user?.currentUser,
    currentOrganization: defaultTo(state?.organization?.selectedOrganization, {})
});

const mapDispatchToProps = (dispatch: any) => ({
    goTo: (path: string) => {
        dispatch(replace(path));
    },
    logout: () => {
        return dispatch(logout());
    },
});

const INTERCOM_EMAIL_TEMPLATE = "activateAccount";

export class NeedsActivation extends React.Component<NeedsActivationProps, NeedsActivationStates> {
    constructor(props: NeedsActivationProps) {
        super(props);
        this.state = {
            showSnackbar: false,
            snackbarMessage: "",
        };
    }

    async componentDidMount() {
        const { user, currentOrganization } = this.props;
        const { ibmUser = false, requiresActivation } = currentOrganization || {};
        if (!ibmUser && !requiresActivation) {
            this.props.goTo("/skills");
        }
    }

    renderBackToLogin = () => {
        const { ibmUser = false } = this.props.currentOrganization || {};
        return (
            <div>
                {
                    ibmUser ?
                        <div data-id="center"><span>- or -</span></div> : <br />
                }
                {
                    ibmUser ?
                        <a data-id="go-skill" onClick={() => { location.href = '/' }}>Continue to your Dashboard</a>
                        : <a data-id="logout" onClick={this.props.logout}>Back to Login</a>
                }
            </div>
        );
    }



    renderSendEmail = () => {
        const { authUser, user } = this.props;
        const url = `https://bespoken.youcanbook.me/?NAME=${encodeURIComponent(user?.name)}&EMAIL=${encodeURIComponent(authUser?.email)}`;
        return (<a data-id="sendEmail" href={url} target="_blank">Select a time slot now</a>);
    }

    hideSnackBar = () => {
        this.setState({ showSnackbar: false });
    }

    render() {
        return (
            <div className="needsActivation">
                <LeftPanel className={style.leftPanel} fullHeight image={backgroundLeft}>
                    <div data-id="details">
                        <ShakingIcon data-id="icon" />
                        <div data-id="message1">Welcome to your Dashboard</div>
                        <div data-id="message2">thank you!</div>
                    </div>
                    <div data-id="companyName">Bespoken</div>
                </LeftPanel>
                <RightPanel className={style.rightPanel} fullHeight>
                    <BespokenLogo data-id="icon" />
                    <section>
                        <div data-id="message1">We're almost there!</div>
                        Set an appointment with us to complete your onboarding. In this meeting we will:
                        <br />
                        <ul>
                            <li>Understand your testing needs</li>
                            <li>Teach you how to use Bespoken to meet these needs</li>
                            <li>Show you other cool stuff to help you get off to a great start</li>
                        </ul>
                        Our customers find these meetings to be extremely helpful - and they typically take less than fifteen minutes.
                        <br />
                        <div className={style.sendEmail}>{this.renderSendEmail()}</div>
                        <div data-id="center">{this.renderBackToLogin()}</div>
                    </section>
                    <BespokenMountains data-id="mountains" />
                    <Snackbar className="sm-snackbar" action="Dismiss" type="cancel"
                        active={this.state.showSnackbar}
                        label={this.state.snackbarMessage}
                        onClick={this.hideSnackBar}
                        timeout={10000}
                        onTimeout={this.hideSnackBar} />
                </RightPanel>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NeedsActivation);
