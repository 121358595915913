import * as React from "react";
import { sortBy, toLower } from "lodash";
import add from 'date-fns/add';
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import BespokenDownloadIcon from "../../../assets/unicons/download-alt.svg";
import { FilterParameters } from "../../services/bespoken-reporting-api";
import { VALUE_UNDEFINED, VALUE_NULL } from "../../services/bespoken-reporting-api";
import BespokenReportingApi from "../../services/bespoken-reporting-api";
import { DatePicker } from '../DatePicker';
import Dropdown from "../../components/Dropdown/Dropdown";
import FilteredDropdown from "../../components/FilteredDropdown/FilteredDropdown";
import { PrimaryButtonMedium } from "../lunacy/buttons/Button";
import { IconLabelButton } from "../IconLabelButton/IconLabelButton";

const Styles = require("./ResultsRangeFilterStyle.scss");
const CLIENT_TIMEZONE_OFFSET = new Date().getTimezoneOffset()

export interface ResultsRangeFilterProps {
    onFiltered: (filters: FilterParameters) => void;
    onDownload: (filters: FilterParameters) => void;
    filters: FilterParameters;
    projectId?: string;
}

export interface ResultsRangeFilterState {
    currentFilterParameters: FilterParameters;
    projects: Array<{ project_id: string; project_name: string }>;
    platforms?: [];
    locales?: [];
    clients?: [];
    activee?: boolean;
}

export class ResultsRangeFilter extends React.Component<ResultsRangeFilterProps, ResultsRangeFilterState> {

    reportingApi: any;

    state: ResultsRangeFilterState;
    props: ResultsRangeFilterProps;

    constructor(props: ResultsRangeFilterProps) {
        super(props);

        this.state = {
            currentFilterParameters: {
                projectId: this.props.projectId || VALUE_UNDEFINED,
                platformId: VALUE_UNDEFINED,
                localeId: VALUE_UNDEFINED,
                startTimestamp: startOfDay(add(new Date(), { days: -7 })),
                endTimestamp: endOfDay(new Date()),
                searchKey: 0,
                page: 1,
                timezoneOffset: CLIENT_TIMEZONE_OFFSET
            },
            projects: [],
        };
    }

    componentDidMount(): void {
        if (this.props.onFiltered) {
            this.setState({ currentFilterParameters: this.props.filters || this.state.currentFilterParameters }, () => {
                this.props.onFiltered(this.state.currentFilterParameters)
            })
        }
        Promise.all([
            this.reportingApi.getProjects()
                .then((data: any[]) => sortBy(data, [({ project_name }) => toLower(project_name)]))
                .then((data: any[]) => [
                    { project_id: VALUE_UNDEFINED, project_name: 'All Test Suites' },
                    ...data.map(({ project_id, project_name }) => ({
                        project_id: project_id || VALUE_NULL,
                        project_name: project_name || '[no-project]'
                    }))
                ])
                .catch(() => [{ project_id: VALUE_UNDEFINED, project_name: 'Error in server' }]),
            this.reportingApi.getPlatforms()
                .then((data: []) => sortBy(data, [({ platform_name }) => toLower(platform_name)]))
                .then((data: []) => [{ platform_id: VALUE_UNDEFINED, platform_name: 'All Platforms' }].concat(data))
                .then((data: []) => data.map(({ platform_id: value, platform_name: label }) => ({ value: value || VALUE_NULL, label: label || '[no-platform]' })))
                .catch(() => [{ label: 'Error in server' }]),
            this.reportingApi.getLocales()
                .then((data: []) => [{ locale_id: VALUE_UNDEFINED, locale_name: 'All Locales' }].concat(data))
                .then((data: []) => data.map(({ locale_id: value, locale_name: label }) => ({ value: value || VALUE_NULL, label: label || '[no-locale]' })))
                .catch(() => [{ label: 'Error in server' }]),
            this.reportingApi.getClients()
                .then((data: []) => [{ client_id: VALUE_UNDEFINED, client_name: 'All Clients' }].concat(data))
                .then((data: []) => data.map(({ client_id: value, client_name: label }) => ({ value: value || VALUE_NULL, label: label || '[no-client]' })))
                .catch(() => [{ label: 'Error in server' }]),
        ]).then(({
            0: projects,
            1: platforms,
            2: locales,
            3: clients,
        }) => ({
            projects,
            platforms,
            locales,
            clients
        }))
            .then((data: any) => this.setState({ ...data }))
    }

    render() {
        return (
            <div className={Styles.layout}>
                <label>Test Suite</label>
                <label>Platform</label>
                <label>Locale</label>
                <label>From</label>
                <label>To</label>
                <label />
                <label />

                <BespokenReportingApi ref={(instance: any) => { if (!instance) { return } this.reportingApi = instance.getWrappedInstance() }} />
                <FilteredDropdown
                    options={this.state.projects.map(({ project_id, project_name }) => ({
                        value: project_id,
                        label: project_name
                    }))}
                    value={this.state.currentFilterParameters.projectId}
                    onChange={(value: string) => this.handleChange({ projectId: value })}
                    placeholder=""
                    className={Styles.projectDropdown}
                />
                <Dropdown
                    source={this.state.platforms || []}
                    value={this.state.currentFilterParameters.platformId || VALUE_UNDEFINED}
                    onChange={(platformId: string) => this.handleChange({ platformId })}
                />
                <Dropdown
                    className={Styles.datePickerTest}
                    source={this.state.locales || []}
                    value={this.state.currentFilterParameters.localeId || VALUE_UNDEFINED}
                    onChange={(localeId: string) => this.handleChange({ localeId })}
                />
                <div className={Styles.datePickerWrapper}>
                    <DatePicker
                        sundayFirstDayOfWeek
                        onChange={(startTimestamp: Date) => this.handleChange({ startTimestamp: startOfDay(startTimestamp) })}
                        value={this.state.currentFilterParameters.startTimestamp}
                    />
                </div>
                <div className={Styles.datePickerWrapper}>
                    <DatePicker
                        sundayFirstDayOfWeek
                        onChange={(endTimestamp: Date) => this.handleChange({ endTimestamp: endOfDay(endTimestamp) })}
                        value={this.state.currentFilterParameters.endTimestamp}
                    />
                </div>
                <div className={Styles.filter_actions}>
                    <IconLabelButton
                        icon={<BespokenDownloadIcon />}
                        label="Download CSV"
                        size="medium"
                        gap="12px"
                        onClick={() => this.handleDownload({ /* attribute used to force search when button is clicked */searchKey: Date.now() })}
                    />
                </div>
                <div className={Styles.filter_actions}>
                    <PrimaryButtonMedium
                        onClick={() => this.handleChange({ /* attribute used to force search when button is clicked */searchKey: Date.now() })}
                    >Refresh</PrimaryButtonMedium>
                </div>
            </div>
        );
    }

    handleChange(val: { startTimestamp?: Date, endTimestamp?: Date, customerId?: string, projectId?: string, platformId?: string, localeId?: string, clientId?: string, searchKey?: number }) {
        const currentFilterParameters = { ...this.state.currentFilterParameters, ...val }
        this.setState({ currentFilterParameters })

        if (this.props.onFiltered) {
            // sets page to 1 when result was reloaded
            this.props.onFiltered({ ...currentFilterParameters, page: 1 })
        }
    }

    async handleDownload(val: { startTimestamp?: Date, endTimestamp?: Date, customerId?: string, projectId?: string, platformId?: string, localeId?: string, clientId?: string, searchKey?: number }) {

        const blob = await this.reportingApi.getTestRunsResultsExport({ ...this.state.currentFilterParameters, ...val })
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', `TestRunsReport_${this.state.currentFilterParameters.startTimestamp.toLocaleDateString()}-${this.state.currentFilterParameters.endTimestamp.toLocaleDateString()}.csv`);
        document.body.appendChild(link);
        link.click();

    }
}
