import * as cn from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { Button } from "react-toolbox/lib/button";
import { BespokenModal } from "../Modal/BespokenModal";
import { fetchInternalApi } from "../../services/internal-api";
import { TextErrorMedium, TextErrorSmall, TextNormal, TextBlueLabelSmall } from "../lunacy";
import { InputWithLabel } from "../lunacy/inputs/InputWithLabel";
import cronValidator from 'cron-validate';

const Styles = require("./monitoring-modal.scss");
const bespokenButton = require("../../themes/bespoken_button.scss");

export const SCHEDULE_TYPES = [
    { label: 'Currently executing every 30 minutes', key: '30m', cron: '*/30 * * * *', trialEnabled: false },
    { label: 'Currently executing every 1 hour', key: '1h', cron: '0 * * * *', trialEnabled: false },
    { label: 'Currently executing every 6 hours', key: '6h', cron: '0 */6 * * *', trialEnabled: true },
    { label: 'Currently executing every 12 hours', key: '12h', cron: '0 */12 * * *', trialEnabled: true },
    { label: 'Currently executing every 1 day', key: '1d', cron: '0 0 * * *', trialEnabled: true },
    { label: 'Currently executing every 7 days', key: '1w', cron: '0 0 * * 0', trialEnabled: true }
]

export interface TestDetailsTableProps {
    canEditSchedule: boolean;
    selectedScheduleType: string;
    showModal: boolean;
    onCancel?: () => Promise<void>;
    onSuccess?: (cron: string, cronExplained: string, emailsToNotify: string, phoneNumbersToNotify: string) => Promise<void>;
    onDelete?: () => Promise<void>;
    currentCron?: string;
    currentEmails?: string;
    currentPhoneNumbers?: string;
    isEditMode?: boolean;
}

export interface TestDetailsTableState {
    createDescription: string;
    showModal: boolean;
    readonlyMode: boolean;
    selectedScheduleType: string;
    scheduleOptions: { value: string, label: string, cron: string, trialEnabled: boolean }[];
    cron: string;
    cronExplained: string;
    emailsToNotify: string;
    phoneNumbersToNotify: string;
    error?: string;
}

export class MonitoringModal extends React.Component<TestDetailsTableProps, TestDetailsTableState> {

    state: TestDetailsTableState;
    props: TestDetailsTableProps;

    constructor(props: TestDetailsTableProps) {
        super(props);

        this.state = {
            showModal: false,
            createDescription: '.....',
            readonlyMode: false,
            selectedScheduleType: SCHEDULE_TYPES[1].key,
            scheduleOptions: SCHEDULE_TYPES.map(({ key, cron, trialEnabled }) => ({ value: key, label: key, cron, trialEnabled })),
            cron: props.isEditMode && props.currentCron ? props.currentCron : '',
            emailsToNotify: props.isEditMode && props.currentEmails ? props.currentEmails : '',
            phoneNumbersToNotify: props.isEditMode && props.currentPhoneNumbers ? props.currentPhoneNumbers : '',
            cronExplained: '',
            error: undefined
        };
    }

    componentDidUpdate(prevProps: TestDetailsTableProps) {
        if (!prevProps.showModal && this.props.showModal) {
            this.setState({
                cron: this.props.currentCron || '',
                emailsToNotify: this.props.currentEmails || '',
                phoneNumbersToNotify: this.props.currentPhoneNumbers || '',
            });
            if (this.state.cron) this.onSelectedSchedule(this.state.cron);
        }
    }

    componentWillReceiveProps(nextProps: Readonly<TestDetailsTableProps>, nextContext: any): void {
        if (!isEqual(nextProps, this.props)) {
            const { showModal, isEditMode, currentCron, currentEmails, currentPhoneNumbers } = nextProps
            let { selectedScheduleType } = nextProps

            if (!selectedScheduleType) {
                selectedScheduleType = '1d'
            }

            this.setState(prevState => ({
                ...prevState,
                showModal,
                selectedScheduleType,
                cron: isEditMode && currentCron ? currentCron : '',
                emailsToNotify: isEditMode && currentEmails ? currentEmails : '',
                phoneNumbersToNotify: isEditMode && currentPhoneNumbers ? currentPhoneNumbers : ''
            }))
        }
    }

    render() {
        const { showModal, readonlyMode, scheduleOptions, selectedScheduleType } = this.state
        const { isEditMode } = this.props
        return (<BespokenModal
            title={isEditMode ? "Edit Monitoring" : "Enable Monitoring"}
            showModal={showModal}
            dialogToggle={async () => !readonlyMode && this.handleCancelModal()} >
            <div className={Styles.modal_body}>
                <div style={{ display: this.state.error ? 'block' : 'none', textAlign: 'center' }}>
                    <TextErrorSmall>{this.state.error}</TextErrorSmall>
                </div>
                <div>
                    <TextNormal>By {isEditMode ? "editing" : "enabling"} monitoring, your tests will run automatically at the selected interval below. Results will be visible at the History tab.</TextNormal>
                </div>
                <div className={Styles.grouping}>
                    <div>
                        <TextBlueLabelSmall>Select an interval:</TextBlueLabelSmall>
                    </div>
                    <div className={Styles.schedule_control}>
                        {
                            scheduleOptions.map(({ value, label, cron, trialEnabled }) => (
                                <div
                                    key={`schedule-type-${value}`}
                                    className={cn(
                                        Styles.schedule_option,
                                        this.props.canEditSchedule || trialEnabled ? 'hello' : Styles.schedule_option_disabled,
                                        this.state.cron === cron ? Styles.selected_option : ''
                                    )}
                                    onClick={evt => (this.props.canEditSchedule || trialEnabled) && this.onSelectedSchedule(cron)}
                                >
                                    {label}
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div>
                    <InputWithLabel value={this.state.cron}
                        label={<TextBlueLabelSmall>CRON Schedule (expressed in UTC) - <a href="https://docs.gitlab.com/ee/topics/cron/">Learn More</a>:</TextBlueLabelSmall>}
                        onChange={(cron: string) => this.setState({ cron })}
                        onBlur={() => this.onSelectedSchedule(this.state.cron)}
                        disabled={!this.props.canEditSchedule}
                    />
                </div>
                <div>
                    <InputWithLabel
                        label={<TextBlueLabelSmall>CRON Schedule (In Plain English):</TextBlueLabelSmall>}
                        value={this.state.cronExplained}
                        disabled={true} />
                </div>
                <div>
                    <InputWithLabel value={this.state.emailsToNotify}
                        label={<TextBlueLabelSmall>Emails to notify (comma separated):</TextBlueLabelSmall>}
                        onChange={(value) => this.setState(prevState => ({ ...prevState, emailsToNotify: value }))}
                    />
                </div>
                <div>
                    <InputWithLabel value={this.state.phoneNumbersToNotify}
                        label={<TextBlueLabelSmall>SMS numbers (comma separated, optional):</TextBlueLabelSmall>}
                        onChange={(value) => this.setState(prevState => ({ ...prevState, phoneNumbersToNotify: value }))}
                    />
                </div>
            </div>
            <div className={Styles.modal_buttons}>
                <Button
                    disabled={readonlyMode}
                    data-id="virtual-device-add-modal-cancel-action"
                    theme={bespokenButton}
                    onClick={async () => this.handleCancelModal()}
                >
                    Cancel
                </Button>
                {isEditMode && (
                    <Button
                        disabled={readonlyMode}
                        data-id="virtual-device-add-modal-delete-action"
                        theme={bespokenButton}
                        onClick={() => this.handleDelete()}
                    >
                        Disable Monitoring
                    </Button>
                )}
                <Button
                    disabled={readonlyMode}
                    data-id="virtual-device-add-modal-ok-action"
                    theme={bespokenButton}
                    accent={true}
                    onClick={() => this.handleSaveModal()}
                >
                    OK
                </Button>
            </div>

        </BespokenModal >
        );
    }

    async onSelectedSchedule(cron: string) {
        if (!cron) {
            this.setState(prevState => ({ ...prevState, cronExplained: '' }))
            return
        }

        const cronResult = cronValidator(cron)
        if (!cronResult.isValid()) {
            this.setState(prevState => ({ ...prevState, error: `CRON expression error: \n${cronResult.getError()[0]}` }))
            return
        }

        const cronExplained = await this.explainCron(cron)
        if (cronExplained.error) {
            this.setState(prevState => ({ ...prevState, error: cronExplained.error }))
        } else {
            this.setState(prevState => ({ ...prevState, cron, cronExplained: cronExplained.expression }))
        }
    }

    async handleSaveModal() {
        const { showModal: currentShowModal } = this.state
        if (this.props.onSuccess) {

            if (this.state.cron.trim().length === 0) {
                this.setState(prevState => ({ ...prevState, error: 'Please, enter a CRON expression first' }))
                return
            }

            const cronResult = cronValidator(this.state.cron)
            if (!cronResult.isValid()) {
                this.setState(prevState => ({ ...prevState, error: `CRON expression error:\n${cronResult.getError()[0]}` }))
                return
            }

            const cronExplained = await this.explainCron(this.state.cron)
            if (cronExplained.hasError()) {
                this.setState(prevState => ({ ...prevState, error: cronExplained.error }))
                return
            }

            if (this.state.emailsToNotify.trim().length === 0) {
                this.setState(prevState => ({ ...prevState, error: 'At least one email address must be provided' }))
                return
            }

            const invalidEmails = this.validateEmails(this.state.emailsToNotify)
            if (invalidEmails.length) {
                this.setState(prevState => ({ ...prevState, error: `The following email addresses are invalid:\n${invalidEmails.join("\n")}` }))
                return
            }

            // Validate phone numbers if provided
            if (this.state.phoneNumbersToNotify.trim().length > 0) {
                const invalidPhoneNumbers = this.validatePhoneNumbers(this.state.phoneNumbersToNotify)
                if (invalidPhoneNumbers.length) {
                    this.setState(prevState => ({ ...prevState, error: `The following phone numbers are invalid:\n${invalidPhoneNumbers.join("\n")}` }))
                    return
                }
            }

            await this.props.onSuccess(this.state.cron, cronExplained.expression, this.state.emailsToNotify, this.state.phoneNumbersToNotify)
            this.setState(prevState => ({ ...prevState, showModal: !currentShowModal, error: '' }))
        }
    }

    async handleCancelModal() {
        if (this.props.onCancel) {
            await this.props.onCancel()
        }
        const cronExplained: string = this.props.isEditMode ? this.state.cronExplained : '';
        this.setState(prevState => ({ ...prevState, showModal: false, cronExplained }))
    }

    async handleDelete() {
        if (this.props.onDelete) {
            await this.props.onDelete()
        }
        this.setState(prevState => ({ ...prevState, showModal: false, cronExplained: '', emailsToNotify: '', phoneNumbersToNotify: '', cron: '' }))
    }

    async explainCron(cron: string): Promise<CRONResult> {
        let cronExplained
        try {
            cronExplained = await fetchInternalApi(
                `/helpers/cronToPlainEnglish/${encodeURIComponent(cron)}`,
                "GET",
                undefined,
                { "Content-Type": "application/json" },
                false);
            return new CRONResult(cronExplained)
        } catch (e) {
            if (e.toString().startsWith('Invalid')) {
                return new CRONResult(undefined, 'Invalid CRON expression. Requires changes to enable monitoring.')
            } else {
                return new CRONResult(undefined, e.toString())
            }
        }
    }

    validateEmails(emails: string): string[] {
        // Regex for email validation
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

        // Split the email list and trim each email
        const emailList = emails.split(',').map(email => email.trim())

        // Filter out invalid emails
        const invalidEmails = emailList.filter(email => !emailRegex.test(email))

        return invalidEmails
    }

    validatePhoneNumbers(phoneNumbers: string): string[] {
        // Regex for phone number validation
        const phoneRegex = /^\+?[1-9]\d{1,14}$/

        // Split the phone number list and trim each number
        const phoneNumberList = phoneNumbers.split(',').map(number => number.trim())

        // Filter out invalid phone numbers
        const invalidPhoneNumbers = phoneNumberList.filter(number => !phoneRegex.test(number))

        return invalidPhoneNumbers
    }
}

class CRONResult {
    constructor(public expression: string, public error?: string) { }

    hasError(): boolean {
        return this.error !== undefined
    }
}