import * as Firebase from "firebase";
import { createHistory } from "history";
import "isomorphic-fetch";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { useRouterHistory } from "react-router";
import { replace, syncHistoryWithStore } from "react-router-redux";
import * as Redux from "redux";
import { autoRehydrate, persistStore } from "redux-persist";
import rootReducer, { State } from "./reducers";
import configureStore from "./store";
import { defaultTo, isEmpty, isNil } from "lodash";
import { fetchOrganization } from "./actions/organization";
import Root from "./containers/Root";
import { BespokenSessionArgs } from "./index-utils";
import { wrapCallbackAsAsync } from "./utils/ReactHelpers";
import TagManager from "./utils/TagManager";
import { AuthUser } from "./reducers/context";
import { debounceEmail, fetchAppSettings, removeAuthUser, setOnboardingViaInvite, updateAuthUser } from "./actions/context";
import { fetchUser } from "./actions/user";
import UtilsServer from "./services/utils-server";
import { logout } from "./actions/context";
// import { logout } from "./actions/session";

const hash = process.env.GIT_HASH;
// const ReactGA = require("react-ga");
// const TagManager = require("react-gtm-module");



let blacklistUrls = [/localhost/, /127.0.0.1/];

// if ALLOW_SENTRY_LOCAL_ERRORS is true, we will send errors from local
if (process.env.ALLOW_SENTRY_LOCAL_ERRORS) {
    blacklistUrls = [];
}

// Initialize Google Analytics
// ReactGA.initialize(__GOOGLE_ANALYTICS__);
// TagManager.initialize({ gtmId: __GOOGLE_TAG_MANAGER__ });
if (__GOOGLE_TAG_MANAGER__) {
    // @ts-ignore
    TagManager.initialize({ gtmId: __GOOGLE_TAG_MANAGER__ });
}

// Creates the Redux reducer with the redux-thunk middleware, which allows us
// to do asynchronous things in the actions
// Help with this from https://github.com/ReactTraining/react-router/issues/353#issuecomment-181786502
// And http://stackoverflow.com/a/38123375/1349766
const browserHistory = useRouterHistory(createHistory)({
    basename: __BASENAME__
});

import * as SentryReact from "@sentry/react";
if (process.env.ENV !== 'prod') {
    const sentryConfig = {
        dsn: "https://9c9082b20111469b9e177d7eb30a7cb2@o79755.ingest.us.sentry.io/191735",
        integrations: [new SentryReact.BrowserTracing()],
        environment: process.env.ENVIRONMENT_NAME,
        release: process.env.APP_VERSION,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }
    SentryReact.init(sentryConfig);
}


// Configure the store
const store = configureStore(browserHistory, rootReducer, autoRehydrate() as Redux.StoreEnhancer<State.All>);
persistStore(store, { whitelist: ["context"] }, (error, result) => {
});
// const context = JSON.parse(localStorage.get("context") || {});
// console.log('context from local storage', context)
// const store = configureStore(browserHistory, rootReducer, { context })
BespokenSessionArgs.store = store

// And our history
const history = syncHistoryWithStore(browserHistory, store);

// Bootstrap Firebase
const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
};

const SPECIAL_PATHS = ['/ibm-redirect', '/sso', '/member-singup', '/member-signup'];

const onAuthStateChangedHandler = async (userFirebase: any) => {


    const state = store.getState();
    const location = state.routing.locationBeforeTransitions;

    if (SPECIAL_PATHS.includes(location?.pathname)) {
        const activeSession = localStorage.getItem('activeSession');
        if (activeSession === 'true') {
            // An active session was detected, so we need to force a logout
            try {
                await store.dispatch(logout());

                localStorage.removeItem('activeSession');
                render(store, history);
                return;
            } catch (error) {
                console.error(`Error during forced logout for ${location.pathname}:`, error);
            }
        }
    }

    if (userFirebase) {
        //window.alert('on state user found: ' + JSON.stringify(userFirebase))
        const authUser: AuthUser = {
            id: userFirebase?.uid,
            email: userFirebase?.email,
            emailVerified: userFirebase?.emailVerified,
        };

        // If the user is a SAML user and is not yet linked to an existing user, we need to let the work on the SSO page complete
        // If there is more than one provider, that means they have been linked
        if (userFirebase.providerData?.[0]?.providerId?.startsWith('saml') && userFirebase.providerData?.length === 1) {
            //store.dispatch(replace('/sso'));
            //render(store, history);
            return
        }
        store.dispatch(updateAuthUser({ authUser }));

        const isIBMRedirect = location?.pathname === '/ibm-redirect';
        const organizationIdOrDefault = defaultTo(store.getState()?.context?.latestOrganizationId, 'default')

        const promises = [
            wrapCallbackAsAsync(handle => store.dispatch(fetchAppSettings(handle))),
            wrapCallbackAsAsync(handle => store.dispatch(fetchUser(handle))),
            wrapCallbackAsAsync(handle => store.dispatch(fetchOrganization(organizationIdOrDefault, handle)))
        ]
        const [appSettings, user, organization]: any[] = await Promise.all(promises.map(p => p.catch(error => ({ error }))));

        if (!state.context.authUser) {
            state.context.authUser = await debounceEmail(userFirebase)
        }
        if (isEmpty(user) && authUser.email) {
            UtilsServer.generateBespokenLead('pending', '', authUser.email, 'pending')
        }
        if (!isEmpty(user?.onboardingData)) {
            store.dispatch(replace("/survey"));
        } else if (user?.error?.message === "Missing user"
            || organization?.error?.message === "Missing organization") {
            // If the user has a valid email (i.e., not free or gmail)
            // or if it's an IBM redirect flow
            if (state.context.authUser?.debounceValid || isIBMRedirect) {
                store.dispatch(replace("/survey"));
            } else {
                store.dispatch(replace("/login"));
            }
        } else if (user?.error || organization?.error) {
            // TODO display error page
            store.dispatch(replace("/login"));
        } else if (!authUser.emailVerified && !user?.skipEmailVerification) {
            replace("/verify");
        } else if (!user?.surveyCompleted) {
            store.dispatch(replace("/survey"));
        } else if (user.userRequiresActivation || organization?.requiresActivation) {
            replace("/needsActivation");
        } else if (organization?.subscription?.isSubscriptionExpired) {
            replace("/renewSubscription");
        } else if (organization?.subscription?.isSubscriptionExpired) {
            replace("/trialExpired");
        } else if (state.context.onboardingViaInvite) {
            store.dispatch(setOnboardingViaInvite(false))
            store.dispatch(replace("/skills"));
        } else {
            const path = localStorage.getItem('redirectToURL');
            if (window.location.href.includes('/sso')) {
                store.dispatch(replace('/skills'));
            } else if (path) {
                localStorage.removeItem('redirectToURL');
                store.dispatch(replace(path));
            } else {
                let redirectPath = isEmpty(location?.pathname) ? '/skills'
                    : location?.pathname === '/' || location?.pathname === '/login' || location?.pathname === '/sso' || location?.pathname === '/ibm-redirect'
                        ? '/skills'
                        : location?.pathname;

                store.dispatch(replace(redirectPath));
            }
        }
    } else {
        // Go to the last page the user requested on logging in
        const lastURL = location?.pathname;


        // If the user was trying to get to a particular test or history, save this request
        // We will redirect them once logged in
        if (lastURL && lastURL.startsWith('/skills') || lastURL.startsWith('/history')) {
            localStorage.setItem('redirectToURL', lastURL);
        } else {
            localStorage.removeItem('redirectToURL');
        }
        const lastAuthUser = state.context.authUser;
        if (lastAuthUser) {
            store.dispatch(removeAuthUser());
        }
        if (location.pathname.startsWith("/token")) {
            store.dispatch(replace(lastURL));
        } else if (location?.pathname !== '/aws-marketplace'
            && location?.pathname !== '/ibm-redirect'
            && location?.pathname !== '/ibm'
            && location?.pathname !== '/ibm-logout'
            && location?.pathname !== '/member-singup'
            && location?.pathname !== '/member-signup'
            && location?.pathname !== '/sso'
            && location?.pathname !== '/survey'
        ) {
            store.dispatch(replace('/login'));
        }
    }

    render(store, history);
}

Firebase.initializeApp(firebaseConfig);
Firebase.auth().onAuthStateChanged(onAuthStateChangedHandler)


// Firebase.auth().onAuthStateChanged(async function (user: Firebase.User) {
//     console.timeEnd("FirebaseInitialize");
//     const state = store.getState();
//     // reauthentication case there is no need to redirect
//     if (!!user && state && state.source && state.source.currentSource && state.routing.locationBeforeTransitions.pathname === "/account") {
//         return;
//     }
//     const lastUser = state.session.user;

//     const location = state.routing.locationBeforeTransitions;
//     const newLocation = { ...location, ...{ pathname: "/" } }; // Doing this will pass along any query parameters that may exist.

//     if (user && location?.pathname === '/ibm-redirect' && location?.query?.state?.indexOf(user?.uid) === -1) {
//         await sleep(2000);
//         logout(() => {
//             render(store, history);
//         })(undefined);
//         return;
//     }

//     if (user) {
//         // singleSignOn if there is a user and a redirect URL then redirect to url with user token
//         const urlParams = new URLSearchParams(state.routing.locationBeforeTransitions?.search);
//         const redirectURL = urlParams && urlParams.get("redirectURL");
//         if (redirectURL) {
//             try {
//                 const idToken = await Firebase.auth().currentUser.getIdToken(true);
//                 const redirectUrlWithToken = `${redirectURL}?userId=${idToken}`;
//                 window.location.replace(redirectUrlWithToken);
//             } catch (error) {
//                 console.log(error);
//             }
//         }
//         // README: after we log in using firebase(like signInWithPopup)
//         // onAuthStateChanged was fired without waiting for
//         // the complete execution of the login function like loginWithProvider
//         let userDetails;
//         let i = 0;
//         do {
//             userDetails = await auth.currentUserDetails();
//             i++;
//             if (isNil(userDetails.userRequiresActivation)) {
//                 await sleep(1000);
//             }
//         } while (userDetails && isNil(userDetails.userRequiresActivation) && i < 3)

//         if (userDetails && !isNil(userDetails.userRequiresActivation)) {
//             const { userRequiresActivation } = userDetails;
//             // listener.listenChangesData();
//             // If there is a new user set it on the store
//             if (!lastUser || lastUser.userId !== user.uid) {
//                 const dashboardNewUser = new FirebaseUser(user);
//                 store.dispatch(setUser(dashboardNewUser));
//                 store.dispatch(setUserDetails(userDetails));
//             }
//             let dashboardUser = new FirebaseUser(user);
//             // Email is verified for authenticated user via providers different than `email/password`. Eg. GitHub
//             if (user.providerData && user.providerData.length && user.providerData[0].providerId !== "password") {
//                 dashboardUser.emailVerified = true;
//                 store.dispatch(setUser(dashboardUser));
//             }

//             // Update user and userDetails after each step completed
//             if (userDetails.surveyCompleted) {
//                 store.dispatch(setUser(dashboardUser));
//                 store.dispatch(setUserDetails(userDetails));
//             }

//             const bespokenUser = await BespokenApi.user(userDetails.userId)

//             store.dispatch(setBespokenUser(bespokenUser));

//             // Reads latest organization from localStorage, check session reducer for the implementation
//             const organizationIdOrDefault = defaultTo(store.getState()?.session?.latestOrganizationId, 'default')
//             await wrapCallbackAsAsync(handle => store.dispatch(fetchOrganization(organizationIdOrDefault, handle)))
//              const isTrialTimeExpired = store.getState().organization.selectedOrganization?.subscription?.isTrialTimeExpired || false
//             const isSubscriptionExpired = store.getState().organization.selectedOrganization?.subscription?.isSubscriptionExpired || false
//             const ibmUser = store.getState().organization.selectedOrganization?.ibmUser || false

//             if (lastUser) {
//                 // If the user is not verify show verify page if it is verify but not completed the survey show survey page, finally if it's both show next location
//                 if (!dashboardUser.emailVerified && !userDetails.skipEmailVerification) {
//                     store.dispatch(replace("/verify"));
//                 } else if (!userDetails.surveyCompleted) {
//                     store.dispatch(replace("/survey"));
//                 } else if (userRequiresActivation) {
//                     store.dispatch(replace("/needsActivation"));
//                 } else if (isTrialTimeExpired) {
//                     store.dispatch(replace("/trialExpired"));
//                 } else if (isSubscriptionExpired) {
//                     store.dispatch(replace("/renewSubscription"));
//                 } else if (ibmUser && location.pathname === '/ibm-redirect') {
//                     // If ibm user was already login
//                     store.dispatch(replace(newLocation));
//                 }
//             } else {
//                 store.dispatch(replace(newLocation));
//             }
//         } else {
//             store.dispatch(replace("/login"));
//         }

//     } else {
//         let afterLogoutPath = '/login'

//         if (lastUser) {
//             if (state?.session?.userDetails?.ibmUser) { afterLogoutPath = '/ibm-logout' }

//             store.dispatch({ type: LOGOUT_USER });
//             store.dispatch(setUser(undefined));
//             store.dispatch(setUserDetails(undefined));
//         }

//         if (location?.pathname !== '/aws-marketplace'
//             && location?.pathname !== '/ibm-redirect'
//             && location?.pathname !== '/ibm'
//             && location?.pathname !== '/ibm-logout'
//             && location?.pathname !== '/member-singup'
//         ) {
//             store.dispatch(replace(afterLogoutPath));
//         }
//     }
//     // We need to wait for the user to be available before we can render the app
//     render(store, history);
// });


const fixDuplicateAppContainer: {
    current: any
} = { current: undefined }
// Hot Module Replacement API
declare let module: { hot: any };

const render = (myStore: any, myHistory: any) => {
    const AppContainer = require("react-hot-loader").AppContainer;

    if (fixDuplicateAppContainer.current) {
        const component = ReactDOM.findDOMNode(fixDuplicateAppContainer.current)
        const result = ReactDOM.unmountComponentAtNode(component.parentElement)
    }

    ReactDOM.render(
        <AppContainer>
            <Root ref={component => fixDuplicateAppContainer.current = component} store={myStore} history={myHistory} />
        </AppContainer>,
        document.getElementById("dashboard")
    );

    if (module.hot) {
        module.hot.accept("./containers/Root", () => {
            const RootLoaded = require("./containers/Root").default;
            ReactDOM.render(
                <AppContainer>
                    <RootLoaded store={myStore} history={myHistory} />
                </AppContainer>,
                document.getElementById("dashboard")
            );
        });
    }
};