import * as React from "react";
import { Util } from "../../helpers/util"

//import { loading_llama } from "../../../assets/images/loading_llama.gif"

const styles = require("../lunacy/texts/styles.scss");
const videoPlayerStyles = require("./VideoPlayerStyles.scss");

interface VideoPlayerProps {
    autoPlay?: boolean;
    height?: number;
    loaded?: boolean;
    url: string;
}

interface VideoPlayerState {
    autoPlay: boolean;
    height?: number;
    loaded: boolean;
    url?: string;
}

class VideoPlayerComponent extends React.Component<VideoPlayerProps, VideoPlayerState> {
    constructor(props: VideoPlayerProps) {
        super(props);
        if (props.url) {
            const fileName = props.url.split('/')[props.url.split('/').length - 1]
            const contentURL = `https://bspk-test-robot.s3.amazonaws.com/${fileName}`
            this.state = {
                autoPlay: props.autoPlay !== undefined ? props.autoPlay : true,
                height: props.height,
                loaded: props.loaded,
                url: contentURL
            }
        // This is only here for debugging - URL should always be provided
        } else {
            this.state = {
                autoPlay: false,
                height: undefined,
                loaded: props.loaded
            }
        }

    }

    componentDidMount() {
        if (this.state.url) {
            this._waitForVideo(this.state.url)
        }

    }

    render(): JSX.Element {
        const style: React.CSSProperties = {
            width: '100%',
            height: this.state.height ? `${this.state.height}px` : '300px', // Default height if not provided
        };

        return (
            <div className={videoPlayerStyles.container} style={{ position: 'relative' }}>
                {this.state.loaded ? (
                    <video autoPlay={this.state.autoPlay} controls muted style={style}>
                        <source src={this.state.url} type='video/mp4' />
                    </video>
                ) : (
                    <div style={{ textAlign: 'center', height: style.height }}>
                        <div className={styles.text_blue_label_small}>
                            Saving video recording.<br/>Playback will be available shortly...
                        </div>
                        <div>
                            <img style={{ width: '40%' }} src="https://firebasestorage.googleapis.com/v0/b/dev-bespoken-tools.appspot.com/o/loading_llama.gif?alt=media&token=3fb20869-1391-486c-bb51-70953850f046" />
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private async _waitForVideo(url: string): Promise<void> {
        let loaded = false
        while (!loaded) {
            loaded = await this._fetchVideo(url)
            await Util.sleep(1000)

        }
        this.setState({ loaded: true })
    }
    private async _fetchVideo (url: string): Promise<boolean> {
        const method = "GET"

        const response = await fetch(url, { method, headers: {} });
        if (response.status === 404) {
            return false
        }
        return true
    }
}

export { VideoPlayerComponent }