import { chain, defaultTo, get, startsWith } from "lodash";
import * as React from "react";
import { Provider } from "react-redux";
import { EnterHook, IndexRoute, LeaveHook, Redirect, RedirectFunction, Route, RouteProps, Router, RouterState } from "react-router";
import { replace } from "react-router-redux";
import { fetchAppSettings } from "../actions/context";
import { setLoading } from "../actions/loading";
import { fetchOrganization } from "../actions/organization";
import { fetchUser } from "../actions/user";
import RenewSubscription from "../components/RenewSubscription/RenewSubscription";
import TrialExpired from "../components/TrialExpired/TrialExpired";
import { PlanIdTypes } from "../constants/stripe";
import { Dashboard } from "../frames/Dashboard";
import EmailVerify from "../frames/EmailVerify/EmailVerify";
import Login from "../frames/Login";
import { BillingPage } from "../pages/billing/BillingPage";
import { ComponentsShowcasePage } from "../pages/components-showcase/ComponentsShowcasePage";
import ComponentsPage from "../pages/ComponentsPage";
import MarketplaceFormPage from "../pages/MarketplaceFormPage";
import MonitoringHistoryPage from "../pages/monitoring/MonitoringHistoryPage";
import TestRunDrillDownPage from "../pages/monitoring/TestRunDrillDownPage";
import MyAccountPageV2 from "../pages/MyAccountPageV2";
import NotFoundPage from "../pages/NotFoundPage";
import { OrganizationPage } from "../pages/organization/OrganizationPage";
import PluginPlayerPage from "../pages/plugins/PluginPlayerPage";
import SourcePage from "../pages/source/SourcePage";
import { SourcesPage } from "../pages/sources/SourcesPage";
import SSOPage from "../pages/SSOPage";
import VirtualDeviceManager from "../pages/virtual-device-manager/virtual-device-manager";
import { State } from "../reducers";
import { ContextState } from "../reducers/context";
import { OrganizationState, SelectedOrganization } from "../reducers/organization";
import { UserState } from "../reducers/user";
import { PAYMENT_ENABLED } from "../services/subscription-plan";
import { wrapCallbackAsAsync } from "../utils/ReactHelpers";
import sleep from "../utils/sleep";
import { LangingPage } from "./LandingPage/LandingPage";
import { TokenLoginPage } from "./Login/TokenLogin";
import { LoadingPage } from "./LoadingPage/LoadingPage";
import IbmLogin from "./Login/IbmLogin";
import IbmRedirect from "./Login/IbmRedirect";
import LoginPage from "./Login/Login";
import { LogoutPage } from "./Logout/IbmLogout";
import MemberSingup from "./member-singup/member-singup";
import NeedsActivation from "./NeedsActivation/NeedsActivation";
import Survey from "./Survey/Survey";

class Root extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // console.log("Root: componentDidMount");
    }

    componentWillUnmount() {
        // console.log("Root:componentWillUnmount");
    }

    onEnterDashboard: EnterHook = (nextState: RouterState, replace: RedirectFunction) => {

    }

    verifyUserAndOrganization: EnterHook = (nextState: RouterState, replace: RedirectFunction) => {
        const contextState: ContextState = this.props.store.getState().context;
        const userState: UserState = this.props.store.getState().user;
        const organizationState: OrganizationState = this.props.store.getState().organization;

        const { authUser } = contextState;
        const { currentUser } = userState;
        const { selectedOrganization } = organizationState;

        const isTrialTimeExpired = selectedOrganization?.subscription?.isTrialTimeExpired || false;
        const isSubscriptionExpired = selectedOrganization?.subscription?.isSubscriptionExpired || false;
        const requiresActivation = selectedOrganization?.requiresActivation || currentUser?.userRequiresActivation;

        if (!authUser) {
            const lastURL = this.props.store.getState().routing.locationBeforeTransitions;
            localStorage.setItem("redirectToURL", lastURL?.pathname);
            replace("/login");
        } else if (!authUser.emailVerified && !currentUser?.skipEmailVerification) {
            replace("/verify");
        } else if (!currentUser?.surveyCompleted) {
            replace("/survey");
        } else if (requiresActivation) {
            replace("/needsActivation");
        } else if (isSubscriptionExpired) {
            replace("/renewSubscription");
        } else if (isTrialTimeExpired) {
            replace("/trialExpired");
        }
    }

    setLoading(show: boolean) {
        this.props.store.dispatch(setLoading(show));
    }

    renderRoutes = () => {
        // const organizationState: OrganizationState = this.props.store.getState().organization;
        // const { selectedOrganization } = organizationState;
        // const enableMonitoringHistory = selectedOrganization && selectedOrganization.planId !== PlanIdTypes.STARTUP;
        // const { ibmUser = false } = selectedOrganization || {};
        // console.log("renderRoutes", enableMonitoringHistory, ibmUser);
        return (
            <Route path="/"
                component={Dashboard}
                onEnter={this.onEnterDashboard}
            >
                <Route path="/lunacy-components" component={ComponentsShowcasePage} />
                <Route path="/trialExpired"
                    component={TrialExpired}
                    onEnter={() => {
                        const organizationState: OrganizationState = this.props.store.getState().organization;
                        const { selectedOrganization } = organizationState;

                        if (!selectedOrganization) {
                            this.props.store.dispatch(replace("/login"));
                        } else if (!selectedOrganization?.subscription?.isTrialTimeExpired) {
                            this.props.store.dispatch(replace("/skills"));
                        }
                    }}
                />
                <Route path="/renewSubscription"
                    component={RenewSubscription}
                    onEnter={() => {
                        const organizationState: OrganizationState = this.props.store.getState().organization;
                        const { selectedOrganization } = organizationState;

                        if (!selectedOrganization) {
                            this.props.store.dispatch(replace("/login"));
                        } else if (!selectedOrganization?.subscription?.isSubscriptionExpired) {
                            this.props.store.dispatch(replace("/skills"));
                        }
                    }} />
                <Route path="/organization" component={OrganizationPage} onEnter={(nextState: RouterState, replace: RedirectFunction) => {
                    const selectedOrganization = defaultTo((this?.props?.store?.getState() as State.All)?.organization?.selectedOrganization, {} as SelectedOrganization);
                    const canManageMemebers = selectedOrganization.isAdmin || selectedOrganization.isOwner;

                    if (!(PAYMENT_ENABLED && canManageMemebers)) { return this.props.store.dispatch(replace("/notFound")); }

                    this.verifyUserAndOrganization(nextState, replace);
                }} />
                <Route path="/account" component={MyAccountPageV2} />
                <Route path="/token/:token" component={TokenLoginPage} />
                <Route path="/virtualdevice"
                    component={VirtualDeviceManager}
                    onEnter={this.verifyUserAndOrganization}
                />
                <Route path="/history" component={MonitoringHistoryPage} />
                <Route path="/history/:projectId" component={MonitoringHistoryPage} />
                <Route path="/history/testruns/:runId" component={TestRunDrillDownPage} />
                <Route path="/plugins/:pluginId" component={PluginPlayerPage} />
                <Route path="/skills"
                    component={SourcesPage}
                    onEnter={this.verifyUserAndOrganization}
                />
                <Route path="/skills/:sourceId" component={SourcePage} />
                <Route key={"billing-page-route"} path="/billing" component={BillingPage} />
                <Route path="/notFound" component={NotFoundPage} />
                <Route path="*" component={NotFoundPage} />
            </Route>);
    }

    render() {
        return (
            <Provider store={this.props.store}>
                <Router history={this.props.history}>
                    <Route path="/components">
                        {process.env.NODE_ENV !== "production" && <IndexRoute component={ComponentsPage} />}
                    </Route>
                    <Route path="/login" component={Login}>
                        <IndexRoute component={LoginPage} />
                    </Route>
                    <Route path="/sso"
                        component={SSOPage}
                        onEnter={() => {
                        }}
                    />
                    <Route path="/verify"
                        component={EmailVerify}
                        onEnter={() => {
                            const userState: UserState = this.props.store.getState().user;
                            const contextState: ContextState = this.props.store.getState().context;
                            const { currentUser } = userState;
                            const { authUser } = contextState;

                            if (!currentUser) {
                                this.props.store.dispatch(replace("/login"));
                            } else if (authUser?.emailVerified || currentUser?.skipEmailVerification) {
                                this.props.store.dispatch(replace("/skills"));
                            }
                        }}
                    />
                    <Route path="/survey"
                        component={Survey}
                        onEnter={() => {
                            const contextState: ContextState = this.props.store.getState().context;
                            const userState: UserState = this.props.store.getState().user;
                            const { authUser } = contextState;
                            const { currentUser } = userState;

                            if (!authUser) {
                                this.props.store.dispatch(replace("/login"));
                            } else if (currentUser?.surveyCompleted) {
                                this.props.store.dispatch(replace("/skills"));
                            }
                        }}
                    />
                    <Route path="/needsActivation"
                        component={NeedsActivation}
                        onEnter={() => {
                            const userState: UserState = this.props.store.getState().user;
                            const organizationState: OrganizationState = this.props.store.getState().organization;
                            const { currentUser } = userState;
                            const { selectedOrganization } = organizationState;

                            const requiresActivation = selectedOrganization?.requiresActivation || currentUser?.userRequiresActivation;

                            if (!currentUser) {
                                this.props.store.dispatch(replace("/login"));
                            } else if (!requiresActivation) {
                                this.props.store.dispatch(replace("/skills"));
                            }
                        }}
                    />
                    {/* Do not need session */}
                    <Route path="/aws-marketplace" component={MarketplaceFormPage} />
                    <Route path="/ibm" component={Login}>
                        <IndexRoute component={IbmLogin} />
                    </Route>
                    <Route path="/ibm-redirect" component={Login}>
                        <IndexRoute component={IbmRedirect} />
                    </Route>
                    <Route path="/ibm-logout" component={LogoutPage} />
                    <Route path="/member-singup" component={MemberSingup} />
                    <Route path="/member-signup" component={MemberSingup} />

                    <Route path="/organizations/:organizationId">
                        <Route path="*" component={LangingPage} />
                    </Route>

                    {this.renderRoutes()}
                </Router>
            </Provider>
        );
    }
}

export default Root;
