import { isEqual, pick } from "lodash";
import * as React from "react";

const Pizzicato = require("pizzicato");
const DEFAULT_SLIDER_LENGTH = 100;

interface AudioFileProps {
    url: string;
    time?: number;
    newStartAudioValue?: number;

    onFileLoading?: () => void;
    onFileLoaded?: () => void;
    onPlaying?: () => void;
    onPaused?: () => void;
    onEnd?: () => void;
    onUpdateTime?: (seconds: number, percentage: number, duration: number) => void;
}

interface AudioFileState {
    url: string;

    time: number;
    loading: boolean;
    audio?: any;
    duration?: number;
    scaleFactor?: number;
    elapsedTime: number;
}

interface PizzicatoSound {
    play: (delay?: number, when?: number) => void;
    pause: () => void;
    stop: () => void;
    on: (event: string, callback: () => void) => void;
    getSourceNode: () => { buffer?: { duration: number } };
    playing: boolean;
    paused: boolean;
    lastTimePlayed: number;
    sourceNode: { buffer?: { length: number } };
}

export class AudioFile extends React.Component<AudioFileProps, AudioFileState> {

    static defaultProps: AudioFileProps = {
        url: undefined,
        time: 0,
        newStartAudioValue: 0
    }

    unmounted = false;

    constructor(props: AudioFileProps) {
        super(props)

        this.state = {
            url: props.url,
            time: 0,
            loading: false,
            elapsedTime: 0
        }

        this.initializeLoopForUpdatingElapsedTime()
    }

    componentWillUnmount(): void {
        this.unmounted = true;
    }

    initializeLoopForUpdatingElapsedTime() {
        let lastTime = 0.0;
        let accumulatedTime = 0;

        const updateTimeElapsed = (now: number) => {
            // stop if unmounted
            if (this.unmounted) {
                return
            }

            if (!this.state.audio) {
                window.requestAnimationFrame(updateTimeElapsed)
                return
            }

            accumulatedTime += now - lastTime
            if (accumulatedTime >= 10) {
                accumulatedTime = 0.0
                this.setState({ elapsedTime: this.calculateTimeElapsedWhenPlaying() })
            }

            lastTime = now
            window.requestAnimationFrame(updateTimeElapsed)
        }

        window.requestAnimationFrame(updateTimeElapsed)
    }

    calculateTimeElapsedWhenPlaying(): number {
        const audio = this.state.audio as PizzicatoSound;
        if (!audio.playing) {
            return this.state.elapsedTime
        }

        try {
            var elapsedTime = Pizzicato.context.currentTime - audio.lastTimePlayed;

            // If we are using a buffer node - potentially in loop mode - we need to
            // know where to re-start the sound independently of the loop it is in.
            if (audio.sourceNode.buffer)
                return elapsedTime % (audio.sourceNode.buffer.length / Pizzicato.context.sampleRate);
            else
                return elapsedTime;
        } catch (error) {
            return -1
        }
    }

    async preloadAudio(): Promise<void> {
        const { url } = this.state
        const { onFileLoading, onFileLoaded, onPlaying, onPaused, onEnd } = this.props

        if (this.state.audio) {
            const audio = this.state.audio as PizzicatoSound;
            if (audio.stop && audio.playing) {
                audio.stop()
            }
        }

        onFileLoading && onFileLoading()
        await new Promise<void>((resolve) => {
            this.setState({ loading: true }, resolve)
        })
        await new Promise<void>((resolve) => {
            const audio = new Pizzicato.Sound(url, (error: Error | null) => {
                if (error) {
                    console.error("Error loading sound:", error);
                    this.setState({ loading: false });
                    onFileLoaded && onFileLoaded();
                    resolve();
                    return;
                }

                const duration = audio.getSourceNode().buffer?.duration
                const scaleFactor = duration / DEFAULT_SLIDER_LENGTH;

                audio.stop()
                audio.on('play', () => onPlaying && onPlaying())
                audio.on('pause', () => onPaused && onPaused())
                audio.on('end', () => {
                    audio.stop()
                    this.setState({ elapsedTime: duration, time: 0 })
                    onEnd && onEnd()
                })

                this.setState({ audio, duration, scaleFactor }, () => {
                    setTimeout(resolve, 10000)
                })
            })
        })
        await new Promise<void>((resolve) => this.setState({ loading: false, time: 0, elapsedTime: 0 }, resolve))
        onFileLoaded && onFileLoaded()
    }

    play() {
        const { scaleFactor, time, audio } = this.state
        const trackPosition = scaleFactor * time;

        const pizzicatoAudio = audio as PizzicatoSound;

        if (pizzicatoAudio && pizzicatoAudio.play && pizzicatoAudio.paused) {
            pizzicatoAudio.play(0, trackPosition);
            return
        }

        if (pizzicatoAudio && pizzicatoAudio.stop && pizzicatoAudio.playing) {
            pizzicatoAudio.stop()
        }

        pizzicatoAudio.play(0, trackPosition);
    }

    pause() {
        const pizzicatoAudio = this.state.audio as PizzicatoSound;
        pizzicatoAudio.pause();
        this.setState({ time: this.state.elapsedTime / this.state.scaleFactor })
    }

    get time(): number {
        return this.state.time
    }

    set time(time: number) {
        const pizzicatoAudio = this.state.audio as PizzicatoSound;
        if (pizzicatoAudio.playing) {
            pizzicatoAudio.pause()
            pizzicatoAudio.play(0, time * this.state.scaleFactor)
        }
        this.setState({ time })
    }

    get loading() {
        return this.state.loading
    }

    async componentWillMount(): Promise<void> {
        await this.preloadAudio()
    }

    componentDidUpdate(prevProps: Readonly<AudioFileProps>, prevState: Readonly<AudioFileState>, prevContext: any): void {
        if (prevState.elapsedTime !== this.state.elapsedTime) {
            const { duration, elapsedTime, scaleFactor } = this.state
            this.props.onUpdateTime && this.props.onUpdateTime(elapsedTime, elapsedTime / scaleFactor, duration)
        }
    }

    async componentWillReceiveProps(nextProps: Readonly<AudioFileProps>, nextContext: any): Promise<void> {
        const properties = ["url", "newStartAudioValue"]
        if (isEqual(pick(this.props, properties), pick(nextProps, properties))) {
            return
        }

        const urlHasChanged = !isEqual(pick(this.props, "url"), pick(nextProps, "url"))
        const newStartAudioValueHasChanged = !isEqual(pick(this.props, "newStartAudioValue"), pick(nextProps, "newStartAudioValue"))

        if (urlHasChanged) {
            this.setState({ url: nextProps.url, }, async () => await this.preloadAudio())
        }

        if (newStartAudioValueHasChanged) {
            const pizzicatoAudio = this.state.audio as PizzicatoSound;
            this.setState({ time: nextProps.newStartAudioValue })
            if (pizzicatoAudio.playing) {
                pizzicatoAudio.pause()
                pizzicatoAudio.play(0, nextProps.newStartAudioValue * this.state.scaleFactor)
            } else {
                const { duration, scaleFactor } = this.state
                this.props.onUpdateTime && this.props.onUpdateTime(nextProps.newStartAudioValue * scaleFactor, nextProps.newStartAudioValue, duration)
            }
        }
    }

    render(): null {
        return null;
    }
}