import * as React from "react";
const Styles = require("./styles.scss");

const ICONS: Record<string, { src: string; alt: string }> = {
    alexa: {
        src: "/assets/platform_icons/alexa.svg",
        alt: "Alexa"
    },
    google: {
        src: "/assets/platform_icons/google.svg",
        alt: "Google"
    },
    twilio: {
        src: "/assets/platform_icons/twilio.png",
        alt: "Twilio"
    },
    sms: {
        src: "/assets/platform_icons/sms.png",
        alt: "SMS"
    },
    whatsapp: {
        src: "/assets/platform_icons/whatsapp.svg",
        alt: "Whatsapp"
    },
    webchat: {
        src: "/assets/platform_icons/webchat.png",
        alt: "Webchat"
    },
    watson: {
        src: "/assets/platform_icons/watson.png",
        alt: "IBM Watson"
    },
    voiceflow: {
        src: "/assets/platform_icons/voiceflow.png",
        alt: "Voiceflow"
    },
    lex: {
        src: "/assets/platform_icons/lex.webp",
        alt: "Lex"
    },
    phone: {
        src: "/assets/platform_icons/phone.png",
        alt: "Phone"
    },
    email: {
        src: "/assets/platform_icons/email.png",
        alt: "Email"
    },
    undefined: {
        src: "/assets/platform_icons/undefined.svg",
        alt: "Unknown platform"
    }
};

export type PlatformNames = keyof typeof ICONS;

interface PlatformIconProps {
    platform: PlatformNames;
}

export const PlatformIcon = ({ platform }: PlatformIconProps) => {
    const { src, alt } = ICONS[platform];
    return <img className={Styles.type_icon} src={src} alt={alt} />;
};

export const PLATFORM_ICONS = Object.keys(ICONS) as PlatformNames[];