import { trim } from "lodash";

export const UTILS_API_URL = process.env.UTILS_API_URL;

export default class UtilsServer {

  static async generateBespokenLead(firstNameCTA: string, lastNameCTA: string,
    emailNameCTA: string, companyCTA: string) {
    if (UTILS_API_URL) {
      const payload = JSON.stringify({
        firstNameCTA,
        lastNameCTA,
        emailNameCTA,
        companyCTA,
        page: "dashboard",
        providers: ["salesforce", "mailchimp"],
      });
      const generateLeadUrl = trim(process.env.UTILS_API_URL, "/") + "/utils-server/generateBespokenLead";

      fetch(generateLeadUrl, {
        method: "POST",
        headers: {

          "Content-Type": "application/json",
        },
        body: payload
      });
    }
  }
}