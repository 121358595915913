import * as cn from "classnames";
import * as React from "react";
import { Button, IconButton } from "react-toolbox/lib/button";
import BespokenAddIcon from "../../../../assets/bespoken_add_icon.svg";
import BespokenCopyIcon from "../../../../assets/bespoken_copy_icon.svg";
import BespokenRefreshIcon from "../../../../assets/bespoken_refresh_icon.svg";

import { chain, includes } from "lodash";
import { connect } from "react-redux";
import Input from "react-toolbox/lib/input";
import { setLoading } from "../../../actions/loading";
import { createVirtualDevice, createVirtualDeviceWithOAuth, fetchOrganization, fetchVirtualDevices, refreshVirtualDeviceWithOAuthLink } from "../../../actions/organization";
import { sourceType } from "../../../constants";
import Source from "../../../models/source";
import { State } from "../../../reducers";
import { VirtualDevices, VirutalDevicePlatform, VirtualDeviceRequest } from "../../../reducers/organization";
import { wrapCallbackAsAsync } from "../../../utils/ReactHelpers";
import { ERROR } from "../../lunacy/debug-panel/DebugPanel";
import { VirtualDeviceDropdown } from "../../VirtualDeviceDropdown/VirtualDeviceDropdown";
import { VoiceDropdown, LocaleDropdown } from "../../../components/lunacy";

const theme = require("../../../themes/autosuggest.scss");
const Styles = require("./source-header.scss");
const validationStyle = require("../../validation/ValidationParentComponentStyle.scss");
const bespokenButton = require("../../../themes/bespoken_button.scss");
const bespokenDropdown = require("../../../themes/bespoken_dropdown.scss");
const bespokenInput = require("../../../themes/bespoken_input.scss");

interface DispatchToProps {
    setLoading: (value: boolean) => any;

    fetchOrganization: () => Promise<any>;

    createVirtualDevice: (request: VirtualDeviceRequest) => Promise<any>;
    createVirtualDeviceOAuth: (request: VirtualDeviceRequest) => Promise<{ url: string }>;
    refreshVirtualDevice: (virtualDeviceRequest: VirtualDeviceRequest) => Promise<any>;

    fetchVirtualDevices: () => Promise<any>;
}
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        setLoading: (value: boolean) => dispatch(setLoading(value)),

        fetchOrganization: async () => wrapCallbackAsAsync(handle => dispatch(fetchOrganization('current', handle))),

        createVirtualDevice: async (request) => wrapCallbackAsAsync(handle => dispatch(createVirtualDevice(request, handle))),
        createVirtualDeviceOAuth: async (request) => wrapCallbackAsAsync(handle => dispatch(createVirtualDeviceWithOAuth(request, handle))) as Promise<{ url: string }>,
        refreshVirtualDevice: async (virtualDeviceRequest) => wrapCallbackAsAsync(handle => dispatch(refreshVirtualDeviceWithOAuthLink(virtualDeviceRequest, handle))),

        fetchVirtualDevices: async () => wrapCallbackAsAsync(handle => dispatch(fetchVirtualDevices(handle))),
    };
}

interface StateToProps {
    allVirtualDevices?: VirtualDevices[];
    defaultPlatformId: string;
    appSettings?: any;
}
function mapStateToProps(state: State.All): StateToProps {
    const allVirtualDevices = chain(state?.organization?.selectedOrganization?.virtualDevices)
        .filter(({ status }) => status !== "disabled")
        .value()

    const defaultPlatformId = chain(state?.organization?.selectedOrganization?.subscription?.platforms)
        .uniq()
        .sort()
        .first()
        .value()


    return {
        allVirtualDevices,
        defaultPlatformId,
        appSettings: state.context?.appSettings,
    };
}

interface ExposedProps {
    source: Source;
    virtualDevices?: any[];
    updateConfig: (property: string, value: string) => any;
    saveSource: () => any;
    hasUnsavedChanges: boolean;
    showSnackbarMessage: (message: string) => any;
}
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface ComponentProps extends DispatchToProps, StateToProps, ExposedProps { }

interface ComponentState {
}

class SourceHeaderComponent extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = {
        };
    }

    async componentDidMount() {
       await this.props?.fetchVirtualDevices()
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.source) {
            this.setState((prevState: any) => ({
                ...prevState,
                sourceName: nextProps.source.name,
            }));
        }
    }

    handleTypeChange = (platform: string) => {
        // console.log('handleTypeChange')
        this.props.updateConfig('platform', platform)
            .then(() => {
                // if (this.props.hasUnsavedChanges) {
                //     this.props.saveSource()
                // }
            });
    }

    handleLocaleChange = (locale: string) => {
        // console.log('handleLocaleChange')

        this.props.updateConfig('locale', locale)
            .then(() => {
                // if (this.props.hasUnsavedChanges) {
                //     this.props.saveSource()
                // }
            });
    }

    handleVoiceChange = (voice: string) => {
        // console.log('handleVoiceChange')

        this.props.updateConfig('voiceId', voice)
            .then(() => {
                // if (this.props.hasUnsavedChanges) {
                //     this.props.saveSource()
                // }
            });
    }

    handleUrlChange = (url: string) => {
        this.props.updateConfig('virtualDeviceConfig.url', url);
    }

    handleTargetEmailChange = (email: string) => {
        this.props.updateConfig('virtualDeviceConfig.targetEmail', email);
    }

    handleUpdateVirtualDevice = (props: any) => {
        // console.log('handleUpdateVirtualDevice')

        const { token } = props;
        this.props.updateConfig('virtualDeviceToken', token)
            .then(() => {
                // if (this.props.hasUnsavedChanges) {
                //     this.props.saveSource()
                // }
            });
    }

    handlePhoneNumberChange = (phoneNumber: any) => {
        this.props.updateConfig('phoneNumber', phoneNumber);
    }

    handlePhoneNumberBlur = () => {
        // if (this.props.hasUnsavedChanges) {
        //     this.props.saveSource()
        // }
    }

    handleCopyToClipBoard = () => {
        const textArea = document.createElement("textarea");
        textArea.value = this.props.source?.config?.virtualDeviceToken;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        this.props.showSnackbarMessage(`The token for ${this.props.source?.name} has been copied to the clipboard`);
        try {
            document.execCommand("copy");
        } catch (err) {
            console.error("Oops, unable to copy", err);
        }
    }

    render() {
        const platform = this.props.source?.config?.platform || this.props?.defaultPlatformId;
        const locale = this.props.source?.config?.locale || "en-US";
        const voice = this.props.source?.config?.voiceId || undefined;
        const displayLocaleAndVoice = ["sms", "whatsapp", "webchat", "watson", "email", "voiceflow", "lex"].indexOf(platform) === -1
        const displayWebchatUrl = ["webchat"].indexOf(platform) !== -1
        const displayEmailTargetEmail = ["email"].indexOf(platform) !== -1
        const showLinkCondition = chain(this.props.allVirtualDevices)
            .filter(it => it.platform === platform)
            .isEmpty()
            .value();

        const url = this.props.source?.config?.virtualDeviceConfig?.url || this.props.source?.config?.extraParameters?.url || "";
        const targetEmail = this.props.source?.config?.virtualDeviceConfig?.targetEmail || "";

        if (!this.props.source) return <div />;
        return (
            <div className={theme.title_container} data-intercom-target="MainSettings">
                <div className={validationStyle.dropdown_container}>
                    <div>
                        {displayEmailTargetEmail &&
                            <div
                                className={Styles.container_input_url}>
                                <span>Target email</span>
                                <Input
                                    className={Styles.input_url}
                                    theme={bespokenInput}
                                    floating={false}
                                    value={targetEmail}
                                    onChange={(val: string) => this.handleTargetEmailChange(val)}
                                />
                            </div>
                        }
                        {displayWebchatUrl &&
                            <div
                                className={Styles.container_input_url}>
                                <span>Url</span>
                                <Input
                                    className={Styles.input_url}
                                    theme={bespokenInput}
                                    floating={false}
                                    value={url}
                                    onChange={(val: string) => this.handleUrlChange(val)}
                                />
                            </div>
                        }
                        {displayLocaleAndVoice &&
                            <div>
                                <span>Locale</span>
                                <LocaleDropdown
                                    theme={bespokenDropdown}
                                    className={cn(bespokenDropdown.template, "dropdown-locale-language")}
                                    sourceType={platform}
                                    locale={locale}
                                    voice={voice}
                                    handleLocaleChange={this.handleLocaleChange}
                                    handleVoiceChange={this.handleVoiceChange}
                                    appSettings={this.props.appSettings}
                                />
                            </div>
                        }
                        {displayLocaleAndVoice &&
                            <div>
                                <span>Voice</span>
                                <VoiceDropdown
                                    theme={bespokenDropdown}
                                    className={bespokenDropdown.template}
                                    sourceType={platform}
                                    locale={locale}
                                    voice={voice}
                                    handleVoiceChange={this.handleVoiceChange}
                                    data-id="dropdown-voice"
                                    appSettings={this.props.appSettings}
                                />
                            </div>
                        }
                        {
                            ["phone", "twilio", "sms", "whatsapp"].indexOf(platform) >= 0 &&
                            (
                                <div className={validationStyle.twilio} data-id="twilio_phone_number">
                                    <span>Phone Number</span>
                                    <Input type={"text"}
                                        theme={bespokenInput}
                                        value={this.props.source?.config?.phoneNumber || ""}
                                        onChange={this.handlePhoneNumberChange}
                                        onBlur={this.handlePhoneNumberBlur}
                                    />
                                </div>
                            )
                        }
                        {
                            showLinkCondition ?
                                (
                                    <div className={validationStyle.create_vd_container}>
                                        <Button
                                            data-id="create-select-virtualdevice"
                                            icon={<BespokenAddIcon />}
                                            theme={bespokenButton}
                                            className={`${bespokenButton.large} ${bespokenButton.right_icon} ${validationStyle.create_virtual_device}`}
                                            accent={true}
                                            onClick={async () => {
                                                try {
                                                    const platform = this.props.source?.config?.platform || this.props?.defaultPlatformId;
                                                    this.props.setLoading(true)

                                                    if (includes(["google", "alexa"], platform)) {
                                                        const returnUrl = `${window.location.origin}${window.location.pathname}`
                                                        const consentScreenLink = await this.props.createVirtualDeviceOAuth({ platform, returnUrl })
                                                        location.href = consentScreenLink?.url
                                                        return
                                                    }

                                                    await this.props.createVirtualDevice({ platform })
                                                    await this.props.fetchVirtualDevices()
                                                } catch (err) {
                                                    ERROR('Error creating virtual device', err)
                                                } finally {
                                                    this.props.setLoading(false)
                                                }
                                            }}
                                        >
                                            Create Virtual Device
                                        </Button>
                                    </div>
                                ) :
                                (
                                    <div className={validationStyle.create_vd_container}>
                                        <div className={validationStyle.virtual_devices} data-id="create-select-virtualdevice">
                                            <span>Virtual Device</span>
                                            <VirtualDeviceDropdown
                                                platform={this.props.source?.config?.platform}
                                                selectedValue={this.props.source?.config?.virtualDeviceToken}
                                                selectFirstIfNotFound={true}
                                                onValueChanged={({ value: token }) => this.handleUpdateVirtualDevice({ token })}
                                            />
                                        </div>
                                        <div className={validationStyle.actions}>
                                            <a title={"Copy"} className={validationStyle.copy_token}
                                                onClick={this.handleCopyToClipBoard}>
                                                <IconButton data-id="copy-token-action" icon={<BespokenCopyIcon />} ripple={false} />
                                            </a>
                                            {
                                                [sourceType.alexa, sourceType.google].indexOf(platform) !== -1 &&
                                                (
                                                    <a title={"Refresh permissions"}
                                                        className={validationStyle.refresh_token}
                                                        onClick={async () => {
                                                            const platform = this.props.source?.config?.platform || this.props?.defaultPlatformId;
                                                            const token = this.props.source?.config?.virtualDeviceToken;
                                                            this.props.setLoading(true)

                                                            try {
                                                                const returnUrl = `${window.location.origin}${window.location.pathname}`
                                                                const consentScreenLink = await this.props?.refreshVirtualDevice({ token, returnUrl })
                                                                location.href = consentScreenLink?.url
                                                            } catch (err) {
                                                                ERROR('Error creating virtual device', err)
                                                            }

                                                            this.props.setLoading(false)
                                                        }}
                                                    >
                                                        <IconButton data-id="refresh-permissions-action"
                                                            icon={<BespokenRefreshIcon />} ripple={false} />
                                                    </a>
                                                )
                                            }
                                        </div>
                                    </div>
                                )

                        }
                    </div>
                </div>
            </div>
        );
    }

}
export const SourceHeader = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(SourceHeaderComponent);

