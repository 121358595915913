import * as React from "react";
import * as cn from "classnames";
import { Slider } from "react-toolbox/lib/slider";
import BespokenPlayAudio from "../../../assets/bespoken_run_all_icon.svg";
import BespokenPauseAudio from "../../../assets/bespoken_pause_icon.svg";
import BespokenDownloadIcon from "../../../assets/bespoken_download_icon_2.svg";
import { AudioFile } from "../AudioPlayer/AudioFile";
import { DownloadFile } from "../AudioPlayer/DownloadFile";

const Styles = require('./InlineIVRPlayerStyle.scss');
const TrackSliderTheme = require("../AudioPlayer/baseTrackSliderTheme.scss");

interface InlineIVRPlayerProps {
  conversationId: string;
}

interface InlineIVRPlayerState {
  url?: string;
  sliderValue: number;
  newStartAudioValue: number;
  isDraggingSlider: boolean;
  paused: boolean;
  loadingFile: boolean;
  secondsElapsed: number;
  disable: boolean;
  isAudioLoaded: boolean;
}

export class InlineIVRPlayer extends React.Component<InlineIVRPlayerProps, InlineIVRPlayerState> {
  private audioFile: AudioFile | null = null;
  private _isMounted: boolean = false;

  constructor(props: InlineIVRPlayerProps) {
    super(props);
    this.state = {
      url: undefined,
      sliderValue: 0,
      newStartAudioValue: 0,
      isDraggingSlider: false,
      paused: true,
      loadingFile: true,
      secondsElapsed: 0,
      disable: false,
      isAudioLoaded: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadAudioFile();
  }

  componentDidUpdate(prevProps: InlineIVRPlayerProps) {
    if (prevProps.conversationId !== this.props.conversationId) {
      this.loadAudioFile();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.audioFile) {
      this.audioFile.pause();
    }
  }

  loadAudioFile = async () => {
    const { conversationId } = this.props;
    if (!conversationId) return;

    const VIRTUAL_DEVICE_TWILIO_URL = process.env.VIRTUAL_DEVICE_TWILIO_URL;

    try {
      const response = await fetch(`${VIRTUAL_DEVICE_TWILIO_URL}/twilio_recording?callSid=${conversationId}`);
      const { recordingUrl: url } = await response.json();
      this.safeSetState({ url, isAudioLoaded: true });
    } catch (err) {
      console.error("Error loading audio file:", err);
      this.safeSetState({ isAudioLoaded: false });
    }
  }

  safeSetState = (state: Partial<InlineIVRPlayerState>, callback?: () => void) => {
    if (this._isMounted) {
      this.setState(state as any, callback);
    }
  }

  formatHMS(value: number) {
    const sec = parseInt(value.toString(), 10);
    let hours: string | number = Math.floor(sec / 3600);
    let minutes: string | number = Math.floor((sec - (hours * 3600)) / 60);
    let seconds: string | number = sec - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }

    if (hours === '00') {
      return minutes + ':' + seconds;
    } else {
      return hours + ':' + minutes + ':' + seconds;
    }
  }

  render() {
    const {
      url,
      sliderValue,
      secondsElapsed,
      newStartAudioValue,
      paused,
      loadingFile,
      isDraggingSlider,
      disable,
      isAudioLoaded
    } = this.state;
    const disableActions = disable || loadingFile || !isAudioLoaded;

    if (!isAudioLoaded) {
      return <div className={Styles.loading}>Loading audio...</div>;
    }

    return (
      <div className={cn(Styles.audio_player, loadingFile ? Styles.hide : undefined)}>
        {url && (
          <AudioFile
            url={url}
            newStartAudioValue={newStartAudioValue}
            ref={(ref) => this.audioFile = ref}
            onFileLoading={() => this.safeSetState({ loadingFile: true })}
            onFileLoaded={() => this.safeSetState({ loadingFile: false })}
            onPlaying={() => this.safeSetState({ paused: false })}
            onPaused={() => this.safeSetState({ paused: true })}
            onEnd={() => this.safeSetState({ paused: true })}
            onUpdateTime={(secondsElapsed, sliderValue) => {
              if (!this.state.isDraggingSlider) {
                this.safeSetState({ sliderValue, secondsElapsed });
              }
            }}
          />
        )}
        <div className={cn(Styles.controls, disableActions ? Styles.hide : undefined)}>
          <div className={Styles.play}>
            {paused ? (
              <BespokenPlayAudio
                onClick={() => { if (!disableActions && this.audioFile) { this.audioFile.play(); } }}
                className={cn(disableActions ? Styles.disable : undefined)}
              />
            ) : (
              <BespokenPauseAudio
                onClick={() => { if (!disableActions && this.audioFile) { this.audioFile.pause(); } }}
                className={cn(disableActions ? Styles.disable : undefined)}
              />
            )}
          </div>
          <div>
            <Slider
              className={Styles.slider}
              disabled={disableActions}
              theme={TrackSliderTheme}
              value={sliderValue}
              onChange={(sliderValue: number) => {
                this.safeSetState({ sliderValue: Math.max(sliderValue, 1) });
              }}
              onDragStart={() => this.safeSetState({ isDraggingSlider: true })}
              onDragStop={() => this.safeSetState({ newStartAudioValue: sliderValue, isDraggingSlider: false })}
            />
          </div>
          <div><span>{this.formatHMS(secondsElapsed)}</span></div>
          <div>
            <BespokenDownloadIcon
              onClick={async () => {
                if (!disableActions && url) {
                  const link = document.createElement('a');
                  link.href = url;
                  link.download = 'audio.mp3';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              }}
              className={cn(disableActions ? Styles.disable : undefined)}
            />
          </div>
        </div>
      </div>
    );
  }
}