import * as cn from "classnames";
import { isEmpty, startsWith } from "lodash";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { connect } from "react-redux";
import { State } from "../../../reducers";
import { WARN } from "../debug-panel/DebugPanel";
import TemplateSVG from "./template-avatar-icon.svg";

const Styles = require("./CircleAvatar.scss");

const DEFAULT_NO_COLOR = "#939598"

interface DispatchToProps { }
function mapDispatchToProps(dispatch: any) { return {} }

interface StateToProps {
}
function mapStateToProps(state: State.All): StateToProps {
    return {
    }
}

interface ExposedProps {
    label: string;
    imageUrl?: string;
    color: string;
    tooltip?: string;
}
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface ComponentProps extends DispatchToProps, StateToProps, ExposedProps { }

interface ComponentState {
    hasImage: boolean;
}

class Component extends React.Component<ComponentProps, ComponentState> {

    static defaultProps: ComponentProps = {
        label: "-",
        imageUrl: undefined,
        color: "red",
        tooltip: ""
    }

    constructor(props: ComponentProps) {
        super(props);
        this.state = {
            hasImage: false
        }
    }

    async componentDidMount(): Promise<void> {
        const hasImage = this.props.imageUrl ? await fetch(this.props?.imageUrl).then(res => startsWith(res?.headers?.get('content-type'), 'image')).catch(err => false) : false
        this.setState({ hasImage })
    }

    async componentDidUpdate(oldProps: Readonly<ComponentProps>, nextContext: any): Promise<void> {
        if (this.props.label === oldProps.label && this.props.imageUrl === oldProps.imageUrl) {
            return
        }

        const hasImage = this.props.imageUrl ? await fetch(this.props?.imageUrl).then(res => startsWith(res?.headers?.get('content-type'), 'image')).catch(err => false) : false

        this.setState({
            hasImage
        })
    }

    render(): false | JSX.Element {
        return (
            <div
                className={cn(Styles.component)}
                title={`${this.props?.tooltip}`}
                ref={ref => {
                    const domNode = ReactDOM.findDOMNode(ref)

                    const svg: SVGElement = domNode?.querySelector('svg')
                    if (isEmpty(svg)) { return }

                    const circle = svg.querySelector('[data-id="circle"]')
                    const circleLinkedImage = svg.querySelector('[data-id="linkedImage"]')
                    const circleClippath = svg.querySelector('[data-id="clip-image"]')
                    const label = svg.querySelector('[data-id="label"]')

                    circle && circle.setAttribute('style', `fill:${this.props.color};`)

                    if (this.state.hasImage) {
                        label && (label.innerHTML = '')
                        circleLinkedImage && circleLinkedImage.setAttribute("xlink:href", this.props?.imageUrl)
                    } else {
                        label && (label.innerHTML = this.props?.label)
                        circleClippath && circleClippath.setAttribute("style", "visibility:hidden")
                    }
                }}>
                <TemplateSVG />
            </div>
        )
    }
}

export const CircleAvatar = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Component);
