import * as React from "react";
import { connect } from "react-redux";
import { Button } from "react-toolbox/lib/button";
import BespokenLogo from "../../../assets/bespoken_logoweb.svg";
import BespokenMountains from "../../../assets/bespoken_mountains.svg";
import BinocularsIcon from "../../../assets/binoculars.svg";
import { setDebouncing } from '../../actions/debounce'
import * as backgroundLeft from "../../../assets/images/bespoken_auth_no_text.jpg";
import {
    AmazonFlowFlag, resetPassword, SendEmailFlag, setAmazonFlow, setLogEmailSentFlag,
    SuccessCallback
} from "../../actions/session";
import { login, singUp, loginWithGoogle } from "../../actions/context";
import { LeftPanel, Loader, RightPanel } from "../../components";
import AuthForm from "../../components/AuthForm/AuthForm";
import User from "../../models/user";
import { State } from "../../reducers";
import auth from "../../services/auth";
import { BespokenModal } from "../../components/Modal/BespokenModal";
import { push } from "react-router-redux";
import { wrapCallbackAsAsync } from "../../utils/ReactHelpers";
import { fetchInternalApi } from "../../services/internal-api";

const style = require("./loginStyles.scss");
const bespokenButton = require("../../themes/bespoken_button.scss");

interface DispatchToProps {
    setDebouncing: (value: boolean) => any;
    loginWithGithub: (redirectStrat?: SuccessCallback) => Promise<User>;
    loginWithAmazon: (accessToken: string, redirectStrat?: SuccessCallback) => Promise<User>;
    setAmazonFlow: (amazonFlow: boolean) => AmazonFlowFlag;
    setSendEmailFlag: (value: boolean) => SendEmailFlag;
    resetPassword: (email: string) => Promise<void>;
    goTo: (path: string) => any;
    login: (email: string, password: string) => Promise<any>;
    signUpWithEmail: (email: string, password: string, confirmPassword: string) => Promise<any>;
    loginWithGoogle: () => Promise<any>;
    fetchAppSettings?: () => Promise<any>;
    fetchOrganization?: () => Promise<any>;
    fetchUser?: () => Promise<any>;

}

interface LoginPageState {
    debouncing: boolean
    error?: any;
    loading?: boolean;
    bannerUrl?: string;
    bannerHtml?: string;
    showLoader?: boolean;
    startupPlanDialogActive?: boolean;
}

interface StateToProps {
    debouncing: boolean;
}

function mapStateToProps (state: State.All): LoginPageState {
    return {
        debouncing: state.debouncing.debouncing
    };
}

const mapDispatchToProps = (dispatch: any) =>
({
    setDebouncing: (value: boolean) => dispatch(setDebouncing(value)),

    setAmazonFlow: function (amazonFlow: boolean): AmazonFlowFlag {
        return dispatch(setAmazonFlow(amazonFlow));
    },
    setSendEmailFlag: function (sendEmail: boolean): SendEmailFlag {
        return dispatch(setLogEmailSentFlag(sendEmail));
    },
    resetPassword: function (email: string): Promise<void> {
        return dispatch(resetPassword(email));
    },
    goTo: (path: string) => dispatch(push(path)),
    login: (email: string, password: string) =>
        wrapCallbackAsAsync(handle => dispatch(login(email, password, handle))),
    signUpWithEmail: (email: string, password: string, confirmPassword: string) =>
        wrapCallbackAsAsync(handle => dispatch(singUp(email, password, handle))),
    loginWithGoogle: () => dispatch(loginWithGoogle()),
});

interface LoginPageProps extends DispatchToProps, StateToProps { }

export class Login extends React.Component<LoginPageProps, LoginPageState> {
    constructor(props: LoginPageProps) {
        super(props);
        this.state = { debouncing: false, error: "", showLoader: false, startupPlanDialogActive: false };
    }

    async componentDidMount() {
        if (window && window.location && window.location.hash) {
            this.setState({ loading: true });
            const matches = location.hash.match(new RegExp("access_token" + "=([^&]*)"));
            const accessToken = matches ? matches[1] : undefined;
            setTimeout(async () => {
                await this.props.loginWithAmazon(accessToken);
                this.props.setAmazonFlow(true);
            }, 500);
        }
    }

    componentWillUpdate(nextProps: LoginPageProps, nextState: any) {
        if (nextProps.debouncing === true && nextState.startupPlanDialogActive === false) {
            this.setState({
                showLoader: false,
                startupPlanDialogActive: true
            })
        }
    }

    handleResetPassword = async (email: string) => {
        try {
            if (!email) {
                this.setState({ error: new Error("Please enter email to reset.") });
            } else {
                await this.props.resetPassword(email);
            }
        } catch (err) {
            this.setState({ error: err });
        }
    }

    handleFormLogin = async (email: string, pass: string) => {
        try {
            this.props.setSendEmailFlag(true);
            this.setState({ showLoader: true, error: "" });
            const authUser = await this.props.login(email, pass);

            this.props.goTo('/');
        } catch (error) {
            this.setState({ showLoader: false, error });
        }
    }

    handleFormLoginWithGithub = async () => {
        try {
            this.props.setSendEmailFlag(true);
            this.setState({ showLoader: true, error: "" });
            await this.props.loginWithGithub();
        } catch (error) {
            if (!this.handleFreeEmailError(error)) {
                this.setState({ showLoader: false, error });
            }
        }
    }

    handleFormLoginWithGoogle = async () => {
        try {
            this.props.setSendEmailFlag(true);
            this.setState({ showLoader: true, error: "" });
            await this.props.loginWithGoogle()
        } catch (error) {

            if (!this.handleFreeEmailError(error)) {
                this.setState({ showLoader: false, error });
            }
        }
    }

    handleFormSignUpWithEmail = async (email: string, pass: string, confirmPass: string) => {
        try {
            this.setState({ showLoader: true, error: "" });
            const userExistsResult = await fetchInternalApi(`/login/userExists`, "POST", { email },
                { 'Content-Type': 'application/json' }, false);

            if ( userExistsResult === "true") {
                this.setState({ showLoader: false, error: { message: 'This email is already registered. Please use a different one.'} });
                return;
            }
            const validateEmailResult = await fetchInternalApi(`/login/validateEmail`, "POST", { email },
                { 'Content-Type': 'application/json' }, false);
            if (validateEmailResult?.isFreeMail || validateEmailResult.isDisposable) {
                this.setState({
                    showLoader: false,
                    startupPlanDialogActive: true,
                });
                return;
            }
            const authUser = await this.props.signUpWithEmail(email, pass, confirmPass);
            this.props.goTo('/survey')
        } catch (error) {
            // TODO handle errors
            if (!this.handleFreeEmailError(error)) {
                this.setState({ showLoader: false, error });
            }
        }
    }

    handleFreeEmailError = (error: Error) => {
        if (error && error.message && error.message === "custom/free-email") {
            this.setState({ showLoader: false, startupPlanDialogActive: true });
            return true;
        }
        return false;
    }

    handleEmailBlur = async (email: string) => {
        if (!email) return;
        try {
            this.setState({ error: "" });
            auth.validateEmail(email);

            const userExistsResult = await fetchInternalApi(`/login/userExists`, "POST", { email },
                { 'Content-Type': 'application/json' }, false);

            if ( userExistsResult === "false") {
                const validateEmailResult = await fetchInternalApi(`/login/validateEmail`, "POST", { email },
                    { 'Content-Type': 'application/json' }, false);
                if (validateEmailResult?.isFreeMail) {
                    this.setState((prevState) => ({
                        ...prevState,
                        startupPlanDialogActive: true,
                    }));
                }
            }
        } catch (error) {
            this.setState({ error });
        }
    }

    disablingDialog = () => {
        console.trace()

        this.props.setDebouncing(false)
        this.setState((prevState) => ({
            ...prevState,
            startupPlanDialogActive: false,
        }));
    }

    render() {
        const allProps = this.props as any;
        const location = allProps.location;
        return (
            <div>
                <LeftPanel className={style.leftPanel} fullHeight={true} image={backgroundLeft}>
                    <div data-id="details">
                        <BinocularsIcon data-id="icon" />
                        <div data-id="message1">Welcome to your Dashboard</div>
                        <div data-id="message2">from here, you can</div>
                        <div data-id="message3">see everything</div>
                    </div>
                    <div data-id="companyName">Bespoken</div>
                </LeftPanel>
                <RightPanel className={style.rightPanel} fullHeight>
                    <section>
                        <BespokenLogo data-id="icon" />
                        <AuthForm
                            error={this.state.error}
                            onSubmit={this.handleFormLogin}
                            onLoginWithGithub={this.handleFormLoginWithGithub}
                            onLoginWithGoogle={this.handleFormLoginWithGoogle}
                            onSignUpWithEmail={this.handleFormSignUpWithEmail}
                            onResetPassword={this.handleResetPassword}
                            location={location}
                            onEmailBlur={this.handleEmailBlur}
                        />
                        <BespokenModal
                            showModal={this.state.startupPlanDialogActive}
                            title={"Free Trial Restrictions"}
                            redTitleBorder
                            dialogToggle={this.disablingDialog}>
                            <p>We only offer free trials for users with corporate emails. Please register using your company email to receive a free trial.</p>
                            <div className={style.dialog_buttons_container}>
                                <Button
                                    data-id="source-selector-modal-delete-action"
                                    theme={bespokenButton}
                                    accent={true}
                                    onClick={this.disablingDialog}>OK</Button>
                            </div>
                        </BespokenModal>
                    </section>
                    <BespokenMountains data-id="mountains" />
                </RightPanel>
                {this.state.showLoader && <Loader />}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
